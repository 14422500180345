import { json } from "d3-fetch";
import React, {useState, useEffect} from 'react';

export const Botones = ({seleccion, setSeleccion}) => {

    const [ categoriaswp, setCategoriaswp] = useState([]);
    const [ loading, setLoading] = useState(true);
  
    useEffect(() => {
      json(`/datos/ods/categoriaswp.json`).then((data) => {
        setCategoriaswp(data);
      });

    }, []);

    const handleClick = (e) => {
        console.log(e.target.name);
        e.preventDefault();
        setSeleccion(e.target.name);
    }

    return (
        <section className="container g-pt-10 g-pb-10">
            <div classNane="row">
                
        {
            categoriaswp.map( cat => (
                
                    cat.ods != seleccion ?
                    <img src={cat.imagen} name={cat.ods} width="20%" className="g-pl-10 g-pb-10" onClick={handleClick}/>
                    :
                    <img src={cat.imagent} name={cat.ods} width="20%" className="g-pl-10 g-pb-10" onClick={handleClick}/>
                )
            )
        }
        </div>
        </section>

    )
}