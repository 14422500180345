import { json } from "d3-fetch";
import React, {useState, useEffect} from 'react';


export const Medio = ({seleccionMedio, setSeleccionMedio}) => {

    console.log(seleccionMedio);


    const [ medios, setMedios] = useState([]);

    useEffect(() => {
        json(`/datos/ods/medios.json`).then((data) => {
          setMedios(data);
        });
  
      }, []);

      const handleClick = (e) => {
        console.log(e.target.name);
        e.preventDefault();
        setSeleccionMedio(e.target.name);
    }

    return (
        <section className="container g-pt-10 g-pb-10">
        <div classNane="row">
            
        {
            medios.map( medio => (
                
                    medio.medio != seleccionMedio ?
                    <img src={medio.imagen} name={medio.medio} width="30%" className="g-pl-10 g-pb-10"/>
                    :
                    <img src={medio.imagent} name={medio.medio} width="30%" className="g-pl-10 g-pb-10"/>
                )
            )
        }

    </div>
    </section>
    )
}
