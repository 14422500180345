import React,{ useState, useEffect} from 'react';
import { Breadcrumb } from '../layout/Breadcrumb';
import { NoticiaVista } from './NoticiaVista';
import { json } from "d3-fetch";

import LoadingOverlay from 'react-loading-overlay';


export const Noticia = ({ match }) => {

    const id = match.params.id;

    const url = "https://backend.odssocialnetwork.org/wp-json/wp/v2/posts?include[]=" + id + "&_embed";

    const [ post, setPost] = useState([]);
    const [ loading, setLoading] = useState(true);
    const [ categoriaswp, setCategoriaswp] = useState([]);

  
    useEffect(() => {

        json(`/datos/ods/categoriaswp.json`).then((data) => {
            setCategoriaswp(data);
          });
         
        fetch(url)
        .then(response => response.json())
        .then(post => {
            setPost(post);
            setLoading(false);
    })},[]);




    return (
        <LoadingOverlay
        active={loading}
        spinner
        text='Cargando contenido...'
        >
        <Breadcrumb
            info1 = "Noticia"
            info2 = "Noticia"
            camino = "noticia"
        />
        {
            loading ? "Cargando..." : 
            <NoticiaVista
            post ={post}
            categoriaswp = {categoriaswp}
            />
        }
        </LoadingOverlay>
    )
}