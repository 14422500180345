import React from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

export const Itemhemeroteca = ({post}) => {

    console.log(post);

    const terminos = ["poor","world","action","people"];

    return (
        <div className="col-lg-12 g-mb-50 g-mt-50 g-mb-0--lg">

        <article className="media">
        <figure className="d-flex u-shadow-v25 mr-3 g-pos-rel">
          <img className="g-width-140 g-height-80" src={post._embedded["wp:featuredmedia"] ? post._embedded["wp:featuredmedia"][0].source_url : "https://loremflickr.com/745/450/" + terminos[2] } alt="Image Description"/>
        </figure>

        <div className="media-body">
          <h3 className="g-font-size-16">
          <Link to={"/noticia/" + post.id} className="g-color-gray-dark-v1">
              <div dangerouslySetInnerHTML={{__html: post.title.rendered}}></div>
              </Link>
          </h3>
          <b>{post._embedded["wp:term"][0][0].name}</b><br/>

          <ul className="u-list-inline g-font-size-12 g-color-gray-dark-v4">

            <li className="list-inline-item">
            <Moment locale="es">{post.date}</Moment>
            </li>
           
          </ul>
        </div>
      </article>
      </div>
    )
}
