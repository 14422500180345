import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";

export const Head = () => {

  const [ desplegarActualidad, setDesplegarActualidad ] = useState(false);

  const onMouseEnter = (e) => {
    e.preventDefault();
    setDesplegarActualidad(true);
  }
  
  const onMouseLeave = (e) => {
    e.preventDefault();
    setDesplegarActualidad(false);
  }
    return (
    <>
    {/* Header */}
    <header id="js-header" className="u-header u-header--static u-header--floating">
      <div className="u-header__section u-header__section--light g-bg-white g-transition-0_3 g-py-10">
        <nav className="js-mega-menu navbar navbar-expand-lg hs-menu-initialized hs-menu-horizontal">
          <div className="container">
            {/* Responsive Toggle Button */}
            <button className="navbar-toggler navbar-toggler-right btn g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-top-minus-3 g-right-0" type="button" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navBar" data-toggle="collapse" data-target="#navBar">
              <span className="hamburger hamburger--slider">
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
              </span>
              </span>
            </button>
            {/* End Responsive Toggle Button */}

            {/* Logo */}
            <Link to="/" className="navbar-brand d-flex">
              <img src="/logo-ods.png" width="40px" height="40px"/>

            </Link>
            <Link to="/" className="navbar-brand d-flex">
             ODSSocialNetwork
            </Link>
            {/* End Logo */}

            {/* Navigation */}
            <div className="collapse navbar-collapse align-items-center flex-sm-row g-pt-10 g-pt-5--lg g-mr-40--lg" id="navBar">
              <ul className="navbar-nav text-uppercase g-pos-rel g-font-weight-600 ml-auto">
                {/* Info */}
                <li className="nav-item  g-mx-10--lg g-mx-15--xl">
                  <NavLink to="/info" activeClassName="active" className="nav-link g-py-7 g-px-0">Intro</NavLink>
                </li>
                {/* End Info */}

                {/* ONG */}
                <li className="nav-item  g-mx-10--lg g-mx-15--xl">

                  <NavLink to="/ong" activeClassName="active" id="mega-menu-home" className="nav-link g-py-7 g-px-0" href="#" aria-haspopup="true" aria-expanded="false">Líderes ODS</NavLink>
                </li>
                {/* End ONG */}

                {/* ODS Info */}
                <li className="nav-item  g-mx-10--lg g-mx-15--xl">
                  <NavLink to="/odsinfo" activeClassName="active" className="nav-link g-py-7 g-px-0">Info ODS</NavLink>
                </li>
                {/* End ODS Info */}

                {/* ODS Mundo */}
                <li className="nav-item  g-mx-10--lg g-mx-15--xl">
                  <NavLink to="/odsmundo" activeClassName="active" className="nav-link g-py-7 g-px-0">Mundo ODS</NavLink>
                </li>           
                {/* End ODS Mundo */}

               {/* PLE */}
                <li className="nav-item  g-mx-10--lg g-mx-15--xl">
                  <NavLink to="/ple" activeClassName="active" className="nav-link g-py-7 g-px-0">PLE</NavLink>
                </li>
                {/* End PLE */}
                <li className="nav-item  g-mx-10--lg g-mx-15--xl">
                  <a href="https://storytelling.odssocialnetwork.org" target="_blank" activeClassName="active" className="nav-link g-py-7 g-px-0">Storytelling</a>
                </li>

                 {/* Noticias */}
                <li class="nav-item hs-has-sub-menu  g-mx-10--lg g-mx-15--xl hs-sub-menu-opened" data-animation-in="fadeIn" data-animation-out="fadeOut"
                  onMouseEnter={(e) => onMouseEnter(e)}
                >
                  <a id="nav-link-demos" class="nav-link g-py-7 g-px-0" href="#" aria-haspopup="true" aria-expanded="false" aria-controls="nav-submenu-demos">Actualidad</a>

                    <ul class="hs-sub-menu list-unstyled u-shadow-v11 g-brd-top g-brd-primary g-brd-top-2 g-min-width-220 g-mt-18 g-mt-8--lg--scrolling" id="nav-submenu-demos" aria-labelledby="nav-link-demos" style={{display: desplegarActualidad ? 'block' : 'none'}}
                                      onMouseLeave={(e) => onMouseLeave(e)}

                    >
                    <li class="dropdown-item ">
                      <NavLink to="/actualidad" activeClassName="active" className="nav-link g-py-7 g-px-0">Últimas Noticias</NavLink>
                    </li>
                    <li class="dropdown-item ">
                      <NavLink to="/hemeroteca" activeClassName="active" className="nav-link g-py-7 g-px-0">Hemeroteca</NavLink>
                    </li>
                    <li class="dropdown-item ">
                      <NavLink to="/agenda" activeClassName="active" className="nav-link g-py-7 g-px-0">Agenda</NavLink>
                    </li>
                    <li class="dropdown-item ">
                      <NavLink to="/anuncios" activeClassName="active" className="nav-link g-py-7 g-px-0">Tablón de anuncios</NavLink>
                    </li>
                  </ul>
                </li>
                {/* End Noticias */}

                <li className="nav-item  g-mx-10--lg g-mx-15--xl">
                <NavLink to="/recursos" activeClassName="active" className="nav-link g-py-7 g-px-0">Recursos</NavLink>
                </li>  
                 {/* Contacto */}
                 <li className="nav-item  g-mx-10--lg g-mx-15--xl">
                <NavLink to="/contacto" activeClassName="active" className="nav-link g-py-7 g-px-0">Contacto</NavLink>
                </li>  
                {/* End Contacto */}
          
              </ul>
            </div>
            {/* End Navigation */}
          </div>
        </nav>
      </div>
    </header>
    {/* End Header */}
    </>
    )
}