import Chart from "react-apexcharts";

export const Barras = ({color, data}) => {

    console.log(color);
    const state = {
        options: {
            colors: [`${color}`],
            labels: ["España", "OCDE (Europa y Norteamérica)", "Europa del Este y Asia central", "América Latina y Caribe", "África", "Oriente Medio y África del Norte", "Asia","Oceanía","Mundo"],   
            
          },
          series: [{
            data: data,
          }]
        }
        

    return (
        <Chart
        options={state.options}
        series={state.series}
        type="bar"
        width="600"
      />
    )
}