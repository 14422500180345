import React from 'react'
import { Breadcrumb } from '../layout/Breadcrumb'
import { Terminos } from '../layout/Terminos'
import { Portfolio } from './Portfolio'

export const Recursos = () => {
    return (
        <>
        <Breadcrumb
            info1 = "Recursos "
            camino = "Recursos"
        />
         <Terminos
          titulo="Recursos"
          texto="Acceso a documentación y recursos útiles para entender los Objetivos de Desarrollo Sostenible."
          />
        <Portfolio/>
        </>
    )
}
