import { Atlas } from './Atlas';
import { Barras } from './Barras';
import { Botones } from './Botones';

export const Profile3 = ({cambiarOds, ods, titulo}) => {
    return (
        <section class="g-mb-100">
        <div class="container">
          <div class="row">
            {/* Profile Sidebar */}
            <div class="col-lg-3 g-mb-50 g-mb-0--lg">
              {/* User Image */}
              <div class="u-block-hover g-pos-rel">
                <figure>
                  <img class="img-fluid w-100 u-block-hover__main--zoom-v1" src="/assets/datos/ods/ods3.jpg" alt="Image Description"/>
                </figure>
                <Atlas
                  titulo = {"SDG 3: Good health and well being"}
                  enlace = {"https://datatopics.worldbank.org/sdgatlas/goal-3-good-health-and-well-being"}
                  subtitulo = {"Global health amid a pandemic"}
                  imagen = {"/assets/datos/atlas/goal-3.5923aaf8.png"}
                />
                {/*<Botones cambiarOds={cambiarOds} />*/}

                {/* Figure Caption */}
                 {/*<figcaption class="u-block-hover__additional--fade g-bg-black-opacity-0_5 g-pa-30">
                  <div class="u-block-hover__additional--fade u-block-hover__additional--fade-up g-flex-middle">
                    {/* Figure Social Icons */}
                     {/*<ul class="list-inline text-center g-flex-middle-item--bottom g-mb-20">
                      <li class="list-inline-item align-middle g-mx-7">
                        <a class="u-icon-v1 u-icon-size--md g-color-white" href="#">
                          <i class="icon-note u-line-icon-pro"></i>
                        </a>
                      </li>
                      <li class="list-inline-item align-middle g-mx-7">
                        <a class="u-icon-v1 u-icon-size--md g-color-white" href="#">
                          <i class="icon-notebook u-line-icon-pro"></i>
                        </a>
                      </li>
                      <li class="list-inline-item align-middle g-mx-7">
                        <a class="u-icon-v1 u-icon-size--md g-color-white" href="#">
                          <i class="icon-settings u-line-icon-pro"></i>
                        </a>
                      </li>
                    </ul>
                    {/* End Figure Social Icons */}
                   {/*</div>
                </figcaption>
                {/* End Figure Caption */}
  
                {/* User Info */}
                {/*
                 <span class="g-pos-abs g-top-20 g-left-0">
                    <a class="btn btn-sm u-btn-primary rounded-0" href="#">Johne Doe</a>
                    <small class="d-block g-bg-black g-color-white g-pa-5">Project Manager</small>
                  </span>
                */}
                {/* End User Info */}
              </div>
              {/* User Image */}
  
              {/* Sidebar Navigation */}
              {/* <div class="list-group list-group-border-0 g-mb-40">
                {/* Overall */}
                {/* <a href="page-profile-main-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-home g-pos-rel g-top-1 g-mr-8"></i> Overall</span>
                  <span class="u-label g-font-size-11 g-bg-gray-dark-v2 g-rounded-20 g-px-10">2</span>
                </a>
                {/* End Overall */}
  
                {/* Profile */}
              {/*   <a href="page-profile-profile-1.html" class="list-group-item justify-content-between active">
                  <span><i class="icon-cursor g-pos-rel g-top-1 g-mr-8"></i> Profile</span>
                </a>
                {/* End Profile */}
  
                {/* Users Contacts */}
               {/*  <a href="page-profile-users-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-notebook g-pos-rel g-top-1 g-mr-8"></i> Users Contacts</span>
                </a>
                {/* End Users Contacts */}
  
                {/* My Projects */}
               {/*  <a href="page-profile-projects-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-layers g-pos-rel g-top-1 g-mr-8"></i> My Projects</span>
                  <span class="u-label g-font-size-11 g-bg-primary g-rounded-20 g-px-10">9</span>
                </a>
                {/* End My Projects */}
  
                {/* Comments */}
                {/* <a href="page-profile-comments-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-bubbles g-pos-rel g-top-1 g-mr-8"></i> Comments</span>
                  <span class="u-label g-font-size-11 g-bg-pink g-rounded-20 g-px-8">24</span>
                </a>
                {/* End Comments */}
  
                {/* Reviews */}
                {/* <a href="page-profile-reviews-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-heart g-pos-rel g-top-1 g-mr-8"></i> Reviews</span>
                </a>
                {/* End Reviews */}
  
                {/* History */}
                {/* <a href="page-profile-history-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-fire g-pos-rel g-top-1 g-mr-8"></i> History</span>
                </a>
                {/* End History */}
  
                {/* Settings */}
                {/* <a href="page-profile-settings-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-settings g-pos-rel g-top-1 g-mr-8"></i> Settings</span>
                  <span class="u-label g-font-size-11 g-bg-cyan g-rounded-20 g-px-8">3</span>
                </a>
                {/* End Settings */}
              {/* </div>
              {/* End Sidebar Navigation */}
  
              {/* Project Progress */}
              <div class="card border-0 rounded-0 g-mb-50">
                {/* Panel Header */}
                {/* <div class="card-header d-flex align-items-center justify-content-between g-bg-gray-light-v5 border-0 g-mb-15">
                  <h3 class="h6 mb-0">
                      <i class="icon-layers g-pos-rel g-top-1 g-mr-5"></i> Project Progress
                    </h3>
                  <div class="dropdown g-mb-10 g-mb-0--md">
                    <span class="d-block g-color-primary--hover g-cursor-pointer g-mr-minus-5 g-pa-5" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="icon-options-vertical g-pos-rel g-top-1"></i>
                      </span>
                    <div class="dropdown-menu dropdown-menu-right rounded-0 g-mt-10">
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-layers g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> Projects
                      </a>
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-wallet g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> Wallets
                      </a>
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-fire g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> Reports
                      </a>
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-settings g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> Users Setting
                      </a>
  
                      <div class="dropdown-divider"></div>
  
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-plus g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> View More
                      </a>
                    </div>
                  </div>
                </div>
                {/* End Panel Header */}
  
                {/* Panel Body */}
                <div class="js-scrollbar card-block u-info-v1-1 g-bg-white-gradient-v1--after g-height-300 g-pa-0">
                  {/* Web Design */}
                 {/*  <div class="g-mb-20">
                    <h6 class="g-mb-10">Web Design <span class="float-right g-ml-10">68%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-cyan u-progress-bar--xs" role="progressbar" style={{width: "68%", ariaValuenow:"68", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">11% more than last week</small>
                  </div>
                  {/* End Web Design */}
  
                  {/* Unify Project */}
                 {/*  <div class="g-mb-20">
                    <h6 class="g-mb-10">Dribbble Shots <span class="float-right g-ml-10">62%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-pink u-progress-bar--xs" role="progressbar" style={{width: "62%", ariaValuenow:"62", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">20% less than last week</small>
                  </div>
                  {/* End Unify Project */}
  
                  {/* Unify Project */}
                 {/*  <div class="g-mb-20">
                    <h6 class="g-mb-10">Unify Project <span class="float-right g-ml-10">93%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-primary u-progress-bar--xs" role="progressbar" style={{width: "93%", ariaValuenow:"93", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">17% more than last week</small>
                  </div>
                  {/* End Unify Project */}
  
                  {/* WordPress Coding */}
                {/*   <div class="g-mb-20">
                    <h6 class="g-mb-10">WordPress Coding <span class="float-right g-ml-10">74%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-black u-progress-bar--xs" role="progressbar" style={{width: "74%", ariaValuenow:"74", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">35% more than last week</small>
                  </div>
                  {/* End WordPress Coding */}
  
                  {/* Pixeel Ltd */}
                 {/*  <div class="g-mb-20">
                    <h6 class="g-mb-10">Pixeel Ltd <span class="float-right g-ml-10">86%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-darkpurple u-progress-bar--xs" role="progressbar" style={{width: "86%", ariaValuenow:"86", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">42% more than last week</small>
                  </div>
                  {/* End Pixeel Ltd */}
                </div>
                {/* End Panel Body */}
              </div>
              {/* End Project Progress */}
            </div>
            {/* End Profile Sidebar */}
  
            {/* Profle Content */}
            <div class="col-lg-9">
            {/* Nav tabs */}
            <ul class="nav nav-justified u-nav-v1-1 u-nav-red g-brd-bottom--md g-brd-bottom-2 g-brd-darkred g-mb-20" role="tablist" data-target="nav-2-1-default-hor-left-underline" data-tabs-mobile-type="slide-up-down" data-btn-classes="btn btn-md btn-block rounded-0 u-btn-outline-primary g-mb-20">
              <li class="nav-item">
                <a class="nav-link g-py-10 active" data-toggle="tab" href="#nav-2-1-default-hor-left-underline--1" role="tab">Presentación</a>
              </li>
              <li class="nav-item">
                <a class="nav-link g-py-10" data-toggle="tab" href="#nav-2-1-default-hor-left-underline--2" role="tab">Metas</a>
              </li>
              <li class="nav-item">
                <a class="nav-link g-py-10" data-toggle="tab" href="#nav-2-1-default-hor-left-underline--3" role="tab"> Estadísticas</a>
              </li>
              <li class="nav-item">
                <a class="nav-link g-py-10" data-toggle="tab" href="#nav-2-1-default-hor-left-underline--4" role="tab">Informes</a>
              </li>
            </ul>
            {/* End Nav tabs */}

            {/* Tab panes */}
            <div id="nav-2-1-default-hor-left-underline" class="tab-content">
              {/* Edit Profile */}
              <div class="tab-pane fade show active" id="nav-2-1-default-hor-left-underline--1" role="tabpanel" data-parent="#nav-2-1-default-hor-left-underline">
                <h2 class="h4 g-font-weight-300">{ods} | {titulo}</h2>
                <p>Garantizar una vida sana y promover el bienestar en todas las edades es esencial para el desarrollo sostenible.</p>

                <p>Actualmente, el mundo se enfrenta a una crisis sanitaria mundial sin precedentes; la COVID-19 está propagando el sufrimiento humano, desestabilizando la economía mundial y cambiando drásticamente las vidas de miles de millones de personas en todo el mundo.</p>

                <p>Antes de la pandemia, se consiguieron grandes avances en la mejora de la salud de millones de personas. En concreto, estos grandes avances se alcanzaron al aumentar la esperanza de vida y reducir algunas de las causas de muerte comunes asociadas con la mortalidad infantil y materna. Sin embargo, se necesitan más esfuerzos para erradicar por completo una gran variedad de enfermedades y abordar un gran número de problemas de salud, tanto constantes como emergentes. A través de una financiación más eficiente de los sistemas sanitarios, un mayor saneamiento e higiene, y un mayor acceso al personal médico, se podrán conseguir avances significativos a la hora de ayudar a salvar las vidas de millones de personas.</p>

                <p>Las emergencias sanitarias, como la derivada de la COVID-19, suponen un riesgo mundial y han demostrado que la preparación es vital. El Programa de las Naciones Unidas para el Desarrollo señaló las grandes diferencias relativas a las capacidades de los países para lidiar con la crisis de la COVID-19 y recuperarse de ella. La pandemia constituye un punto de inflexión en lo referente a la preparación para las emergencias sanitarias y la inversión en servicios públicos vitales del siglo XXI.</p>

                
              </div>
              {/* End Edit Profile */}

              {/* Security Settings */}
              <div class="tab-pane fade" id="nav-2-1-default-hor-left-underline--2" role="tabpanel" data-parent="#nav-2-1-default-hor-left-underline">
                <h2 class="h4 g-font-weight-300">{ods} | Retos a alcanzar</h2>
                <p class="g-mb-25">
                    <strong>3.1.</strong> Para 2030, reducir la tasa mundial de mortalidad materna a menos de 70 por cada 100.000 nacidos vivos.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>3.2.</strong> Para 2030, poner fin a las muertes evitables de recién nacidos y de niños menores de 5 años, logrando que todos los países intenten reducir la mortalidad neonatal al menos hasta 12 por cada 1.000 nacidos vivos, y la mortalidad de niños menores de 5 años al menos hasta 25 por cada 1.000 nacidos vivos.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>3.3.</strong> Para 2030, poner fin a las epidemias del SIDA, la tuberculosis, la malaria y las enfermedades tropicales desatendidas y combatir la hepatitis, las enfermedades transmitidas por el agua y otras enfermedades transmisibles.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>3.4.</strong> Para 2030, reducir en un tercio la mortalidad prematura por enfermedades no transmisibles mediante la prevención y el tratamiento y promover la salud mental y el bienestar.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>3.5.</strong> Fortalecer la prevención y el tratamiento del abuso de sustancias adictivas, incluido el uso indebido de estupefacientes y el consumo nocivo de alcohol.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>3.6.</strong> Para 2020, reducir a la mitad el número de muertes y lesiones causadas por accidentes de tráfico en el mundo.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>3.7.</strong> Para 2030, garantizar el acceso universal a los servicios de salud sexual y reproductiva, incluidos los de planificación de la familia, información y educación, y la integración de la salud reproductiva en las estrategias y los programas nacionales.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>3.8.</strong>  Lograr la cobertura sanitaria universal, en particular la protección contra los riesgos financieros, el acceso a servicios de salud esenciales de calidad y el acceso a medicamentos y vacunas seguros, eficaces, asequibles y de calidad para todos.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>3.9.</strong> Para 2030, reducir sustancialmente el número de muertes y enfermedades producidas por productos químicos peligrosos y la contaminación del aire, el agua y el suelo.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>3.a.</strong> Fortalecer la aplicación del Convenio Marco de la Organización Mundial de la Salud para el Control del Tabaco en todos los países, según proceda.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>3.b.</strong> Apoyar las actividades de investigación y desarrollo de vacunas y medicamentos para las enfermedades transmisibles y no transmisibles que afectan primordialmente a los países en desarrollo y facilitar el acceso a medicamentos y vacunas esenciales asequibles de conformidad con la Declaración de Doha relativa al Acuerdo sobre los ADPIC y la Salud Pública, en la que se afirma el derecho de los países en desarrollo a utilizar al máximo las disposiciones del Acuerdo sobre los Aspectos de los Derechos de Propiedad Intelectual Relacionados con el Comercio en lo relativo a la flexibilidad para proteger la salud pública y, en particular, proporcionar acceso a los medicamentos para todos.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>3.c.</strong> Aumentar sustancialmente la financiación de la salud y la contratación, el desarrollo, la capacitación y la retención del personal sanitario en los países en desarrollo, especialmente en los países menos adelantados y los pequeños Estados insulares en desarrollo.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>3.d.</strong> Reforzar la capacidad de todos los países, en particular los países en desarrollo, en materia de alerta temprana, reducción de riesgos y gestión de los riesgos para la salud nacional y mundial.


                
                </p>

               
              </div>
              {/* End Security Settings */}

              {/* Payment Options */}
              <div class="tab-pane fade" id="nav-2-1-default-hor-left-underline--3" role="tabpanel" data-parent="#nav-2-1-default-hor-left-underline">
                <h2 class="h4 g-font-weight-300">{ods} | Grado de consecución del objetivo</h2>
                <p class="g-mb-25">Fuente: <a href="https://unstats.un.org/sdgs/report/2020/">https://unstats.un.org/sdgs/report/2020/</a>
</p>

                <Barras
                color="#4BA145"
                data = {[95.44,92.54,77.11,73.87,44.50,75.82,68.16,62.93,70.04]}
                />

              </div>
              {/* End Payment Options */}

              {/* Notification Settings */}
              <div class="tab-pane fade" id="nav-2-1-default-hor-left-underline--4" role="tabpanel" data-parent="#nav-2-1-default-hor-left-underline">
                <h2 class="h4 g-font-weight-300">{ods} | Documentación</h2>
                <p class="g-mb-25">Below are the notifications you may manage.</p>

                  {/* Email Notification */}
                  <div class="form-group">
                    <label class="d-flex align-items-center justify-content-between">
                      <span>SALUD Y BIENESTAR: POR QUÉ ES IMPORTANTE</span>
                      <div class="u-check">
                      <a href="https://www.un.org/sustainabledevelopment/es/wp-content/uploads/sites/3/2016/10/3_Spanish_Why_it_Matters.pdf">Descargar documento</a> 
                      </div>
                    </label>
                  </div>
                  <div class="form-group">
                    <label class="d-flex align-items-center justify-content-between">
                      <span>Plan de acción mundial a favor de una vida sana y bienestar para todos</span>
                      <div class="u-check">
                      <a href="https://odssocialnetwork.org/documentos/plan de acción mundial salud 3.pdf">Descargar documento</a> 

                      </div>
                    </label>
                  </div>
                  {/* End Email Notification */}

                  <hr class="g-brd-gray-light-v4 g-my-20"/>

              </div>
              {/* End Notification Settings */}
            </div>
            {/* End Tab panes */}
          </div>
            {/* End Profle Content */}
          </div>
        </div>
      </section>
    )
}