import VideoCover from 'react-video-cover';


export const Video = () => {


  const videoOptions = {
    src: '../../assets/videos/videoplayback.mp4',
    title: 'click to play/pause',
    autoPlay: true,
    loop: true,
    muted: true,
    playsInline: true
  };

    return (
    <>
    
    {/* Video Block */}
    <section className="container-fluid g-pa-20">
      <div className="dzsparallaxer auto-init height-is-based-on-content use-loading mode-scroll loaded dzsprx-readyall g-bg-cover g-bg-black-opacity-0_6--after" data-options='{direction: "fromtop", animation_duration: 25, direction: "reverse"}'>
        {/* Promo Block - Parallax Video */}
        <div className="dzsparallaxer--target" style={{width: "100%", height: "200%"}} data-forcewidth_ratio="1.77">
          <div className="js-bg-video g-pos-abs w-100 h-100" data-hs-bgv-type="vimeo" data-hs-bgv-id="167434033" data-hs-bgv-loop="1">
          <VideoCover videoOptions={videoOptions} autoPlay={true}/>

          </div>
        </div>
        {/* End Promo Block - Parallax Video */}

        <div className="row text-center g-min-height-450 g-flex-centered g-pos-rel g-z-index-1">
          <div className="col-sm-8 col-md-7 col-lg-4 px-5 px-sm-0">
            <span className="d-block g-color-white-opacity-0_8 g-font-weight-600 text-uppercase mb-2">La Cumbre de Desarrollo Sostenible de la ONU: </span>
            <h2 className="h1 g-color-white g-font-weight-600 mb-0">17 Objetivos para transformar nuestro mundo</h2>
          </div>
        </div>
      </div>
    </section>
    {/* End Video Block */}
    </>
    )
}