import { json } from "d3-fetch";
import React, {useState, useEffect} from 'react';

export const Botones = ({cambiarOds}) => {

    const [ categoriaswp, setCategoriaswp] = useState([]);
    const [ loading, setLoading] = useState(true);
  
    useEffect(() => {
      json(`/datos/ods/categoriaswp.json`).then((data) => {
        setCategoriaswp(data);
      });

    }, []);

    const handleClick = (e, profile) => {
        //console.log(e.target.name);
        e.preventDefault();
        cambiarOds(profile);
        //setPrimeraCat(e.target.name);
    }

    return (
        <section className="container g-pt-10 g-pb-10">
            <div classNane="row">
                
        {
            categoriaswp.filter(cat => cat.ods.includes('ODS')).map( cat => (
                
            <img key={cat.ods} src={cat.imagent} name={cat.datosmapa} width="50%" className="g-pl-10 g-pb-10" onClick={ (e) => handleClick(e, cat.profile)}/>
            ))
        }
        <img src="assets/datos/ods/todos.jpg" name="todos" width="50%" className="g-pl-10 g-pb-10" onClick={ (e) => handleClick(e, "todos")}/>
        </div>
        </section>

    )
}