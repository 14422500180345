import React, { useState } from 'react'
import Modal from 'react-modal';


export const Emergente = () => {

    const [windowOpen, setWindowOpen] = useState(false);  

    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      }
    };
    
    const openModal = () => {
      setWindowOpen(true);
    }

    const closeModal = () => {
      setWindowOpen(false);
    }

    return (
        <div>
             <button onClick={openModal}>Open Modal</button>
        <Modal
          isOpen={windowOpen}
          style={customStyles}
          contentLabel="Example Modal"
        >

          <div id="modal-type-hashlink" class="js-autonomous-popup text-left g-max-width-600 g-bg-white g-overflow-y-auto g-pa-20" data-modal-type="hashlink" data-effect="fadein">
            <button type="button" class="close" onClick={closeModal}>
              <i class="hs-icon hs-icon-close"></i>
            </button>
            <h4 class="g-mb-20">This modal window have been shown by "hashlink"</h4>

            <hr/>

            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type
              specimen book.</p>
            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more
              recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          </div>

        </Modal>
        </div>
    )
}
