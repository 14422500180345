import { Head } from './layout/Head';
import { Home } from './Home/Home';

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { Footer } from './layout/Footer';
import { Ong } from './ONG/Ong';
import { ONGVista } from './ONG/OngVista';
import { Info } from './Info/Info';
import { ODSInfo } from './ODSInfo/ODSInfo';
import { ODSMundo } from './ODSMundo/ODSMundo';
import { Noticias } from './Noticias/Noticias';
import { Agenda } from './Noticias/Agenda';
import { Noticia } from './Noticias/Noticia';
import { PLE } from './PLE/PLE';
import { Contacto } from './Contacto/Contacto';
import { Anuncios } from './Noticias/Anuncios';
import { Emergente } from './Noticias/Emergente';
import { QueSonOds } from './ODSInfo/QueSonOds';
import { Recursos } from './Recursos/Recursos';
import { Hemeroteca } from './Noticias/Hemeroteca';
import CookieConsent from "react-cookie-consent";
import { Aviso } from './Politicas/Aviso';
import { Privacidad } from './Politicas/Privacidad';
import { Cookies } from './Politicas/Cookies';



export const OdsSocialNetwork = () => {
  return (
    <Router>
      <Head />
      <Switch>
        <Route exact path="/ong" component={Ong} />
        <Route path="/ong/:id" component={ONGVista} />
        <Route path="/info" component={Info} />
        <Route path="/de-donde-surgen" component={QueSonOds} />
        <Route path="/odsinfo" component={ODSInfo} />
        <Route path="/odsmundo" component={ODSMundo} />
        <Route path="/actualidad" component={Noticias} />
        <Route path="/agenda" component={Agenda} />
        <Route path="/anuncios" component={Anuncios} />
        <Route path="/emergente" component={Emergente} />
        <Route path="/noticia/:id" component={Noticia} />
        <Route path="/ple" component={PLE} />
        <Route path="/contacto" component={Contacto} />
        <Route path="/recursos" component={Recursos} />
        <Route path="/hemeroteca" component={Hemeroteca} />
        <Route path="/aviso_legal" component={Aviso} />
        <Route path="/politica_de_privacidad" component={Privacidad} />
        <Route path="/politica_de_cookies" component={Cookies} />

        <Route path="/">
          <Home />
        </Route>
      </Switch>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Aceptar"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        Esta web utiliza cookies para mejorar la experiencia de usuario.{" "}
        <span style={{ fontSize: "10px" }}>Sólo obtenemos datos estadísticos para conocer el número de visitas.</span>
      </CookieConsent>

    </Router>
  )
}