import { Categoria } from "../Noticias/Categoria";

export const ODS = ({categories, categoriaswp}) => {


    console.log(categories);

    return (
        <div class="mb-5">
          <h3 class="h5 g-color-black mb-3"></h3>
          <ul class="list-unstyled d-flex" >

                {
                    categories.map( cat => (
                        <Categoria
                        categoriaswp = {categoriaswp}
                        cat = {cat}
                        imagen = {true}
                        />
                    ))
                }
            
          </ul>
        </div>
    )
}