export const Iconos = () => {
    return (
    <>
    {/* Icon Blocks */}
    <div className="row no-gutters g-brd-bottom--lg g-brd-gray-light-v4">
      <div className="col-lg-3 g-pa-60">
        {/* Icon Blocks */}
        <div className="text-center">
          <span className="d-inline-block u-icon-v3 u-icon-size--xl g-font-size-36 g-bg-darkred g-color-white rounded-circle g-mb-20">
              <i className="icon-finance-086 u-line-icon-pro"></i>
            </span>
          <h3 className="h4 g-color-gray-dark-v2 mb-3">Identificar</h3>
          <p className="mb-0">Buscamos organizaciones líderes en ODS con capacidad para transformar la realidad.</p>
        </div>
        {/* End Icon Blocks */}
      </div>

      <div className="col-lg-3 g-brd-left--lg g-brd-gray-light-v4 g-pa-60">
        {/* Icon Blocks */}
        <div className="text-center">
          <span className="d-inline-block u-icon-v3 u-icon-size--xl g-font-size-36 g-bg-darkred g-color-white rounded-circle g-mb-20">
              <i className="icon-education-031 u-line-icon-pro"></i>
            </span>
          <h3 className="h4 g-color-gray-dark-v2 mb-3">Sumar</h3>
          <p className="mb-0">Impulsamos la creación de una red internacional para acelerar la consecución de los ODS</p>
        </div>
        {/* End Icon Blocks */}
      </div>

      <div className="col-lg-3 g-brd-left--lg g-brd-gray-light-v4 g-pa-60">
        {/* Icon Blocks */}
        <div className="text-center">
          <span className="d-inline-block u-icon-v3 u-icon-size--xl g-font-size-36 g-bg-darkred g-color-white rounded-circle g-mb-20">
              <i className="icon-finance-222 u-line-icon-pro"></i>
            </span>
          <h3 className="h4 g-color-gray-dark-v2 mb-3">Compartir</h3>
          <p className="mb-0">Generamos un espacio de aprendizaje colaborativo con las experiencias y el conocimiento existente.</p>
        </div>
        {/* End Icon Blocks */}
      </div>
      <div className="col-lg-3 g-brd-left--lg g-brd-gray-light-v4 g-pa-60">
        {/* Icon Blocks */}
        <div className="text-center">
          <span className="d-inline-block u-icon-v3 u-icon-size--xl g-font-size-36 g-bg-darkred g-color-white rounded-circle g-mb-20">
              <i className="icon-finance-222 u-line-icon-pro"></i>
            </span>
          <h3 className="h4 g-color-gray-dark-v2 mb-3">Multiplicar</h3>
          <p className="mb-0">Agitamos las redes sociales para el impacto de las acciones de la red ODS Social Network.</p>
        </div>
        {/* End Icon Blocks */}
      </div>
    </div>
    {/* End Icon Blocks */}
    </>
    )
}