import React,{ useState, useEffect} from 'react'; 
import { Breadcrumb } from "../layout/Breadcrumb";
import { json } from "d3-fetch";
import { ODS } from './ODS';
import { Link } from 'react-router-dom';


import LoadingOverlay from 'react-loading-overlay';
import { UltimasNoticias } from './UltimasNoticias';
import { Enlaces } from './Enlaces';

export const ONGVista = ( { match } ) => {

   const id = parseInt(match.params.id);
   const [loading,setLoading] = useState(true);
   const [ post, setPost] = useState([]);
   const [ categoriaswp, setCategoriaswp] = useState([]);


   const url = "https://backend.odssocialnetwork.org/wp-json/wp/v2/posts?include[]=" + id + "&_embed";
  
    useEffect(() => {
      json(`/datos/ods/categoriaswp.json`).then((data) => {
        setCategoriaswp(data);
      });

      fetch(url)
          .then(response => response.json())
          .then(post => {
              setPost(post);
              setLoading(false);
          });
        scrollTop();
    }, []);

    const scrollTop = () =>{
      window.scrollTo({top: 0, behavior: 'smooth'});
   };

   const buscarCategoriaONG = (categories) => {

    const bcategoriaswp = categoriaswp.map( cat => {
        return cat.categoriawp;
    })
    let resultado = [];
    const id = categories.map( cat => {
        if(bcategoriaswp.includes(cat)) {
        resultado.push(cat);
        }
    })
    return resultado;
   }
   const buscarCategoriaONGinversa = (categories) => {

    const bcategoriaswp = categoriaswp.map( cat => {
        return cat.categoriawp;
    })
    let resultado = [];
    const id = categories.map( cat => {
        if(!bcategoriaswp.includes(cat)) {
        resultado.push(cat);
        }
    })
    return resultado;
   }



    return (
      
      <LoadingOverlay
      active={loading}
      spinner
      text='Cargando contenido...'
      >
        <Breadcrumb
            info1 = "ONG"
            info2 = ""
            camino = "ong"
        />
        {
        loading ? "Cargando..." : 
        <>
        {/* Section */}
        <section className="container">
          <div className="row">
            <div className="col-lg-9 ">
              <div className="mb-4">
                <h2 className="h3 text-uppercase mb-3">{post[0].title.rendered}</h2>
                <div className="g-width-60 g-height-1 g-bg-black"></div>
              </div>
              <div className="mb-5">
              <p><div dangerouslySetInnerHTML={ {__html: post[0].excerpt.rendered} } /></p>
              <div className="embed-responsive">
                {
                  loading ? "Cargando..." : <ODS
                  categoriaswp = {categoriaswp}
                  categories = {buscarCategoriaONG(post[0].categories)}
                />
                }
                
              </div>
              </div>
          
            </div>
        
            <div className="col-lg-3 g-mb-60">
              <div className="embed-responsive">
                <img src={post[0]._embedded["wp:featuredmedia"][0].source_url} width="100%"/>
              </div>
            </div>
          </div>
        </section>

        <Enlaces post={ post[0] } />


        <div className="g-bg-gray-light-v5">
        <section className="container g-bg-gray-light-v5" >

          <div className="row">
          <h2 className="h3 text-uppercase mb-3">PROYECTOS</h2>

              <p><div dangerouslySetInnerHTML={ {__html: post[0].content.rendered} } /></p>
          </div>
        </section>
        </div>

        <UltimasNoticias
          categories = {buscarCategoriaONGinversa(post[0].categories)}
          categoriaswp = {categoriaswp}

      />
        <section className="container g-pt-10 g-pb-10">
          <div className="row">
          <Link to="/ong" className="btn btn-xl u-btn-outline-black g-font-size-default mr-2">Atrás</Link>

            </div>
            </section>
          
        {/* End Section */}
        </>
        }
      </LoadingOverlay>
    )
}