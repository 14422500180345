import React, { useState, useEffect } from 'react';
import { Footer } from "../layout/Footer";
import { Iconos } from './Iconos';
import { Inspire } from './Inspire';
import { Presentacion } from "./Presentacion";
import { Promo } from "./Promo";
import { Video } from "./Video";

import { Revolution } from "./Revolution";
import { UltimasNoticias } from './UltimasNoticias';
import { Action } from './Action';

export const Home = () => {

    const [loading, setLoading] = useState(true);

    useEffect( () => {
        //window.location.reload();
    },[loading]);

    return (
        <>
        <Promo/>
        <Inspire/>
        <Iconos/>
        <Video/>
        <UltimasNoticias/>
        <Action/>
        </>
    )
}