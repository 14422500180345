import React, { useState, useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { json } from "d3-fetch";
import { Breadcrumb } from '../layout/Breadcrumb';
import { Itemhemeroteca } from './Itemhemeroteca';


export const Hemeroteca = () => {

    const [ posts, setPosts] = useState([]);
    const [ totalPaginas, setTotalPaginas ] = useState();
    const [ totalNoticias, setTotalNoticias ] = useState();
    const [ pagina, setPagina ] = useState(1);
    const [ loading, setLoading] = useState(true);

    const url = "https://backend.odssocialnetwork.org//wp-json/wp/v2/posts?_embed&tags=105&per_page=20";


    const terminos = ["poor","world","action","people"];


    useEffect(() => {
        setLoading(true);
        fetch(url + "&page=" + pagina.toString())
            .then(response => {
                //console.log(response.headers.get("X-WP-TotalPages"));
                setTotalPaginas(response.headers.get("X-WP-TotalPages"));
                setTotalNoticias(response.headers.get("X-WP-Total"));
                return response.json();
            })
            .then(posts => {
                console.log(posts);
                setPosts(posts);
                setLoading(false);
    })},[pagina]);

    const handleSiguiente = (e) => {
        e.preventDefault();
        setPagina(pagina + 1)
    }

    const handleAnterior = (e) => {
        e.preventDefault();
        setPagina(pagina - 1)
    }


    return (
        <LoadingOverlay
        active={loading}
        spinner
        text='Cargando contenido...'
        >
            <Breadcrumb
            info1 = "Hemeroteca"
            info2 = ""
            camino = "hemeroteca"
        />
        <section className="g-py-50">
        <div className="container">
        <div className="row">
          {/* Articles Content */}
          <div className="col-lg-12 g-mb-50 g-mb-0--lg"></div>
          <div className="g-mb-60">
              <div className="u-heading-v3-1 g-mb-30">
                <h2 className="h5 u-heading-v3__title g-font-primary g-font-weight-700 g-color-gray-dark-v1 text-uppercase g-brd-primary">Hemeroteca ({totalNoticias} Noticias)</h2>
              </div>
        {
            loading ? "Cargando..." : 
            
                posts.map( post => (
                    <Itemhemeroteca post={post}/>
                ))
            
        }
        </div>
        </div>
        </div>
        </section>
        <nav id="stickyblock-end-1" aria-label="Page Navigation">
                <ul class="list-inline text-center mb-10">
                { (pagina > 1) &&    
                <li class="list-inline-item">
                    <a class="u-pagination-v1__item g-brd-secondary-light-v2 g-brd-primary--hover g-color-gray-dark-v5 g-color-primary--hover g-font-size-12 rounded g-px-15 g-py-5 g-ml-15" href="#" aria-label="Anterior" onClick={handleAnterior}>
                      <span aria-hidden="true">
                      <i class="ml-2 fa fa-angle-left"> </i> 
                        Noticias anteriores
                      </span>
                      <span class="sr-only">Noticias anteriores</span>
                    </a>
                  </li>
                }
                {
                (pagina < totalPaginas) &&
                  <li class="list-inline-item">
                    <a class="u-pagination-v1__item g-brd-secondary-light-v2 g-brd-primary--hover g-color-gray-dark-v5 g-color-primary--hover g-font-size-12 rounded g-px-15 g-py-5 g-ml-15" href="#" aria-label="Next" onClick={handleSiguiente}>
                      <span aria-hidden="true">
                        Noticias siguiente
                        <i class="ml-2 fa fa-angle-right"></i>
                      </span>
                      <span class="sr-only">Noticias siguiente</span>
                    </a>
                  </li>
                }
                </ul>
              </nav>
        
        </LoadingOverlay>
    )
}
