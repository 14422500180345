import React, { memo, useEffect, useState } from "react";
import { csv } from "d3-fetch";
import { scaleLinear } from "d3-scale";
import {
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  Graticule
} from "react-simple-maps";

const MapChart = ({ setTooltipContent, data, ods }) => {


  //const geoUrl = "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";
  const geoUrl = "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/v2/topojson-maps/world-110m.json";


  const colorScale = scaleLinear()
  .domain([0.29, 0.68])
  .range(["#ffedea", "#ff5233"]);

  const rounded = num => {
    if (num > 1000000000) {
      return Math.round(num / 100000000) / 10 + "Bn";
    } else if (num > 1000000) {
      return Math.round(num / 100000) / 10 + "M";
    } else {
      return Math.round(num / 100) / 10 + "K";
    }
  };

  function perc2color(perc) {
    var r, g, b = 50;
    if(perc < 50) {
      r = 200;
      g = Math.round(5.1 * perc);
    }
    else {
      g = 200;
      r = Math.round(510 - 5.10 * perc);
    }
    var h = r * 0x10000 + g * 0x100 + b * 0x1;
    return '#' + ('000000' + h.toString(16)).slice(-6);
  }



  return (
    <>
      {/*<button onClick={e => (cambiarOds("Normalized Score: sdg1_wpc"))}>ODS 1</button>
      <button onClick={e => (cambiarOds("Normalized Score: sdg2_undernsh"))}>ODS 2</button>
  <button onClick={e => (cambiarOds("Normalized Score: sdg3_matmort"))}>ODS 3</button>*/}

      <ComposableMap data-tip="" projectionConfig={{ scale: 200 }}>
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map(geo => {
                //console.log(geo.properties.ISO_A3);
                const d = data.find((s) => s.id === geo.properties.ISO_A3);  
                //console.log("------");
                     
                return (

                <Geography
                  fill={d ? perc2color(d[ods]) : "#F5F4F6"}
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    const { NAME, POP_EST } = geo.properties;
                    if(d!==undefined) {
                    setTooltipContent(`${NAME} — ${rounded(POP_EST)} - ${d[ods]}%`);
                    } else {
                      setTooltipContent(`${NAME} — ${rounded(POP_EST)}`);

                    }
                  }}
                  onMouseLeave={() => {
                    setTooltipContent("");
                  }}
                  style={{
                    
                    hover: {
                      fill: "#F53",
                      outline: "none"
                    },
                    pressed: {
                      fill: "#E42",
                      outline: "none"
                    }
                  }}
                />
              )})
            }
          </Geographies>
      </ComposableMap>
    </>
  );
};

export default memo(MapChart);