import React from 'react'

export const QueSonOds = () => {
    return (
        <>
        <section className="container g-pt-100 g-pb-60">
      <div className="row justify-content-center">
        <div className="col-lg-9">
          <div className="g-mb-60">
            <h2 className="g-color-black g-font-weight-600 text-center g-mb-30">¿De dónde surgen los ODS?</h2>
            <p><span className="d-inline-block float-left g-width-60 g-height-60 g-color-black g-font-weight-600 g-font-size-30 text-center g-pa-7 mr-2">L</span>a Agenda 2030 para el Desarrollo Sostenible, adoptada por todos los Estados Miembros de las 
            Naciones Unidas en 2015, proporciona un plan común para la paz y la prosperidad para las personas y el planeta, ahora y en el futuro. En su esencia, se encuentran los 17 Objetivos de Desarrollo Sostenible (ODS), que son un llamado urgente a la acción 
            de todos los países, desarrollados y en desarrollo, en una asociación global. Reconocen que poner fin a la pobreza y otras privaciones debe ir de la mano de estrategias que mejoren la salud y la educación, reduzcan la desigualdad y estimulen el crecimiento 
            económico, a la vez que se aborda el cambio climático y se trabaja para preservar nuestros océanos y bosques.</p>
            <p>Los ODS se basan en décadas de trabajo de los países y la ONU, incluido el Departamento de Asuntos Económicos y Sociales (DAES) de la ONU:</p>
          </div>

          <div className="row">
            <div className="col-md-6 g-mb-60">
              <h3 className="h4 g-color-black g-font-weight-600"><span className="g-font-size-25">01.</span></h3>
              <p>En junio de 1992, en la Cumbre de la Tierra en Río de Janeiro, Brasil, más de 178 países adoptaron la Agenda 21, un plan de acción integral para construir una alianza mundial para el desarrollo sostenible a fin de mejorar las vidas humanas y proteger el medio ambiente.</p>
            </div>
            <div className="col-md-6 g-mb-60">
              <img className="img-fluid" src="../../assets/img-temp/400x270/01.png" alt="Image Description" />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 flex-md-unordered g-mb-60">
              <h3 className="h4 g-color-black g-font-weight-600"><span className="g-font-size-25">02.</span></h3>
              <p>Los Estados miembros adoptaron por unanimidad la Declaración del Milenio en la Cumbre del Milenio en septiembre de 2000 en la Sede de la ONU en Nueva York. La Cumbre condujo a la elaboración de ocho Objetivos de Desarrollo del Milenio (ODM) para reducir la pobreza extrema para 2015.</p>
            </div>
            <div className="col-md-6 flex-md-first g-mb-60">
              <img className="img-fluid" src="../../assets/img-temp/400x270/02.jpg" alt="Image Description"/>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 g-mb-60">
              <h2 className="h4 g-color-black g-font-weight-600"><span className="g-font-size-25">03.</span></h2>
              <p>La Declaración de Johannesburgo sobre el Desarrollo Sostenible y el Plan de Aplicación, adoptados en la Cumbre Mundial sobre el Desarrollo Sostenible en Sudáfrica en 2002, reafirmaron los compromisos de la comunidad mundial con la erradicación de la pobreza y el medio ambiente, y se basaron 
                  en la Agenda 21 y la Declaración del Milenio al incluir más énfasis en las asociaciones multilaterales.</p>
            </div>
            <div className="col-md-6 g-mb-60">
              <img className="img-fluid" src="../../assets/img-temp/400x270/03.png" alt="Image Description"/>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 g-mb-60">
              <h2 className="h4 g-color-black g-font-weight-600"><span className="g-font-size-25">04.</span></h2>
              <p>En la Conferencia de las Naciones Unidas sobre el Desarrollo Sostenible (Río + 20) en Río de Janeiro, Brasil, en junio de 2012, los Estados Miembros adoptaron el documento final "El futuro que queremos" en el que decidieron, entre otras cosas, poner en marcha un proceso para Desarrollar 
                  un conjunto de ODS para construir sobre los ODM y establecer el Foro Político de Alto Nivel de la ONU sobre Desarrollo Sostenible. El resultado de Río +20 también contenía otras medidas para implementar el desarrollo sostenible, incluidos mandatos para futuros programas de trabajo en la financiación del desarrollo, pequeños estados insulares en desarrollo (SIDS) y más.</p>
            </div>
            <div className="col-md-6 g-mb-60">
              <img className="img-fluid" src="../../assets/img-temp/400x270/04.jpg" alt="Image Description"/>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 g-mb-60">
              <h2 className="h4 g-color-black g-font-weight-600"><span className="g-font-size-25">05.</span></h2>
              <p>En 2013, la Asamblea General estableció un Grupo de Trabajo Abierto de 30 miembros para desarrollar una propuesta sobre los ODS.</p>
            </div>
            <div className="col-md-6 g-mb-60">
              <img className="img-fluid" src="../../assets/img-temp/400x270/05.jpg" alt="Image Description"/>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 g-mb-60">
              <h2 className="h4 g-color-black g-font-weight-600"><span className="g-font-size-25">06.</span></h2>
              <p>En enero de 2015, la Asamblea General inició el proceso de negociación sobre la agenda de desarrollo post-2015. El proceso culminó con la posterior adopción de la Agenda 2030 para el Desarrollo Sostenible, con 17 ODS como eje central, en la Cumbre de Desarrollo Sostenible de las Naciones Unidas en septiembre de 2015.</p>
            </div>
            <div className="col-md-6 g-mb-60">
              <img className="img-fluid" src="../../assets/img-temp/400x270/06.png" alt="Image Description"/>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 g-mb-60">
              <h2 className="h4 g-color-black g-font-weight-600"><span className="g-font-size-25">07.</span></h2>
              <p>2015 fue un año histórico para el multilateralismo y la formulación de políticas internacionales, con la adopción de varios acuerdos importantes:
                <li>Marco de Sendai para la reducción del riesgo de desastres (marzo de 2015)</li>
                <li>Agenda de acción de Addis Abeba sobre financiación para el desarrollo (julio de 2015)</li>
                <li>Transformar nuestro mundo: la Agenda 2030 para el Desarrollo Sostenible con sus 17 ODS fue adoptada en la Cumbre de Desarrollo Sostenible de las Naciones Unidas en Nueva York en septiembre de 2015.</li>
                <li>Acuerdo de París sobre el cambio climático (diciembre de 2015)</li>
</p>
            </div>
            <div className="col-md-6 g-mb-60">
              <img className="img-fluid" src="../../assets/img-temp/400x270/07.jpg" alt="Image Description"/>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 g-mb-60">
              <h2 className="h4 g-color-black g-font-weight-600"><span className="g-font-size-25">08.</span></h2>
              <p>Ahora, el Foro Político de Alto Nivel sobre Desarrollo Sostenible anual sirve como la plataforma central de las Naciones Unidas para el seguimiento y revisión de los ODS.</p>
            </div>
            <div className="col-md-6 g-mb-60">
              <img className="img-fluid" src="../../assets/img-temp/400x270/08.jpg" alt="Image Description"/>
            </div>
          </div>

          <h2 className="g-color-black g-font-weight-600 text-center g-mb-30">División de Objetivos de Desarrollo Sostenible (DSDG) </h2>
          <p>En la actualidad, la División de Objetivos de Desarrollo Sostenible (DSDG) del Departamento de Asuntos Económicos y Sociales de las Naciones Unidas (DAES) brinda apoyo sustantivo y 
              fomento de la capacidad para los ODS y sus bloques temáticos relacionados, incluidos el agua, la energía, el clima, los océanos y la urbanización, transporte, ciencia y tecnología, 
              el Informe sobre el desarrollo sostenible mundial (GSDR), las asociaciones y los pequeños Estados insulares en desarrollo. La División de Objetivos de Desarrollo Sostenible juega un 
              papel clave en la evaluación de la implementación de la Agenda 2030 en todo el sistema de las Naciones Unidas y en las actividades de promoción y divulgación relacionadas con los ODS. 
              Para que la Agenda 2030 se convierta en realidad, la propiedad generalizada de los ODS debe traducirse en un fuerte compromiso de todas las partes interesadas para implementar los objetivos globales. 
              La DSDG tiene como objetivo ayudar a facilitar este compromiso.</p>
        </div>
      </div>
    </section>
        </>
    )
}
