import React,{ useState, useEffect } from 'react';

export const Categoria = ({ categoriaswp, cat, imagen }) => {

    const [categoria, setCategoria] = useState({
       "ods": "",
       "color": "",
       "imagen": ""
    })

    useEffect(() => {

        const found = categoriaswp.find( element => {
            return element.categoriawp === parseInt(cat);
        })

        if(found) {

        setCategoria({
            "ods": found.ods,
            "color": found.color,
            "imagen": found.imagen
        })}
    },[]);

    const estilo = {
        backgroundColor: categoria.color
    }

    return (
        <>
        {
            imagen ?  
            <li className="d-flex align-items-center justify-content-between g-mb-20 g-ml-5">
            <img src={categoria.imagen} width="100px"/>
            </li>
            :
            <a className="btn btn-xs u-btn-darkpurple text-uppercase rounded-0" style={estilo} href="#">{categoria.ods}</a>
        }
        </>
    )
}