import React,{ useState, useEffect} from 'react';
import { json } from "d3-fetch";
import { Categoria } from '../Noticias/Categoria';
import LoadingOverlay from 'react-loading-overlay';
import { UltimaNoticia } from './UltimaNoticia';

import "./UltimasNoticias.css"

export const UltimasNoticias = () => {
    const url = "https://backend.odssocialnetwork.org/wp-json/wp/v2/posts?_embed&per_page=3&tags=105";

    const [ posts, setPosts] = useState([]);
    const [ categoriaswp, setCategoriaswp] = useState([]);
    const [ loading, setLoading] = useState(true);
    const terminos = ["poor","world","action"];

    useEffect(() => {
        json(`/datos/ods/categoriaswp.json`).then((data) => {
            setCategoriaswp(data);
          });
        fetch(url)
            .then(response => response.json())
            .then(posts => {
                setPosts(posts);
                setLoading(false);
                console.log(posts);

    })},[]);

    return (

        <LoadingOverlay
        active={loading}
        spinner
        text='Cargando contenido...'
        >
          { loading ? "Cargando..." :
        <>
        {/* Blog Classic Blocks */}
    <div className="container-fluid g-px-30 g-pt-100">
      <div className="masonry-grid row g-mb-70">

        {
            posts.map((post, index) => (
                <UltimaNoticia
                    id = {post.id}
                    title = {post.title.rendered}
                    source_url = {post._embedded["wp:featuredmedia"] ? post._embedded["wp:featuredmedia"][0].source_url : "https://loremflickr.com/745/450/" + terminos[index] }
                    date = {posts.date}
                />
            ))
        }


        </div>
        </div>
          </>
          }
          </LoadingOverlay>

        
    )
}