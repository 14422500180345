import { Breadcrumb } from "../layout/Breadcrumb";
import { ListaOng } from "./ListaOng";
import React,{ useState, useEffect} from 'react'; 
import { Botones } from "./Botones";
import { Terminos } from "../layout/Terminos";


const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
 };

export const Ong = () => {

    const [ primeraCat, setPrimeraCat] = useState(true);

    useEffect(() => {
          scrollTop();
        
      }, []);
    return (
        <>
        <Breadcrumb
            info1 = "Líderes "
            info2 = "ODS"
            camino = "LideresODS"
        />
        <Terminos
          titulo="Líderes ODS"
          texto="Te presentamos a las entidades y organizaciones que ya se han unido a ODS Social Network. 
          Descúbrelas y contacta seleccionando el ODS por el que las quieras localizar. 
          Si tu organización está interesada en sumarse a la red, no dudes en contactar. Te esperamos."
          />

        <div className="row">
            <div className="col-2">
            <h3 className="h1 g-color-gray-dark-v1 g-font-weight-100 ml-5">Elige ODS: </h3>


        <Botones 
            setPrimeraCat = {setPrimeraCat}
        />
        </div>
        <div className="col-10">

        <ListaOng
            primeraCat = {primeraCat}
            setPrimeraCat = {setPrimeraCat}
        />
        </div>
        </div>
        </>
    )
}