import React from 'react'
import Moment from 'react-moment';

export const Ofertas = ({posts}) => {




    return (
            
      <div class="row">

        {

          posts.map( post => (
            
            <div className="col-lg-6 g-mb-40 g-mb-0--lg">
            <li class="media g-brd-around g-brd-gray-light-v4 g-brd-left-3 g-brd-teal-left g-rounded-3 g-pa-20 g-mb-7">
              <div class="d-flex g-mt-4 g-mr-15">
                <img class="g-width-40 g-height-40 rounded" src={post._embedded["wp:featuredmedia"][0].source_url} alt="Image Description"/>
              </div>
              <div class="media-body">
                <div class="d-flex justify-content-between">
                  <h5 class="h6 g-font-weight-600 g-color-black">{post.title.rendered}</h5>
                  <span class="small text-nowrap g-color-teal"><Moment locale="es">{posts.date}</Moment></span>
                </div>
                <div dangerouslySetInnerHTML={ {__html: post.content.rendered} } />

                
              </div>
            </li>
          </div>
          )
          )
        }
        </div>

    )
}
