import { Link } from "react-router-dom";

export const Inspire = () => {
    return (
    <>
    {/* About */}
    <section className="g-pt-100 g-pb-100">
      <div className="container">
        {/* Image, Text Block */}
        <div className="row d-flex align-items-lg-center flex-wrap g-mb-60 g-mb-0--lg">
          <div className="col-md-6 col-lg-8">
            <img className="img-fluid rounded" src="../../assets/img-temp/900x600/img1.jpg" alt="Image Description"/>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="g-mt-minus-30 ">
              <div className="g-mb-20">
                <h2 className="g-color-red g-font-weight-600 g-font-size-25 g-font-size-35--lg g-line-height-1_2 mb-4">Trabajamos para<br/>sumar líderes del cambio<br/>para alcanzar los ODS
                  </h2>
                <p className="g-font-size-16">ODS Social Network es un proyecto que persigue multiplicar el impacto de las acciones individuales realizadas para ODS.</p>
              </div>
              <Link to="/info" className="btn u-btn-darkred g-brd-2 g-brd-white g-font-size-13 g-rounded-50 g-pl-20 g-pr-15 g-py-9">
                  Conócenos
                  <span className="align-middle u-icon-v3 g-width-16 g-height-16 g-color-black g-bg-white g-font-size-11 rounded-circle ml-3">
                    <i className="fa fa-angle-right"></i>
                  </span>
              </Link>
            </div>
          </div>
        </div>
        {/* End Image, Text Block */}
      </div>

      <div className="container">
        {/* Image, Text Block */}
        <div className="row d-flex justify-content-between align-items-lg-center flex-wrap g-mt-minus-50--lg">
          <div className="col-md-6 order-md-2">
            <div className="g-brd-around--md g-brd-10 g-brd-white rounded">
              <img className="img-fluid w-100 rounded" src="../../assets/img-temp/600x450/img1.jpg" alt="Image Description"/>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 ml-auto order-md-1">
            <div className="g-mt-minus-30 g-mt-0--md g-ml-minus-100--lg">
              <div className="g-mb-20">
                <h2 className="g-color-black g-font-weight-600 g-font-size-25 g-font-size-35--lg g-line-height-1_2 mb-4">Sumar voluntades para<br/>cambiar el planeta
                  </h2>
                <p className="g-font-size-16">No existe ni tiempo ni planeta.</p>
              </div>
              <Link to="/contacto" className="btn u-btn-darkred g-brd-2 g-brd-white g-font-size-13 g-rounded-50 g-pl-20 g-pr-15 g-py-9">
                  Únete
                  <span className="align-middle u-icon-v3 g-width-16 g-height-16 g-color-black g-bg-white g-font-size-11 rounded-circle ml-3">
                    <i className="fa fa-angle-right"></i>
                  </span>
              </Link>
            </div>
          </div>
        </div>
        {/* End Image, Text Block */}
      </div>
    </section>
    {/* End About */}
    </>
    )
}