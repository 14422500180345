import { Profile1 } from "./Profile1";
import { Profile2 } from "./Profile2";
import { Profile3 } from "./Profile3";
import { Profile4 } from "./Profile4";
import { Profile5 } from "./Profile5";
import { Profile6 } from "./Profile6";
import { Profile7 } from "./Profile7";
import { Profile8 } from "./Profile8";
import { Profile9 } from "./Profile9";
import { Profile10 } from "./Profile10";
import { Profile11 } from "./Profile11";
import { Profile12 } from "./Profile12";
import { Profile13 } from "./Profile13";
import { Profile14 } from "./Profile14";
import { Profile15 } from "./Profile15";
import { Profile16 } from "./Profile16";
import { Profile17 } from "./Profile17";
import { Profile18 } from "./Profile18";


export const Profile = ({profile, cambiarOds}) => {

  console.log(profile);

    return (
      <>
      { profile === "Profile 1" ? <Profile1 cambiarOds={cambiarOds} ods="ODS 01" titulo="Fin de la Pobreza" /> : null}
      { profile === "Profile 2" ? <Profile2 cambiarOds={cambiarOds} ods="ODS 02" titulo="Hambre Cero" /> : null}
      { profile === "Profile 3" ? <Profile3 cambiarOds={cambiarOds} ods="ODS 03" titulo="Salud y Bienestar" /> : null}
      { profile === "Profile 4" ? <Profile4 cambiarOds={cambiarOds} ods="ODS 04" titulo="Educación de Calidad" /> : null}
      { profile === "Profile 5" ? <Profile5 cambiarOds={cambiarOds} ods="ODS 05" titulo="Igualdad de Género" /> : null}
      { profile === "Profile 6" ? <Profile6 cambiarOds={cambiarOds} ods="ODS 06" titulo="Agua limpia y Saneamiento" /> : null}
      { profile === "Profile 7" ? <Profile7 cambiarOds={cambiarOds} ods="ODS 07" titulo="Energía asequible y no contaminante" /> : null}
      { profile === "Profile 8" ? <Profile8 cambiarOds={cambiarOds} ods="ODS 08" titulo="Trabajo decente y crecimiento económico" /> : null}
      { profile === "Profile 9" ? <Profile9 cambiarOds={cambiarOds} ods="ODS 09" titulo="Industria, Innovación e Infraestructura" /> : null}
      { profile === "Profile 10" ?<Profile10 cambiarOds={cambiarOds} ods="ODS 10" titulo="Reducción de las desigualdades" /> : null}
      { profile === "Profile 11" ?<Profile11 cambiarOds={cambiarOds} ods="ODS 11" titulo="Ciudades y comunidades sostenibles" /> : null}
      { profile === "Profile 12" ?<Profile12 cambiarOds={cambiarOds} ods="ODS 12" titulo="Producción y comnsumo responsable" /> : null}
      { profile === "Profile 13" ?<Profile13 cambiarOds={cambiarOds} ods="ODS 13" titulo="Acción por el clima" /> : null}
      { profile === "Profile 14" ?<Profile14 cambiarOds={cambiarOds} ods="ODS 14" titulo="Vida submarina" /> : null}
      { profile === "Profile 15" ?<Profile15 cambiarOds={cambiarOds} ods="ODS 15" titulo="Vida de ecosistemas terrestres" /> : null}
      { profile === "Profile 16" ?<Profile16 cambiarOds={cambiarOds} ods="ODS 16" titulo="Paz, Justicia e Instituciones sólidas" /> : null}
      { profile === "Profile 17" ?<Profile17 cambiarOds={cambiarOds} ods="ODS 17" titulo="Alianzas para lograr los objetivos" /> : null}
      { profile === "todos" ?<Profile18 cambiarOds={cambiarOds} ods="ODS" titulo="GENERAL" /> : null}
      </>
    )
}