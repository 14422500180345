import React from 'react'
import { Botones } from './Botones'

export const Item1 = ({seleccion, setSeleccion, carousel}) => {

    const handleClick = (e) => {
        e.preventDefault();
        carousel.current.goTo(1);
    }
    
    return (
        <div class="row no-gutters">
        <div class="col-lg-6 g-bg-black g-pa-60 g-pa-100--sm">
          <h3 class="h4 g-color-white g-font-weight-700 g-mb-25">ODS</h3>

          <div class="g-mb-50">
            <p class="g-color-white-opacity-0_7 g-mb-30">Selecciona un ODS del que desees obtener información.</p>
            <Botones
                seleccion={seleccion}
                setSeleccion={setSeleccion}
            />
          </div>

        {
            seleccion.length > 0 
            ?
                <a class="btn btn-lg u-btn-primary g-font-weight-600 g-font-size-default text-uppercase g-rounded-50" href="#" onClick={e => handleClick(e)}>Continuar con {seleccion} >></a>
            :
            ""
        }
        </div>

        <div class="col-lg-6 g-min-height-400 g-bg-size-cover g-bg-pos-center" style={{backgroundImage: "url(../../assets/img-temp/900x900/img1.jpg)"}}></div>
    </div>
    )
}
