import React from 'react'

export const Atlas = ({titulo, enlace, subtitulo, imagen}) => {
    return (
        <>
        <br/>
        <h3>Atlas of Sustainable Development Goals <b className="g-font-size-25">2020</b></h3> From World Development Indicators
        <br/>
        <hr/>
        <a href={ enlace }>{ titulo }</a><br/>
        {subtitulo}
        <figure>
          <img class="img-fluid " src={ imagen } alt="Image Description"/>
        </figure>
        </>
    )
}
