import {Link} from 'react-router-dom';
export const Action = () => {
    return (

        <div class="shortcode-html">
        <section class="g-bg-darkred g-color-white g-pa-30" style={{backgroundimage: "url(../../assets/img/bg/pattern5.png)"}}>
          <div class="d-md-flex justify-content-md-center text-center">
            <div class="align-self-md-center">
              <p class="lead g-font-weight-400 g-mr-20--md g-mb-15 g-mb-0--md">¿Eres una ONG y quieres unirte a la red?</p>
            </div>
            <div class="align-self-md-center">
              <Link to="/contacto" class="btn btn-md u-btn-red">Escríbenos</Link>
            </div>
          </div>
        </section>
      </div>
    
    )
}