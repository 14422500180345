import React from 'react'

export const Item4 = ({ seleccion, seleccionNivel, seleccionMedio, dedicacion, carousel}) => {

  const medios = {"medio 01": "Youtube", "medio 02": "Curso MOOC", "medio 03": "Documentos"};

  const handleClick = (e) => {
    e.preventDefault();
    carousel.current.goTo(4);
  }

    return (
    <div class="row no-gutters">
      <div class="col-lg-6 g-bg-black g-pa-60 g-pa-100--sm">
          <h3 class="h4 g-color-white g-font-weight-700 g-mb-25">RESULTADO</h3>

          <div class="g-mb-50">
            <p class="g-color-white-opacity-0_7 g-mb-30">Ya tenemos configurada su elección sobre el ODS que quiere profundizar, el nivel de profundidad, el medio digital y el número de horas que está dispuesto a dedicar..</p>
            <p class="g-color-white-opacity-0_7 g-mb-30">Has elegido las siguientes opciones:</p>
            <ul>
            <li class="g-color-white-opacity-0_7">{ seleccion }</li>
            <li class="g-color-white-opacity-0_7">{ seleccionNivel }</li>
            <li class="g-color-white-opacity-0_7">{ dedicacion } horas</li>
            </ul>
          </div>

          <a class="btn btn-lg u-btn-primary g-font-weight-600 g-font-size-default text-uppercase g-rounded-50" href="#" onClick={e => handleClick(e)}>Obtener Mi Camino</a>
        </div>

        <div class="col-lg-6 g-min-height-400 g-bg-size-cover g-bg-pos-center" style={{backgroundImage: "url(../../assets/img-temp/900x900/img4.jpg)"}}></div>
    </div>
    )
}
