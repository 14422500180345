import { Breadcrumb } from "../layout/Breadcrumb"
import StickyBox from "react-sticky-box";

export const Info = () => {
    return (
        <>
        <Breadcrumb
            info1 = "Información"
            camino = "info"
        />

    {/* Blog Single Item Blocks */}
    <div className="container-fluid px-0">
      <div className="row no-gutters">
        <div className="col-md-6">
            <StickyBox >
          <img src="/assets/img-temp/900x900/cara.jpg"  width="100%" object-fit="cover"/>
          </StickyBox>
        </div>


        <div className="col-md-6">
          <div className="g-pa-50 g-pt-0 g-px-100--lg g-pb-100--lg">
            <div className="g-max-width-400--md g-pos-rel g-z-index-1 g-pt-100 g-pl-50 g-pl-0--md g-ml-minus-50--lg mb-4">
              <h1 className="h2 g-color-black g-font-weight-600">¿Qué es ODS Social Network?</h1>
            </div>

            <div className="mb-5">
              <p className="g-color-darkred g-font-weight-600 g-font-size-16">Es una plataforma digital que apuesta por dotar a Extremadura de un espacio dinámico, activo y en permanente actualización, que facilite el acceso a herramientas, materiales y recursos que se están generando en el mundo sobre los ODS.</p>
              <p className="g-color-darkred g-font-weight-600 g-font-size-16">Supondrá un espacio colaborativo de referencia para todos los agentes que trabajan por la consecución de los objetivos de desarrollo sostenible, desde la región para el mundo, y del mundo para la región.</p>

            </div>

      
            <div className="g-max-width-400--md g-pos-rel g-z-index-1 g-pt-100 g-pl-50 g-pl-0--md g-ml-minus-50--lg mb-4">
              <h1 className="h2 g-color-black g-font-weight-600">¿Qué pretende?</h1>
            </div>
              <p className="g-color-darkgrey g-font-weight-600 g-font-size-16">En Extremadura existen numerosas fuentes de información acerca de los ODS; información de calidad, pero dispersa, desaprovechando en muchas ocasiones las experiencias de entidades vecinas. ODS Social Network pretende centralizar todos los recursos de la región en un mismo espacio de colaboración, de forma que los agentes participantes puedan desarrollar sinergias a la hora de crear y desarrollar sus proyectos. En definitiva, facilitar alianzas entre las entidades que trabajan en pro de los objetivos de desarrollo sostenible.</p>

              <div className="g-max-width-400--md g-pos-rel g-z-index-1 g-pt-100 g-pl-50 g-pl-0--md g-ml-minus-50--lg mb-4">
              <h1 className="h2 g-color-black g-font-weight-600">Cómo funciona.</h1>
            </div>
            <p className="g-color-darkred g-font-weight-600 g-font-size-16">ODS Social Network se articula en torno a cuatro ejes orientados a que toda la ciudadanía pueda elevar su grado de participación y compromiso con el desarrollo sostenible en la región:</p>
            <p className="g-color-darkgrey g-font-weight-600 g-font-size-16">Un espacio para la <strong>gestión del conocimiento</strong>, con recursos varios acerca de los Objetivos de Desarrollo Sostenible que abarcan legislación, acuerdos, historia o datos actualizados sobre cumplimiento entre otros.</p>
            <p className="g-color-darkgrey g-font-weight-600 g-font-size-16">Un espacio de <strong>aprendizaje</strong>, con más de 6000 horas de cursos gratuitos acerca de los ODS, estructurados en entornos personales de aprendizaje (PLE) en función de las necesidades e intereses de cada persona.</p>
            <p className="g-color-darkgrey g-font-weight-600 g-font-size-16">Un espacio de <strong>participación colaborativa</strong>, en el que las entidades que trabajan en desarrollo sostenible compartan sus experiencias y proyectos, así como una sección de storytelling con actividades modelo.</p>
            <p className="g-color-darkgrey g-font-weight-600 g-font-size-16">Un espacio de <strong>comunicación</strong>, que abarca noticias y actualidad acerca de los ODS, así como un calendario de las actividades desarrolladas por las entidades de la región, maximizando la visibilidad de éstas.</p>
            <div className="g-max-width-400--md g-pos-rel g-z-index-1 g-pt-100 g-pl-50 g-pl-0--md g-ml-minus-50--lg mb-4">
              <h1 className="h2 g-color-black g-font-weight-600">Participación</h1>
            </div>
            <p className="g-color-darkgrey g-font-weight-600 g-font-size-16">La esencia de ODS Social Network es <strong>colaborativa</strong>, y para fomentar esa colaboración, la iniciativa ofrece a las entidades que participan un escaparate sin igual en toda la región. En virtud de su centralidad y permanente actualización de información, los agentes pueden dar a conocer sus proyectos, actividades o noticias, de manera que llegue a <strong>toda la red</strong> de actores asociados a los ODS, fomentando alianzas y sinergias junto a experiencias de terceras entidades, o tomar parte activa en los entornos personales de aprendizaje aportando sus propias enseñanzas.</p>

          
         
            <div className="g-mb-60" >
              <p>Esta plataforma forma parte del proyecto  “Espacio ODS Extremadura - Social Net World” que ejecuta <a href="https://www.fundacionciudadania.es/" style={{color: "darkred"}}>Fundación Ciudadanía</a> con financiación de la <a href="http://www.juntaex.es/aexcid/" style={{color: "darkred"}}>Agencia Extremeña de Cooperación Internacional para el Desarrollo</a> de la Junta de Extremadura (AEXCID).</p>

          
            </div>

            {/* Social Icons */}
            {/*<div className="g-brd-top g-brd-gray-light-v4 text-center g-pt-50">
              <h3 className="h6 g-color-black g-font-weight-600 text-uppercase mb-3">Share:</h3>
              <ul className="list-inline mb-0">
                <li className="list-inline-item g-mx-2">
                  <a className="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-facebook--hover" href="#">
                    <i className="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-facebook"></i>
                    <i className="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-facebook"></i>
                  </a>
                </li>
                <li className="list-inline-item g-mx-2">
                  <a className="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-twitter--hover" href="#">
                    <i className="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-twitter"></i>
                    <i className="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-twitter"></i>
                  </a>
                </li>
                <li className="list-inline-item g-mx-2">
                  <a className="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-google-plus--hover" href="#">
                    <i className="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-google-plus"></i>
                    <i className="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-google-plus"></i>
                  </a>
                </li>
                <li className="list-inline-item g-mx-2">
                  <a className="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-linkedin--hover" href="#">
                    <i className="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-linkedin"></i>
                    <i className="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>*/}
            {/* End Social Icons */}
          </div>
        </div>
      </div>
    </div>
    {/* End Blog Single Item Blocks */}

        </>
    )
}