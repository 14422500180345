import Moment from 'react-moment';
import { Link } from 'react-router-dom';


export const UltimaNoticia = ({id, title, source_url, date}) => {

    console.log(title);


    return (

        
        <div className="masonry-grid-item col-sm-6 col-lg-4 g-mb-30">
          {/* Blog Classic Blocks */}
          <article className="u-shadow-v11">
            <img className="img-fluid w-100 imagen-actualidad2" src={source_url} alt="Image Description"/>
            <div className="g-bg-white g-pa-30">
              <span className="d-block g-color-gray-dark-v4 g-font-weight-600 g-font-size-12 text-uppercase mb-2"><Moment locale="es">{date}</Moment></span>
              <h2 className="h5 g-color-black g-font-weight-600 mb-3">
                <Link to={"/noticia/" + id} className="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer">{title}</Link>
              </h2>
              <Link to={"/noticia/" + id} className="g-font-size-13">Leer más...</Link>
            </div>
          </article>
          {/* End Blog Classic Blocks */}
        </div>
    )
}