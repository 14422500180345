import React,{ useState, useEffect} from 'react';
import { json } from "d3-fetch";
import { Breadcrumb } from '../layout/Breadcrumb'
import { Terminos } from '../layout/Terminos'
import { Ofertas } from './Ofertas'
import LoadingOverlay from 'react-loading-overlay';

export const Anuncios = () => {


  const url = "https://backend.odssocialnetwork.org//wp-json/wp/v2/posts?_embed&tags=135&per_page=15";

  const [ posts, setPosts] = useState([]);
  const [ categoriaswp, setCategoriaswp] = useState([]);
  const [ loading, setLoading] = useState(true);

  useEffect(() => {
      json(`/datos/ods/categoriaswp.json`).then((data) => {
          setCategoriaswp(data);
        });
      fetch(url)
          .then(response => response.json())
          .then(posts => {
              console.log(posts);
              setPosts(posts);
              setLoading(false);
  })},[]);

    return (
        <>
      <LoadingOverlay
      active={loading}
      spinner
      text='Cargando contenido...'
      >
        <Breadcrumb
            info1 = "Anuncios"
            info2 = ""
            camino = "anuncios"
        />
        <Terminos
          titulo="Anuncios"
          texto="Tablón de anuncios"
        />
        <section className="g-py-50">
            
        <div className="container">

          <Ofertas posts={posts}/>     
       
        </div>
        </section>
              
        </LoadingOverlay>
      </>
    )
}