import Moment from 'react-moment';
import 'moment/locale/es';
import moment from 'moment/min/moment-with-locales';

import { OdsSocialNetwork } from './components/odsSocialNetwork';
function App() {

  Moment.globalMoment = moment;
  Moment.globalLocale = 'es';
  Moment.globalFormat = 'D MMM YYYY';
  Moment.globalLocal = true;

  return (
    <>
      <OdsSocialNetwork/>
    </>
  );
}

export default App;
