import React, { useState, useEffect } from 'react';
import publicIp from "public-ip";
import axios from 'axios';

export const Item5 = ({ seleccion, seleccionNivel, seleccionMedio, dedicacion, data, carousel}) => {

    const medios = {"medio 01": "Youtube", "medio 02": "Curso MOOC", "medio 03": "Documentos"};

    const [dataFiltrado, setDataFiltrado] = useState([]);    
    const [horasTotales, setHorasTotales] = useState(0);    

    const [ipv4, setIpv4] = useState(''); 

     publicIp.v4()
     .then(res => {
       setIpv4(res);
     })

  const enviarDatos = async () => {
    const values = {
      ip: ipv4,
      ods: seleccion,
      nivel: seleccionNivel,
      medios: seleccionMedio,
      dedicacion: dedicacion,
      resultado: JSON.stringify(dataFiltrado)
    }
    if(values.ods.length > 0 && values.nivel.length > 0 ) {
      const res = await axios.post("https://backend.socialytech.solutions/api/v1/odsvisits/store/", values)
      .then(response=> {
        //alert(JSON.stringify(response));
        return response })
      .catch(error=>{ 
        alert("error");
        return error; })
      }
  }

  useEffect(() => {
    enviarDatos();
  }, [dataFiltrado])
    
    useEffect(() => {

      if (seleccion.length > 0 && seleccionNivel.length > 0 && seleccionNivel.length > 0 ) {
        calcularDocumentos(data);
      }
    }, [seleccion, seleccionMedio, seleccionNivel, dedicacion])

    async function calcularDocumentos(data) {

      let horasTotales = 0;

      const seleccionODS = await data.filter( s => {
        if(s.ods === seleccion) {
          //horasTotales += parseInt(s.peso);
          return s;
        }
      });

      horasTotales = 0;

      const selNivel = await seleccionODS.filter( s => {
        if(s.nivel === seleccionNivel.replace("NIVEL 0","")) {
          return s;
        }
      });

      //const selMedio = await selNivel.filter( s => {
      //  if(s.medio === seleccionMedio) {
      //    return s;
      //  }
      //});

      let shuffled = [];

      //if (selMedio.length > 0) {
      //  shuffled = selMedio.sort(() => Math.random() - 0.5);
      //} else {
        shuffled = selNivel.sort(() => Math.random() - 0.5);
      //}


      let duracionTemporal = 0;

      const ple = shuffled.filter( filtro => {
        if(dedicacion > duracionTemporal) {
          duracionTemporal += filtro.peso;
          horasTotales += parseInt(filtro.peso);
          return filtro;
        }
      })

      setHorasTotales(horasTotales);
      setDataFiltrado(ple);
    }

    const handleClick = (e) => {
      e.preventDefault();
      carousel.current.goTo(1);
    }
    
    return (
    <div className="row no-gutters">
        <div className="col-lg-6 g-bg-white g-pa-60 g-pa-100--sm">
          <h3 className="h4 g-color-black g-font-weight-700 g-mb-25">MI PATH DE APRENDIZAJE</h3>

          <div className="g-mb-50">
            <p className="g-color-black-opacity-0_7 g-mb-30">El siguiente listado es una recomendación de aprendizaje en base a sus preferencias: {seleccion}, {seleccionNivel}, {medios[seleccionMedio]} y {dedicacion} horas.</p>
            {
              dataFiltrado.length === 0 ?
              <>
                <p><b>No se han encontrado resultados para la búsqueda.</b></p>
                <a class="btn btn-lg u-btn-primary g-font-weight-600 g-font-size-default text-uppercase g-rounded-50" href="#" onClick={e => handleClick(e)}>Modificar opciones >></a>
              </>
              :
              ""
            }
            <ol>
            {
              dataFiltrado.map( s => (
                <li>
                <h4 className="h6 g-color-black g-font-weight-700 g-mb-15"><a href={s.enlace}>{ s.titulo }</a></h4>
                <pre>{ s.peso } horas</pre>
                </li>
              ))
            }
            </ol>
            {
              dataFiltrado.length > 0 ?
              <p><span className="g-color-red">Total:</span> { horasTotales } horas</p>
              :
              ""
            }
            {
              dedicacion > horasTotales && dataFiltrado.length > 0 ?
                <p><span className="g-color-green">Te sobran {dedicacion - horasTotales } horas</span></p>
              :
              ""
            }

          </div>

        </div>

        <div className="col-lg-6 g-min-height-400 g-bg-size-cover g-bg-pos-center" style={{backgroundImage: "url(../../assets/img-temp/900x900/img6.jpg)"}}></div>
    </div>
    )
}
