import React from 'react';


export const Item = ( { recurso } ) => {

    console.log(recurso);
    return (
        <div class="cbp-item identity design">
        <div class="u-block-hover g-parent">
          <img class="img-fluid g-transform-scale-1_1--parent-hover g-transition-0_5 g-transition--ease-in-out" src={recurso._embedded["wp:featuredmedia"][0].source_url} alt={recurso.title.rendered}/>
          <div class="d-flex w-100 h-100 g-bg-black-opacity-0_6 opacity-0 g-opacity-1--parent-hover g-pos-abs g-top-0 g-left-0 g-transition-0_3 g-transition--ease-in u-block-hover__additional--fade u-block-hover__additional--fade-in g-pa-20">
            <ul class="align-items-end flex-column list-inline mt-auto ml-auto mb-0">
              <li class="list-inline-item">
                <a class="u-icon-v2 u-icon-size--xs g-brd-white g-color-black g-bg-white rounded-circle" href={recurso.paginaweb}>
                  <i class="icon-communication-095 u-line-icon-pro"></i>
                </a>
              </li>
              
            </ul>
          </div>
        </div>
        <div class="u-shadow-v19 g-bg-white text-center g-pa-25 mb-1">
          <h3 class="h5 g-color-black g-font-weight-700 mb-1">{recurso.title.rendered} </h3>
          <p class="mb-0"><a href={recurso.paginaweb}>Mostrar</a></p>
        </div>
      </div>
    )
}
