import React, { useState } from 'react';
import { Breadcrumb } from "../layout/Breadcrumb"
import { Terminos } from "../layout/Terminos"
import { Calendario } from "../ODSInfo/Calendario"
import { Botones } from './Botones';
import { Profile } from "./Profile"

export const ODSInfo = () => {


    const [ odsElegido, setOdsElegido ] = useState('Profile 1');
    const cambiarOds = (cambio) => {
      console.log(cambio);
      setOdsElegido(cambio);
    }

    return (
        <>
         <Breadcrumb
            info1 = "Información "
            info2="ODS"
            camino = "InfoODS"
        />
        <Terminos
          titulo="Info ODS"
          texto="Infórmate sobre los retos y las metas que definen cada ODS.
          Consulta las estadísticas actualizadas sobre su consecución, distribuidas por regiones en el mundo.
          Descúbrelas seleccionando el ODS por el que las quieras localizar"
          />
        
        <hr/>

        <div className="row">
            <div className="col-2">
            <Botones cambiarOds={cambiarOds} />

              </div>
              <div className="col-10">


        <Profile 
          profile={odsElegido}
          cambiarOds={cambiarOds}
        />
        </div>
        </div>

        </>

        
    )
}