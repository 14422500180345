import React from 'react'
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import "./Noticias.css";



export const MasNoticias = ({posts}) => {

  const terminos = ["poor","world","action","people"];

    return (
        <div className="row">
          {/* Articles Content */}
          <div className="col-lg-12 g-mb-50 g-mb-0--lg">
            {/* Popular News */}
            <div className="g-mb-60">
              <div className="u-heading-v3-1 g-mb-30">
                <h2 className="h5 u-heading-v3__title g-font-primary g-font-weight-700 g-color-gray-dark-v1 text-uppercase g-brd-primary">Más Noticias</h2>
              </div>

              <div className="row">
                <div className="col-lg-6 g-mb-50 g-mb-0--lg">
                  {/* Article */}
                  <article className="g-mb-40">
                    <figure className="u-shadow-v25 g-pos-rel g-mb-20">
                      <img className="img-fluid w-100 imagen-actualidad2" src={posts[5]._embedded["wp:featuredmedia"] ? posts[5]._embedded["wp:featuredmedia"][0].source_url : "https://loremflickr.com/745/450/" + terminos[0] } alt="Image Description"/>

                      <figcaption className="g-pos-abs g-top-20 g-left-20">
                        {/* <a className="btn btn-xs u-btn-teal text-uppercase rounded-0" href="#">Holiday</a>*/}
                      </figcaption>
                    </figure>

                    <h3 className="h4 g-mb-10">
                    <Link to={"/noticia/" + posts[5].id} className="u-link-v5 g-color-gray-dark-v1 g-color-primary--hover">{posts[5].title.rendered}</Link>
                    </h3>

                    <ul className="list-inline g-color-gray-dark-v4 g-font-size-12">
                     
                      <li className="list-inline-item">
                      <Moment locale="es">{posts[5].date}</Moment>
                      </li>
                    
                    </ul>
                    <Link to={"/noticia/" + posts[5].id} className="g-font-size-12">Leer más...</Link>
                  </article>
                  {/* Article */}

                  {/* Other Articles */}
                  <article className="media">
                    <figure className="d-flex u-shadow-v25 mr-3 g-pos-rel">
                      <img className="g-width-140 g-height-80" src={posts[7]._embedded["wp:featuredmedia"] ? posts[7]._embedded["wp:featuredmedia"][0].source_url : "https://loremflickr.com/745/450/" + terminos[1] } alt="Image Description"/>
                    </figure>

                    <div className="media-body">
                      <h3 className="g-font-size-16">
                      <Link to={"/noticia/" + posts[7].id} className="g-color-gray-dark-v1">{posts[7].title.rendered}</Link>
                      </h3>

                      <ul className="u-list-inline g-font-size-12 g-color-gray-dark-v4">
                        <li className="list-inline-item">
                        <Moment locale="es">{posts[7].date}</Moment>
                        </li>                       
                      </ul>
                    </div>
                  </article>
                  {/* End Other Articles */}

                  <hr className="g-brd-gray-light-v4 g-my-25"/>

                  {/* Other Articles */}
                  <article className="media">
                    <figure className="d-flex u-shadow-v25 mr-3 g-pos-rel">
                      <img className="g-width-140 g-height-80" src={posts[9]._embedded["wp:featuredmedia"] ? posts[9]._embedded["wp:featuredmedia"][0].source_url : "https://loremflickr.com/745/450/" + terminos[2] } alt="Image Description"/>
                    </figure>

                    <div className="media-body">
                      <h3 className="g-font-size-16">
                      <Link to={"/noticia/" + posts[9].id} className="g-color-gray-dark-v1">{posts[9].title.rendered}</Link>
                      </h3>

                      <ul className="u-list-inline g-font-size-12 g-color-gray-dark-v4">
                        <li className="list-inline-item">
                        <Moment locale="es">{posts[9].date}</Moment>

                        </li>
                       
                      </ul>
                    </div>
                  </article>
                  {/* End Other Articles */}

                  <hr className="g-brd-gray-light-v4 g-my-25"/>

                  {/* Other Articles */}
                  <article className="media">
                    <figure className="d-flex u-shadow-v25 mr-3 g-pos-rel">
                      <img className="g-width-140 g-height-80" src={posts[11]._embedded["wp:featuredmedia"] ? posts[11]._embedded["wp:featuredmedia"][0].source_url : "https://loremflickr.com/745/450/" + terminos[3] } alt="Image Description"/>

                   
                    </figure>

                    <div className="media-body">
                      <h3 className="g-font-size-16">
                      <Link to={"/noticia/" + posts[11].id} className="g-color-gray-dark-v1">{posts[11].title.rendered}</Link>
                      </h3>

                      <ul className="u-list-inline g-font-size-12 g-color-gray-dark-v4">
                        <li className="list-inline-item">
                        <Moment locale="es">{posts[11].date}</Moment>
                        </li>
                       
                      </ul>
                    </div>
                  </article>
                  {/* End Other Articles */}
                </div>

                <div className="col-lg-6 g-mb-50 g-mb-0--lg">
                  {/* Article */}
                  <article className="g-mb-40">
                    <figure className="u-shadow-v25 g-pos-rel g-mb-20">
                      <img className="img-fluid w-100" src={posts[6]._embedded["wp:featuredmedia"] ? posts[6]._embedded["wp:featuredmedia"][0].source_url : "https://loremflickr.com/745/450/" + terminos[0] } alt="Image Description"/>

                      <figcaption className="g-pos-abs g-top-20 g-left-20">
                        <a className="btn btn-xs u-btn-red text-uppercase rounded-0" href="#">School</a>
                      </figcaption>
                    </figure>

                    <h3 className="h4 g-mb-10">
                    <Link to={"/noticia/" + posts[6].id} className="u-link-v5 g-color-gray-dark-v1 g-color-primary--hover">{posts[6].title.rendered}</Link>
                    </h3>

                    <ul className="list-inline g-color-gray-dark-v4 g-font-size-12">
                    
                      <li className="list-inline-item">
                        <Moment locale="es">{posts[6].date}</Moment>
                      </li>
                    </ul>
                    <Link to={"/noticia/" + posts[7].id} className="g-font-size-12">Leer más...</Link>
                  </article>
                  {/* End Article */}

                  {/* Other Articles */}
                  <article className="media">
                    <figure className="d-flex u-shadow-v25 mr-3 g-pos-rel">
                      <img className="g-width-140 g-height-80" src={posts[8]._embedded["wp:featuredmedia"] ? posts[8]._embedded["wp:featuredmedia"][0].source_url : "https://loremflickr.com/745/450/" + terminos[1] } alt="Image Description"/>

                   
                    </figure>

                    <div className="media-body">
                      <h3 className="g-font-size-16">
                      <Link to={"/noticia/" + posts[8].id} className="g-color-gray-dark-v1">{posts[8].title.rendered}</Link>
                      </h3>

                      <ul className="u-list-inline g-font-size-12 g-color-gray-dark-v4">
                        <li className="list-inline-item">
                        <Moment locale="es">{posts[8].date}</Moment>

                        </li>
                      
                      </ul>
                    </div>
                  </article>
                  {/* End Other Articles */}

                  <hr className="g-brd-gray-light-v4 g-my-25"/>

                  {/* Other Articles */}
                  <article className="media">
                    <figure className="d-flex u-shadow-v25 mr-3 g-pos-rel">
                      <img className="g-width-140 g-height-80" src={posts[10]._embedded["wp:featuredmedia"] ? posts[10]._embedded["wp:featuredmedia"][0].source_url : "https://loremflickr.com/745/450/" + terminos[2] } alt="Image Description"/>
                    </figure>

                    <div className="media-body">
                      <h3 className="g-font-size-16">
                      <Link to={"/noticia/" + posts[10].id} className="g-color-gray-dark-v1">{posts[10].title.rendered}</Link>
                      </h3>

                      <ul className="u-list-inline g-font-size-12 g-color-gray-dark-v4">
                        <li className="list-inline-item">
                        <Moment locale="es">{posts[10].date}</Moment>

                        </li>
                       
                      </ul>
                    </div>
                  </article>
                  {/* End Other Articles */}

                  <hr className="g-brd-gray-light-v4 g-my-25"/>

                  {/* Other Articles */}
                  <article className="media">
                    <figure className="d-flex u-shadow-v25 mr-3 g-pos-rel">
                      <img className="g-width-140 g-height-80" src={posts[12]._embedded["wp:featuredmedia"] ? posts[12]._embedded["wp:featuredmedia"][0].source_url : "https://loremflickr.com/745/450/" + terminos[3] } alt="Image Description"/>

                    
                    </figure>

                    <div className="media-body">
                      <h3 className="g-font-size-16">
                      <Link to={"/noticia/" + posts[12].id} className="g-color-gray-dark-v1">{posts[12].title.rendered}</Link>
                      </h3>

                      <ul className="u-list-inline g-font-size-12 g-color-gray-dark-v4">
                        <li className="list-inline-item">
                        <Moment locale="es">{posts[12].date}</Moment>

                        </li>
                       
                      </ul>
                    </div>
                  </article>
                  {/* End Other Articles */}
                </div>
              </div>
            </div>
            </div>
            </div>

)
}
