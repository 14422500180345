import React from 'react'

export const Enlaces = ( {post} ) => {
    return (
<section className="container">
          <div className="row">
            <div className="col-lg-9 ">
              <div className="mb-4">
                <h2 className="h3 text-uppercase mb-3">ENLACES</h2>
                <div className="g-width-60 g-height-1 g-bg-black"></div>
              </div>
              <div className="mb-5">
              <p><a href={ post.paginaweb } >{post.paginaweb}</a></p>
              { post.facebook != "" ?
              <a class="u-icon-v2 g-color-facebook g-color-facebook--hover g-mr-15 g-mb-20" href={post.facebook}>
                <i class="fa fa-facebook"></i>
              </a>
              :
              ""
              }
              { post.instagram != "" ?
              <a class="u-icon-v2 g-color-pinterest g-color-pinterest--hover g-mr-15 g-mb-20" href={post.instagram}>
                <i class="fa fa-instagram"></i>
              </a>
              :
              ""
              }
              { post.twitter != "" ?
              <a class="u-icon-v2 g-color-twitter g-color-twitter--hover g-mr-15 g-mb-20" href={post.twitter}>
                <i class="fa fa-twitter"></i>
              </a>
              :
              ""
              }
              { post.linkedin != "" ?
              <a class="u-icon-v2 g-color-linkedin g-color-linkedin--hover g-mr-15 g-mb-20" href={post.twitter}>
                <i class="fa fa-linkedin"></i>
              </a>
              :
              ""
              }
              { post.youtube != "" ?
              <a class="u-icon-v2 g-color-youtube g-color-youtube--hover g-mr-15 g-mb-20" href={post.twitter}>
                <i class="fa fa-youtube"></i>
              </a>
              :
              ""
              }
              { post.flickr != "" ?
              <a class="u-icon-v2 g-color-flickr g-color-flickr--hover g-mr-15 g-mb-20" href={post.twitter}>
                <i class="fa fa-flickr"></i>
              </a>
              :
              ""
              }

              </div>
          
            </div>
        
            <div className="col-lg-3 g-mb-60">
            
            </div>
          </div>
        </section>
    )
}
