import { json } from "d3-fetch";
import React, {useState, useEffect} from 'react';

export const Botones = ({setPrimeraCat}) => {

    const [ categoriaswp, setCategoriaswp] = useState([]);
    const [ loading, setLoading] = useState(true);
  
    useEffect(() => {
      json(`/datos/ods/categoriaswp.json`).then((data) => {
        setCategoriaswp(data);
      });

    }, []);

    const handleClick = (e) => {
        console.log(e.target.name);
        e.preventDefault();
        if(e.target.name === "todos") {
            setPrimeraCat(true);
        } else {
            setPrimeraCat(e.target.name);
        }
    }

    return (
        <section className="container g-pt-10 g-pb-10">
            <div classNane="row">
                
        {
            categoriaswp.map( cat => (
        
            <img src={cat.imagent} name={cat.categoriawp} width="50%" className="g-pl-10 g-pb-10" onClick={handleClick}/>
            ))
        }
        <img src="assets/datos/ods/ods18.jpg" name="todos" width="50%" className="g-pl-10 g-pb-10" onClick={handleClick}/>
        </div>
        </section>

    )
}