import React from 'react'
import { Medio } from './Medio'
import Nouislider from 'react-nouislider';
import 'nouislider/distribute/nouislider.css';


export const Item3 = ({seleccion, seleccionMedio, setSeleccionMedio, dedicacion, setDedicacion, carousel}) => {

    const medios = {"medio 01": "Youtube", "medio 02": "Curso MOOC", "medio 03": "Documentos"};
  
    const handleClick = (e) => {
        e.preventDefault();
        carousel.current.goTo(3);
    }

    const handleChange = (e) => {
      setDedicacion(parseInt(e[0]));
    }

    return (
    <div class="row no-gutters">
      <div class="col-lg-6 g-bg-black g-pa-60 g-pa-100--sm">
        <h3 class="h4 g-color-white g-font-weight-700 g-mb-25">MEDIOS</h3>

        <div class="g-mb-50">
          <p class="g-color-white-opacity-0_7 g-mb-30">Se va a seleccionar par a ti documentos de diferentes tipos. No hace falta que selecciones nada.</p>
          
         <Medio
            seleccionMedio={seleccionMedio}
            setSeleccionMedio={setSeleccionMedio}
         />
                 <h3 class="h4 g-color-white g-font-weight-700 g-mb-25">DEDICACIÓN</h3>

           <p class="g-color-white-opacity-0_7 g-mb-30">¿Cuántas horas puede dedicarle a trabajar sobre el {seleccion}?.</p>
           <br/>
         <Nouislider
         pips={{
          mode: 'range',
          density: 3
        }}
            range={{min: 0, max: 200}}
            start={[dedicacion]}
            step = { 1 }
            tooltips
            onChange = {e => handleChange(e)}

         />
        </div>


            <a class="btn btn-lg u-btn-primary g-font-weight-600 g-font-size-default text-uppercase g-rounded-50" href="#" onClick={e => handleClick(e)}>Continuar con {dedicacion} horas</a>


      </div>

      <div class="col-lg-6 g-min-height-400 g-bg-size-cover g-bg-pos-center" style={{backgroundImage: "url(../../assets/img-temp/900x900/img3.jpg)"}}></div>
    </div>
    )
}
