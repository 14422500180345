import { json } from "d3-fetch";
import React, {useState, useEffect} from 'react';


export const Nivel = ({seleccionNivel, setSeleccionNivel}) => {

    console.log(seleccionNivel);

    const [ niveles, setNiveles] = useState([]);

    useEffect(() => {
        json(`/datos/ods/niveles.json`).then((data) => {
          setNiveles(data);
        });
  
      }, []);

      const handleClick = (e) => {
        console.log(e.target.name);
        e.preventDefault();
        setSeleccionNivel(e.target.name);
    }

    return (
        <section className="container g-pt-10 g-pb-10">
        <div classNane="row">
            
        {
            niveles.map( nivel => (
                
                    nivel.nivel != seleccionNivel ?
                    <img src={nivel.imagen} name={nivel.nivel} width="30%" className="g-pl-10 g-pb-10" onClick={handleClick}/>
                    :
                    <img src={nivel.imagent} name={nivel.nivel} width="30%" className="g-pl-10 g-pb-10" onClick={handleClick}/>
                )
            )
        }

    </div>
    </section>
    )
}
