import React, { useState, useEffect, useRef } from 'react';
import Carousel from 'react-elastic-carousel';
import { dsv } from 'd3-fetch'
import { Barra } from './Barra';
import { Item1 } from './Item1';
import { Item2 } from './Item2';
import { Item3 } from './Item3';
import { Item4 } from './Item4';
import { Item5 } from './Item5';

export const Proceso2 = () => {

    let carousel = useRef(Carousel);

    const [ boton, setBoton ] = useState(1);

    const [seleccion, setSeleccion] = useState("");
    
    const [seleccionNivel, setSeleccionNivel] = useState("");
    
    const [seleccionMedio, setSeleccionMedio] = useState("");

    const [dedicacion, setDedicacion] = useState(50);

    const [data, setData] = useState([]);   
    
    useEffect(() => {
        dsv(';',`/datos/ods/plev2.csv`).then((data) => {
            setData(data);
          });
    }, [])

    const cambiarBoton = (boton) => {
        setBoton(boton);
        carousel.current.goTo(boton-1);
        console.log(boton);

    }

    const handleChange = (e) => {
        setBoton(e.index+1);
    }



    return (
        <>
            <Barra 
                boton={ boton }
                cambiarBoton ={ cambiarBoton }
            />    
            <Carousel 
                ref={carousel}
                onChange={e => handleChange(e)}
                showArrows={false}
                pagination={false}
            >
                <Item1
                    seleccion={seleccion}
                    setSeleccion={setSeleccion}
                    carousel={ carousel }  
                />
                <Item2
                    seleccionNivel={seleccionNivel}
                    setSeleccionNivel={setSeleccionNivel}
                    carousel={ carousel }  
                />
                <Item3
                    seleccion={seleccion}
                    seleccionMedio={seleccionMedio}
                    setSeleccionMedio={setSeleccionMedio}
                    dedicacion = { dedicacion }
                    setDedicacion = { setDedicacion }
                    carousel={ carousel }
                />
                <Item4
                    seleccion = { seleccion }
                    seleccionNivel = { seleccionNivel }
                    seleccionMedio = { seleccionMedio }
                    dedicacion = { dedicacion }
                    carousel={ carousel }

                />
                <Item5
                    seleccion = { seleccion }
                    seleccionNivel = { seleccionNivel }
                    seleccionMedio = { seleccionMedio }
                    dedicacion = { dedicacion }
                    data = { data }
                    carousel={ carousel }  
                />

            </Carousel>
        
      </>
    
    )
}
