import { Atlas } from './Atlas';
import { Barras } from './Barras';
import { Botones } from './Botones';

export const Profile12 = ({cambiarOds, ods, titulo}) => {
    return (
        <section class="g-mb-100">
        <div class="container">
          <div class="row">
            {/* Profile Sidebar */}
            <div class="col-lg-3 g-mb-50 g-mb-0--lg">
              {/* User Image */}
              <div class="u-block-hover g-pos-rel">
                <figure>
                  <img class="img-fluid w-100 u-block-hover__main--zoom-v1" src="/assets/datos/ods/ods12.jpg" alt="Image Description"/>
                </figure>
                <Atlas
                  titulo = {"SDG 12: Responsible consumption and production"}
                  enlace = {"https://datatopics.worldbank.org/sdgatlas/goal-12-responsible-consumption-and-production"}
                  subtitulo = {"Reducing waste for a sustainable future"}
                  imagen = {"/assets/datos/atlas/goal-12.ab39e4ee.png"}
                />
                {/*<Botones cambiarOds={cambiarOds} />*/}

                {/* Figure Caption */}
                 {/*<figcaption class="u-block-hover__additional--fade g-bg-black-opacity-0_5 g-pa-30">
                  <div class="u-block-hover__additional--fade u-block-hover__additional--fade-up g-flex-middle">
                    {/* Figure Social Icons */}
                     {/*<ul class="list-inline text-center g-flex-middle-item--bottom g-mb-20">
                      <li class="list-inline-item align-middle g-mx-7">
                        <a class="u-icon-v1 u-icon-size--md g-color-white" href="#">
                          <i class="icon-note u-line-icon-pro"></i>
                        </a>
                      </li>
                      <li class="list-inline-item align-middle g-mx-7">
                        <a class="u-icon-v1 u-icon-size--md g-color-white" href="#">
                          <i class="icon-notebook u-line-icon-pro"></i>
                        </a>
                      </li>
                      <li class="list-inline-item align-middle g-mx-7">
                        <a class="u-icon-v1 u-icon-size--md g-color-white" href="#">
                          <i class="icon-settings u-line-icon-pro"></i>
                        </a>
                      </li>
                    </ul>
                    {/* End Figure Social Icons */}
                   {/*</div>
                </figcaption>
                {/* End Figure Caption */}
  
                {/* User Info */}
                {/*
                 <span class="g-pos-abs g-top-20 g-left-0">
                    <a class="btn btn-sm u-btn-primary rounded-0" href="#">Johne Doe</a>
                    <small class="d-block g-bg-black g-color-white g-pa-5">Project Manager</small>
                  </span>
                */}
                {/* End User Info */}
              </div>
              {/* User Image */}
  
              {/* Sidebar Navigation */}
              {/* <div class="list-group list-group-border-0 g-mb-40">
                {/* Overall */}
                {/* <a href="page-profile-main-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-home g-pos-rel g-top-1 g-mr-8"></i> Overall</span>
                  <span class="u-label g-font-size-11 g-bg-gray-dark-v2 g-rounded-20 g-px-10">2</span>
                </a>
                {/* End Overall */}
  
                {/* Profile */}
              {/*   <a href="page-profile-profile-1.html" class="list-group-item justify-content-between active">
                  <span><i class="icon-cursor g-pos-rel g-top-1 g-mr-8"></i> Profile</span>
                </a>
                {/* End Profile */}
  
                {/* Users Contacts */}
               {/*  <a href="page-profile-users-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-notebook g-pos-rel g-top-1 g-mr-8"></i> Users Contacts</span>
                </a>
                {/* End Users Contacts */}
  
                {/* My Projects */}
               {/*  <a href="page-profile-projects-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-layers g-pos-rel g-top-1 g-mr-8"></i> My Projects</span>
                  <span class="u-label g-font-size-11 g-bg-primary g-rounded-20 g-px-10">9</span>
                </a>
                {/* End My Projects */}
  
                {/* Comments */}
                {/* <a href="page-profile-comments-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-bubbles g-pos-rel g-top-1 g-mr-8"></i> Comments</span>
                  <span class="u-label g-font-size-11 g-bg-pink g-rounded-20 g-px-8">24</span>
                </a>
                {/* End Comments */}
  
                {/* Reviews */}
                {/* <a href="page-profile-reviews-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-heart g-pos-rel g-top-1 g-mr-8"></i> Reviews</span>
                </a>
                {/* End Reviews */}
  
                {/* History */}
                {/* <a href="page-profile-history-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-fire g-pos-rel g-top-1 g-mr-8"></i> History</span>
                </a>
                {/* End History */}
  
                {/* Settings */}
                {/* <a href="page-profile-settings-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-settings g-pos-rel g-top-1 g-mr-8"></i> Settings</span>
                  <span class="u-label g-font-size-11 g-bg-cyan g-rounded-20 g-px-8">3</span>
                </a>
                {/* End Settings */}
              {/* </div>
              {/* End Sidebar Navigation */}
  
              {/* Project Progress */}
              <div class="card border-0 rounded-0 g-mb-50">
                {/* Panel Header */}
                {/* <div class="card-header d-flex align-items-center justify-content-between g-bg-gray-light-v5 border-0 g-mb-15">
                  <h3 class="h6 mb-0">
                      <i class="icon-layers g-pos-rel g-top-1 g-mr-5"></i> Project Progress
                    </h3>
                  <div class="dropdown g-mb-10 g-mb-0--md">
                    <span class="d-block g-color-primary--hover g-cursor-pointer g-mr-minus-5 g-pa-5" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="icon-options-vertical g-pos-rel g-top-1"></i>
                      </span>
                    <div class="dropdown-menu dropdown-menu-right rounded-0 g-mt-10">
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-layers g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> Projects
                      </a>
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-wallet g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> Wallets
                      </a>
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-fire g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> Reports
                      </a>
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-settings g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> Users Setting
                      </a>
  
                      <div class="dropdown-divider"></div>
  
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-plus g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> View More
                      </a>
                    </div>
                  </div>
                </div>
                {/* End Panel Header */}
  
                {/* Panel Body */}
                <div class="js-scrollbar card-block u-info-v1-1 g-bg-white-gradient-v1--after g-height-300 g-pa-0">
                  {/* Web Design */}
                 {/*  <div class="g-mb-20">
                    <h6 class="g-mb-10">Web Design <span class="float-right g-ml-10">68%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-cyan u-progress-bar--xs" role="progressbar" style={{width: "68%", ariaValuenow:"68", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">11% more than last week</small>
                  </div>
                  {/* End Web Design */}
  
                  {/* Unify Project */}
                 {/*  <div class="g-mb-20">
                    <h6 class="g-mb-10">Dribbble Shots <span class="float-right g-ml-10">62%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-pink u-progress-bar--xs" role="progressbar" style={{width: "62%", ariaValuenow:"62", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">20% less than last week</small>
                  </div>
                  {/* End Unify Project */}
  
                  {/* Unify Project */}
                 {/*  <div class="g-mb-20">
                    <h6 class="g-mb-10">Unify Project <span class="float-right g-ml-10">93%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-primary u-progress-bar--xs" role="progressbar" style={{width: "93%", ariaValuenow:"93", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">17% more than last week</small>
                  </div>
                  {/* End Unify Project */}
  
                  {/* WordPress Coding */}
                {/*   <div class="g-mb-20">
                    <h6 class="g-mb-10">WordPress Coding <span class="float-right g-ml-10">74%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-black u-progress-bar--xs" role="progressbar" style={{width: "74%", ariaValuenow:"74", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">35% more than last week</small>
                  </div>
                  {/* End WordPress Coding */}
  
                  {/* Pixeel Ltd */}
                 {/*  <div class="g-mb-20">
                    <h6 class="g-mb-10">Pixeel Ltd <span class="float-right g-ml-10">86%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-darkpurple u-progress-bar--xs" role="progressbar" style={{width: "86%", ariaValuenow:"86", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">42% more than last week</small>
                  </div>
                  {/* End Pixeel Ltd */}
                </div>
                {/* End Panel Body */}
              </div>
              {/* End Project Progress */}
            </div>
            {/* End Profile Sidebar */}
  
            {/* Profle Content */}
            <div class="col-lg-9">
            {/* Nav tabs */}
            <ul class="nav nav-justified u-nav-v1-1 u-nav-red g-brd-bottom--md g-brd-bottom-2 g-brd-darkred g-mb-20" role="tablist" data-target="nav-2-1-default-hor-left-underline" data-tabs-mobile-type="slide-up-down" data-btn-classes="btn btn-md btn-block rounded-0 u-btn-outline-primary g-mb-20">
              <li class="nav-item">
                <a class="nav-link g-py-10 active" data-toggle="tab" href="#nav-2-1-default-hor-left-underline--1" role="tab">Presentación</a>
              </li>
              <li class="nav-item">
                <a class="nav-link g-py-10" data-toggle="tab" href="#nav-2-1-default-hor-left-underline--2" role="tab">Metas</a>
              </li>
              <li class="nav-item">
                <a class="nav-link g-py-10" data-toggle="tab" href="#nav-2-1-default-hor-left-underline--3" role="tab"> Estadísticas</a>
              </li>
              <li class="nav-item">
                <a class="nav-link g-py-10" data-toggle="tab" href="#nav-2-1-default-hor-left-underline--4" role="tab">Informes</a>
              </li>
            </ul>
            {/* End Nav tabs */}

            {/* Tab panes */}
            <div id="nav-2-1-default-hor-left-underline" class="tab-content">
              {/* Edit Profile */}
              <div class="tab-pane fade show active" id="nav-2-1-default-hor-left-underline--1" role="tabpanel" data-parent="#nav-2-1-default-hor-left-underline">
                <h2 class="h4 g-font-weight-300">{ods} | {titulo}</h2>
                <p>El consumo y la producción mundiales (fuerzas impulsoras de la economía mundial) dependen del uso del medio ambiente natural y de los recursos de una manera que continúa teniendo efectos destructivos sobre el planeta.</p>

                <p>El progreso económico y social conseguido durante el último siglo ha estado acompañado de una degradación medioambiental que está poniendo en peligro los mismos sistemas de los que depende nuestro desarrollo futuro (y ciertamente, nuestra supervivencia).</p>

                <p>La pandemia de la COVID-19 ofrece a los países la oportunidad de elaborar planes de recuperación que reviertan las tendencias actuales y cambien nuestros patrones de consumo y producción hacia un futuro más sostenible.</p>

                <p>El consumo y la producción sostenibles consisten en hacer más y mejor con menos. También se trata de desvincular el crecimiento económico de la degradación medioambiental, aumentar la eficiencia de recursos y promover estilos de vida sostenibles.</p>

                <p>El consumo y la producción sostenibles también pueden contribuir de manera sustancial a la mitigación de la pobreza y a la transición hacia economías verdes y con bajas emisiones de carbono.</p>


                
              </div>
              {/* End Edit Profile */}

              {/* Security Settings */}
              <div class="tab-pane fade" id="nav-2-1-default-hor-left-underline--2" role="tabpanel" data-parent="#nav-2-1-default-hor-left-underline">
                <h2 class="h4 g-font-weight-300">{ods} | Retos a alcanzar</h2>
                <p class="g-mb-25">
                    <strong>12.1.</strong> Aplicar el Marco Decenal de Programas sobre Modalidades de Consumo y Producción Sostenibles, con la participación de todos los países y bajo el liderazgo de los países desarrollados, teniendo en cuenta el grado de desarrollo y las capacidades de los países en desarrollo.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>12.2.</strong> De aquí a 2030, lograr la gestión sostenible y el uso eficiente de los recursos naturales.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>12.3.</strong> De aquí a 2030, reducir a la mitad el desperdicio de alimentos per capita mundial en la venta al por menor y a nivel de los consumidores y reducir las pérdidas de alimentos en las cadenas de producción y suministro, incluidas las pérdidas posteriores a la cosecha.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>12.4.</strong> De aquí a 2020, lograr la gestión ecológicamente racional de los productos químicos y de todos los desechos a lo largo de su ciclo de vida, de conformidad con los marcos internacionales convenidos, y reducir significativamente su liberación a la atmósfera, el agua y el suelo a fin de minimizar sus efectos adversos en la salud humana y el medio ambiente.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>12.5.</strong> De aquí a 2030, reducir considerablemente la generación de desechos mediante actividades de prevención, reducción, reciclado y reutilización.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>12.6.</strong> Alentar a las empresas, en especial las grandes empresas y las empresas transnacionales, a que adopten prácticas sostenibles e incorporen información sobre la sostenibilidad en su ciclo de presentación de informes.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>12.7.</strong> Promover prácticas de adquisición pública que sean sostenibles, de conformidad con las políticas y prioridades nacionales.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>12.8.</strong> De aquí a 2030, asegurar que las personas de todo el mundo tengan la información y los conocimientos pertinentes para el desarrollo sostenible y los estilos de vida en armonía con la naturaleza.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>12.a.</strong> Ayudar a los países en desarrollo a fortalecer su capacidad científica y tecnológica para avanzar hacia modalidades de consumo y producción más sostenibles.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>12.b.</strong> Elaborar y aplicar instrumentos para vigilar los efectos en el desarrollo sostenible, a fin de lograr un turismo sostenible que cree puestos de trabajo y promueva la cultura y los productos locales.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>12.c.</strong> Racionalizar los subsidios ineficientes a los combustibles fósiles que fomentan el consumo antieconómico eliminando las distorsiones del mercado, de acuerdo con las circunstancias nacionales, incluso mediante la reestructuración de los sistemas tributarios y la eliminación gradual de los subsidios perjudiciales, cuando existan, para reflejar su impacto ambiental, teniendo plenamente en cuenta las necesidades y condiciones específicas de los países en desarrollo y minimizando los posibles efectos adversos en su desarrollo, de manera que se proteja a los pobres y a las comunidades afectadas.


                
                </p>

               
              </div>
              {/* End Security Settings */}

              {/* Payment Options */}
              <div class="tab-pane fade" id="nav-2-1-default-hor-left-underline--3" role="tabpanel" data-parent="#nav-2-1-default-hor-left-underline">
                <h2 class="h4 g-font-weight-300">{ods} | Grado de consecución del objetivo</h2>
                <p class="g-mb-25">Fuente: <a href="https://unstats.un.org/sdgs/report/2020/">https://unstats.un.org/sdgs/report/2020/</a>
</p>

                <Barras
                color="#C08D2C"
                data = {[93.34,83.95,87.25,86.64,91.09,78.21,89.61,76.13,86.61]}
                />
              </div>
              {/* End Payment Options */}

              {/* Notification Settings */}
              <div class="tab-pane fade" id="nav-2-1-default-hor-left-underline--4" role="tabpanel" data-parent="#nav-2-1-default-hor-left-underline">
                <h2 class="h4 g-font-weight-300">{ods} | Documentación</h2>
                <p class="g-mb-25">Below are the notifications you may manage.</p>

                  {/* Email Notification */}
                  <div class="form-group">
                    <label class="d-flex align-items-center justify-content-between">
                      <span>PRODUCCIÓN Y CONSUMO RESPONSABLES: POR QUÉ ES IMPORTANTE</span>
                      <div class="u-check">
                      <a href="https://www.un.org/sustainabledevelopment/es/wp-content/uploads/sites/3/2016/10/12_Spanish_Why_it_Matters.pdf">Descargar documento</a> 
                      </div>
                    </label>
                  </div>
                  <div class="form-group">
                    <label class="d-flex align-items-center justify-content-between">
                      <span>Plan de Acción sobre Consumo y Producción Sostenibles y una Política Industrial Sostenible</span>
                      <div class="u-check">
                      <a href="https://odssocialnetwork.org/documentos/Plan acción Europa consumo sostenible 12.pdf">Descargar documento</a> 

                      </div>
                    </label>
                  </div>
                  {/* End Email Notification */}

                  <hr class="g-brd-gray-light-v4 g-my-20"/>

              </div>
              {/* End Notification Settings */}
            </div>
            {/* End Tab panes */}
          </div>
            {/* End Profle Content */}
          </div>
        </div>
      </section>
    )
}