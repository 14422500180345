export const Terminos = ({titulo, texto}) => {

    return (
        <>
        {/* Promo Block */}
        <section className="container g-py-15">
          <div className="row">
            <div className="col-lg-6">
              <div className="d-inline-block g-width-80 g-height-4 g-bg-black mb-3"></div>
              <h2 className="g-color-black g-font-weight-700 g-font-size-50 g-line-height-1 mb-4">{titulo}</h2>
              <p className="mb-0">{texto}</p>
            </div>
          </div>
        </section>
        {/* End Promo Block */}
        </>
    )
}