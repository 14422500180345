import React, {useState, useEffect} from 'react';
import { ONGMini } from "./ONGMini";
import { json } from "d3-fetch";
import LoadingOverlay from 'react-loading-overlay';
import { Botones } from './Botones';


export const ListaOng = ({primeraCat,setPrimeraCat}) => {

  const componerUrl = () => {
    if(primeraCat === true) 
      return "https://backend.odssocialnetwork.org//wp-json/wp/v2/posts?_embed&tags=104&per_page=100&orderby=slug&order=asc";

      if(primeraCat !== true) 
      return "https://backend.odssocialnetwork.org//wp-json/wp/v2/posts?_embed&tags=104&per_page=100&orderby=slug&order=asc&categories=" + primeraCat.toString();

  }
    const url = componerUrl();
    const [data, setData] = useState([]);
    const [ categoriaswp, setCategoriaswp] = useState([]);
    const [ loading, setLoading] = useState(true);
  
    useEffect(() => {
      json(`/datos/ods/categoriaswp.json`).then((data) => {
        setCategoriaswp(data);
      });
    }, []);
 
      useEffect(() => {
        setLoading(true);
      fetch(url)
            .then(response => response.json())
            .then(posts => {
                setData(posts);
                console.log(posts);
                setLoading(false);
      });

    }, [primeraCat]);

    const handleClick = (e) => {
      e.preventDefault();
      setPrimeraCat(true);
    }

    return (
        <>
        <LoadingOverlay
        active={loading}
        spinner
        text='Cargando contenido...'
        >
        { loading ? "Cargando..." :
        <>
        {/* Popular Jobs */}
    <section className="g-py-10">
      <div className="container">
        <header className="text-center g-width-60x--md mx-auto g-mb-50">
    <h2 className="h1 g-color-gray-dark-v1 g-font-weight-300">Mostrando {data.length} Entidades</h2>
          

        </header>

        <div className="row g-mb-30">             
            {
                data.map( ong => (
                  <ONGMini
                    key={ong.id}
                    ong={ong}
                  />
                ))
              }
        </div>

        <div className="text-center">
          <a className="btn btn-xl u-btn-outline-primary text-uppercase g-font-weight-600 g-font-size-12" href="#" onClick={handleClick}>Ver todas</a>
        </div>
      </div>
    </section>
    {/* End Popular Jobs */}
    </>
    }
    </LoadingOverlay>
        </>
    )
}