import React,{ useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from '../layout/Breadcrumb';
import Moment from 'react-moment';
import { json } from "d3-fetch";
import { Categoria } from './Categoria';

import LoadingOverlay from 'react-loading-overlay';
import { Terminos } from '../layout/Terminos';
import { MasNoticias } from './MasNoticias';

import "./Noticias.css";



export const Noticias = () => {
    
    const url = "https://backend.odssocialnetwork.org//wp-json/wp/v2/posts?_embed&tags=105&per_page=15";

    const [ posts, setPosts] = useState([]);
    const [ categoriaswp, setCategoriaswp] = useState([]);
    const [ loading, setLoading] = useState(true);

    const terminos = ["poor","world","action","people"];


    useEffect(() => {
        json(`/datos/ods/categoriaswp.json`).then((data) => {
            setCategoriaswp(data);
          });
        fetch(url)
            .then(response => response.json())
            .then(posts => {
                console.log(posts);
                setPosts(posts);
                setLoading(false);
    })},[]);


    return (
      <LoadingOverlay
      active={loading}
      spinner
      text='Cargando contenido...'
      >
        <Breadcrumb
            info1 = "Actualidad"
            info2 = ""
            camino = "actualidad"
        />
        <Terminos
          titulo="Actualidad"
          texto="Noticias, eventos y novedades"
        />

      { loading ? "Cargando..." :
        
        <>
      <section className="g-py-50">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-lg-6 g-pr-1--lg g-mb-30 g-mb-2--lg">
              <article className="u-block-hover">
                <figure className="u-shadow-v25 g-bg-cover g-bg-white-gradient-opacity-v1--after">
                  <img className="img-fluid w-100 u-block-hover__main--zoom-v1 imagen-actualidad" src={posts[0]._embedded["wp:featuredmedia"] ? posts[0]._embedded["wp:featuredmedia"][0].source_url : "https://loremflickr.com/745/450/" + terminos[0] } alt="PRUEBA"/>
                </figure>

                <span className="g-hidden-xs-down g-pos-abs g-top-30 g-left-30">
                    
                    {
                        posts[0].categories.map( cat => (
                            <Categoria
                            categoriaswp = {categoriaswp}
                            cat = {cat}
                            imagen = {false}
                        />
                        ))
                    }

                </span>
               

                <div className="g-pos-abs g-bottom-30 g-left-30 g-right-30">
                  <small className="g-color-white">
                    <i className="icon-clock g-pos-rel g-top-1 g-mr-2"></i><Moment locale="es">{posts[0].date}</Moment>

                  </small>

                  <h3 className="h4 g-my-10">
                    <Link to={"/noticia/" + posts[0].id} className="g-color-white g-color-white--hover" >{posts[0].title.rendered}</Link>
                  </h3>

                 
                </div>
              </article>
            </div>

            <div className="col-lg-6 g-pl-1--lg g-mb-30 g-mb-2--lg">
              <article className="u-block-hover">
                <figure className="u-shadow-v25 g-bg-cover g-bg-white-gradient-opacity-v1--after">
                  <img className="img-fluid w-100 u-block-hover__main--zoom-v1 imagen-actualidad" src={posts[1]._embedded["wp:featuredmedia"] ? posts[1]._embedded["wp:featuredmedia"][0].source_url : "https://loremflickr.com/745/450/" + terminos[1] } alt="PRUEBA"/>
                </figure>

             

                <span className="g-hidden-xs-down g-pos-abs g-top-30 g-left-30">
                {
                        posts[1].categories.map( cat => (
                            <Categoria
                            categoriaswp = {categoriaswp}
                            cat = {cat}
                        />
                        ))
                    }
                </span>

                <div className="g-pos-abs g-bottom-30 g-left-30 g-right-30">
                  <small className="g-color-white">
                    <i className="icon-clock g-pos-rel g-top-1 g-mr-2"></i><Moment locale="es">{posts[1].date}</Moment>
                  </small>

                  <h3 className="h4 g-my-10">
                  <Link to={"/noticia/" + posts[1].id} className="g-color-white g-color-white--hover" >{posts[1].title.rendered}</Link>
                  </h3>                
                </div>
              </article>
            </div>

            <div className="col-lg-4 g-pr-1--lg g-mb-30 g-mb-0--lg">
              <article className="u-block-hover">
                <figure className="u-shadow-v25 u-bg-overlay g-bg-white-gradient-opacity-v1--after">
                  <img className="u-block-hover__main--zoom-v1 img-fluid w-100 imagen-actualidad2" src={posts[2]._embedded["wp:featuredmedia"] ? posts[2]._embedded["wp:featuredmedia"][0].source_url : "https://loremflickr.com/745/450/" + terminos[2] } alt="PRUEBA"/>
                </figure>

                <div className="w-100 text-center g-absolute-centered g-px-30">
                {
                        posts[2].categories.map( cat => (
                            <Categoria
                            categoriaswp = {categoriaswp}
                            cat = {cat}
                        />
                        ))
                    }
                  <h3 className="h4 g-mt-10">
                    <Link to={"/noticia/" + posts[2].id} className="g-color-white" >{posts[2].title.rendered}</Link>
                  </h3>
                  <small className="g-color-white">
                    <i className="icon-clock g-pos-rel g-top-1 g-mr-2"></i> <Moment locale="es">{posts[2].date}</Moment>
                  </small>
                </div>
              </article>
            </div>

            <div className="col-lg-4 g-px-1--lg g-mb-30 g-mb-0--lg">
              <article className="u-block-hover">
                <figure className="u-shadow-v25 u-bg-overlay g-bg-white-gradient-opacity-v1--after">
                  <img className="u-block-hover__main--zoom-v1 img-fluid w-100 imagen-actualidad2" src={posts[3]._embedded["wp:featuredmedia"] ? posts[3]._embedded["wp:featuredmedia"][0].source_url : "https://loremflickr.com/745/450/" + terminos[3] } alt="PRUEBA"/>
                </figure>

                <div className="w-100 text-center g-absolute-centered g-px-30">
                {
                        posts[3].categories.map( cat => (
                            <Categoria
                            categoriaswp = {categoriaswp}
                            cat = {cat}
                        />
                        ))
                    }
                  <h3 className="h4 g-mt-10">
                    <Link to={"/noticia/" + posts[3].id} className="g-color-white" >{posts[3].title.rendered}</Link>
                  </h3>
                  <small className="g-color-white">
                    <i className="icon-clock g-pos-rel g-top-1 g-mr-2"></i> <Moment locale="es">{posts[3].date}</Moment>
                  </small>
                </div>
              </article>
            </div>

            <div className="col-lg-4 g-pl-1--lg">
              <article className="u-block-hover">
                <figure className="u-shadow-v25 u-bg-overlay g-bg-white-gradient-opacity-v1--after">
                  <img className="u-block-hover__main--zoom-v1 img-fluid w-100 imagen-actualidad2" src={posts[4]._embedded["wp:featuredmedia"] ? posts[4]._embedded["wp:featuredmedia"][0].source_url : "https://loremflickr.com/745/450/" + terminos[3] } alt="PRUEBA"/>
                </figure>

                <div className="w-100 text-center g-absolute-centered g-px-30">
                {
                        posts[4].categories.map( cat => (
                            <Categoria
                            categoriaswp = {categoriaswp}
                            cat = {cat}
                        />
                        ))
                    }
                  <h3 className="h4 g-mt-10">
                    <Link to={"/noticia/" + posts[4].id} className="g-color-white" >{posts[4].title.rendered}</Link>
                  </h3>
                  <small className="g-color-white">
                    <i className="icon-clock g-pos-rel g-top-1 g-mr-2"></i> <Moment locale="es">{posts[4].date}</Moment>
                  </small>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
      
      <section className="g-py-50">
        <div className="container">
          <MasNoticias
            posts={posts}
          />
        </div>
      </section>
      </>

      }

      </LoadingOverlay>
    )
}