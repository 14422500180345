import React,{useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Categoria } from '../Noticias/Categoria';
import { json } from "d3-fetch";


export const ONGMini = ( {ong} ) => {

    const [ categoriaswp, setCategoriaswp] = useState([]);
    const imagen = ong._embedded["wp:featuredmedia"][0].source_url;
    const nombre = ong.title.rendered;

    useEffect(() => {
      json(`/datos/ods/categoriaswp.json`).then((data) => {
          setCategoriaswp(data);
        });
    },[]);


    const buscarCategoriaONG = (categories) => {

      const bcategoriaswp = categoriaswp.map( cat => {
          return cat.categoriawp;
      })
      let resultado = [];
      const id = categories.map( cat => {
          if(bcategoriaswp.includes(cat)) {
          resultado.push(cat);
          }
      })
      return resultado;
     }
     const buscarCategoriaONGinversa = (categories) => {
  
      console.log(categories);
      const bcategoriaswp = categoriaswp.map( cat => {
          return cat.categoriawp;
      })
      let resultado = [];
      const id = categories.map( cat => {
          if(!bcategoriaswp.includes(cat)) {
          resultado.push(cat);
          }
      })
      return resultado;
     }


    return (
      
      <div className="col-lg-4 g-mb-40 g-mb-0--lg">
        <li className="media u-shadow-v11 rounded g-pa-20 g-mb-10">
                <div className="d-flex align-self-center g-mt-3 g-mr-15">
                <Link to={"/ong/" + ong.id} ><img className="g-width-80 g-height-80" src={imagen} alt="Image Description"/></Link>
                </div>
                <div className="media-body">
                <Link to={"/ong/" + ong.id} className="d-block u-link-v5 g-color-main g-color-primary--hover g-font-weight-600 g-mb-3" >{ nombre }</Link>
                <span>

                {
                        buscarCategoriaONG(ong.categories).map( cat => (
                            <Categoria
                            categoriaswp = {categoriaswp}
                            cat = {cat}
                        />
                        ))
                    }
                    </span>
                </div>
              </li>
              </div>
    )
}