import React from 'react'
import { Nivel } from './Nivel'

export const Item2 = ({seleccionNivel, setSeleccionNivel, carousel}) => {

    const handleClick = (e) => {
        e.preventDefault();
        carousel.current.goTo(2);
    }

    return (
    <div class="row no-gutters">
      <div class="col-lg-6 g-bg-black g-pa-60 g-pa-100--sm">
        <h3 class="h4 g-color-white g-font-weight-700 g-mb-25">NIVEL</h3>

        <div class="g-mb-50">
          <p class="g-color-white-opacity-0_7 g-mb-30">Los documentos, cursos o vídeos están clasificados en niveles. El nivel 1 es el más básico y el nivel 3 el más avanzado.</p>
            <p class="g-color-white-opacity-0_7 g-mb-30">Seleccione el nivel de conocimiento que cree que puede ser más interesante para obtener su PLE.</p>
         <Nivel
            seleccionNivel={seleccionNivel}
            setSeleccionNivel={setSeleccionNivel}
         />
        </div>
        {
          seleccionNivel.length > 0 
          ?
            <a class="btn btn-lg u-btn-primary g-font-weight-600 g-font-size-default text-uppercase g-rounded-50" href="#" onClick={e => handleClick(e)}>Continuar con {seleccionNivel} >></a>
          :
          ""
        }
        </div>

      <div class="col-lg-6 g-min-height-400 g-bg-size-cover g-bg-pos-center" style={{backgroundImage: "url(../../assets/img-temp/900x900/img2.jpg)"}}></div>
    </div>
    )
}
