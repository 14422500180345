import React from 'react'

export const Barra = ({boton, cambiarBoton}) => {

    const handleClick = (e, boton) => {
        cambiarBoton(boton);
    }

    return (
        <div class="row">
          <div class="col-sm-6 col-lg-3 g-mb-60">
            <div class="text-center">
              <h3 class="h5 g-font-weight-600 g-mb-10">ODS</h3>
              <i class="u-dot-line-v2-2 g-brd-transparent--before g-brd-gray-light-v2--after g-mt-15" onClick={ e => handleClick(e, 1)}>
                { boton === 1 ?
                <span class="u-dot-line-v2__inner g-bg-white--before g-brd-green-light-v2--before"></span>
                :
                <span class="u-dot-line-v2__inner g-bg-white--before g-brd-gray-light-v2--before"></span>        
                }
              </i>
            </div>
          </div>
            
          <div class="col-sm-6 col-lg-3 g-mb-60">
            <div class="text-center">
              <h3 class="h5 g-font-weight-600 g-mb-10">NIVEL</h3>
              <i class="u-dot-line-v2-2 g-brd-gray-light-v2--before g-brd-gray-light-v2--after g-mt-15" onClick={ e => handleClick(e, 2)}>
              { boton === 2 ?
                <span class="u-dot-line-v2__inner g-bg-white--before g-brd-green-light-v2--before"></span>
                :
                <span class="u-dot-line-v2__inner g-bg-white--before g-brd-gray-light-v2--before"></span>        
                }      </i>
            </div>
          </div>
            
          <div class="col-sm-6 col-lg-3 g-mb-60">
            <div class="text-center">
              <h3 class="h5 g-font-weight-600 g-mb-10">DEDICACIÓN</h3>
              <i class="u-dot-line-v2-2 g-brd-gray-light-v2--before g-brd-gray-light-v2--after g-mt-15" onClick={ e => handleClick(e, 3)}>
              { boton === 3 ?
                <span class="u-dot-line-v2__inner g-bg-white--before g-brd-green-light-v2--before"></span>
                :
                <span class="u-dot-line-v2__inner g-bg-white--before g-brd-gray-light-v2--before"></span>        
                }      </i>
            </div>
          </div>
            
          <div class="col-sm-6 col-lg-3 g-mb-60">
            <div class="text-center">
              <h3 class="h5 g-font-weight-600 g-mb-10">RESULTADO</h3>
              <i class="u-dot-line-v2-2 g-brd-gray-light-v2--before g-brd-transparent--after g-mt-15" onClick={ e => handleClick(e, 4)}>
              { boton === 4 ?
                <span class="u-dot-line-v2__inner g-bg-white--before g-brd-green-light-v2--before"></span>
                :
                <span class="u-dot-line-v2__inner g-bg-white--before g-brd-gray-light-v2--before"></span>        
                }
              </i>
            </div>
          </div>
        </div>
    )
}