import { Atlas } from './Atlas';
import { Barras } from './Barras';
import { Botones } from './Botones';

export const Profile6 = ({cambiarOds, ods, titulo}) => {
    return (
        <section class="g-mb-100">
        <div class="container">
          <div class="row">
            {/* Profile Sidebar */}
            <div class="col-lg-3 g-mb-50 g-mb-0--lg">
              {/* User Image */}
              <div class="u-block-hover g-pos-rel">
                <figure>
                  <img class="img-fluid w-100 u-block-hover__main--zoom-v1" src="/assets/datos/ods/ods6.jpg" alt="Image Description"/>
                </figure>
                <Atlas
                  titulo = {"SDG 6: Clean water and sanitation"}
                  enlace = {"https://datatopics.worldbank.org/sdgatlas/goal-6-clean-water-and-sanitation"}
                  subtitulo = {"Water, sanitation, and hygiene: essential for well-being"}
                  imagen = {"/assets/datos/atlas/goal-6.b01848a8.png"}
                />
                {/*<Botones cambiarOds={cambiarOds} />*/}

                {/* Figure Caption */}
                 {/*<figcaption class="u-block-hover__additional--fade g-bg-black-opacity-0_5 g-pa-30">
                  <div class="u-block-hover__additional--fade u-block-hover__additional--fade-up g-flex-middle">
                    {/* Figure Social Icons */}
                     {/*<ul class="list-inline text-center g-flex-middle-item--bottom g-mb-20">
                      <li class="list-inline-item align-middle g-mx-7">
                        <a class="u-icon-v1 u-icon-size--md g-color-white" href="#">
                          <i class="icon-note u-line-icon-pro"></i>
                        </a>
                      </li>
                      <li class="list-inline-item align-middle g-mx-7">
                        <a class="u-icon-v1 u-icon-size--md g-color-white" href="#">
                          <i class="icon-notebook u-line-icon-pro"></i>
                        </a>
                      </li>
                      <li class="list-inline-item align-middle g-mx-7">
                        <a class="u-icon-v1 u-icon-size--md g-color-white" href="#">
                          <i class="icon-settings u-line-icon-pro"></i>
                        </a>
                      </li>
                    </ul>
                    {/* End Figure Social Icons */}
                   {/*</div>
                </figcaption>
                {/* End Figure Caption */}
  
                {/* User Info */}
                {/*
                 <span class="g-pos-abs g-top-20 g-left-0">
                    <a class="btn btn-sm u-btn-primary rounded-0" href="#">Johne Doe</a>
                    <small class="d-block g-bg-black g-color-white g-pa-5">Project Manager</small>
                  </span>
                */}
                {/* End User Info */}
              </div>
              {/* User Image */}
  
              {/* Sidebar Navigation */}
              {/* <div class="list-group list-group-border-0 g-mb-40">
                {/* Overall */}
                {/* <a href="page-profile-main-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-home g-pos-rel g-top-1 g-mr-8"></i> Overall</span>
                  <span class="u-label g-font-size-11 g-bg-gray-dark-v2 g-rounded-20 g-px-10">2</span>
                </a>
                {/* End Overall */}
  
                {/* Profile */}
              {/*   <a href="page-profile-profile-1.html" class="list-group-item justify-content-between active">
                  <span><i class="icon-cursor g-pos-rel g-top-1 g-mr-8"></i> Profile</span>
                </a>
                {/* End Profile */}
  
                {/* Users Contacts */}
               {/*  <a href="page-profile-users-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-notebook g-pos-rel g-top-1 g-mr-8"></i> Users Contacts</span>
                </a>
                {/* End Users Contacts */}
  
                {/* My Projects */}
               {/*  <a href="page-profile-projects-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-layers g-pos-rel g-top-1 g-mr-8"></i> My Projects</span>
                  <span class="u-label g-font-size-11 g-bg-primary g-rounded-20 g-px-10">9</span>
                </a>
                {/* End My Projects */}
  
                {/* Comments */}
                {/* <a href="page-profile-comments-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-bubbles g-pos-rel g-top-1 g-mr-8"></i> Comments</span>
                  <span class="u-label g-font-size-11 g-bg-pink g-rounded-20 g-px-8">24</span>
                </a>
                {/* End Comments */}
  
                {/* Reviews */}
                {/* <a href="page-profile-reviews-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-heart g-pos-rel g-top-1 g-mr-8"></i> Reviews</span>
                </a>
                {/* End Reviews */}
  
                {/* History */}
                {/* <a href="page-profile-history-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-fire g-pos-rel g-top-1 g-mr-8"></i> History</span>
                </a>
                {/* End History */}
  
                {/* Settings */}
                {/* <a href="page-profile-settings-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-settings g-pos-rel g-top-1 g-mr-8"></i> Settings</span>
                  <span class="u-label g-font-size-11 g-bg-cyan g-rounded-20 g-px-8">3</span>
                </a>
                {/* End Settings */}
              {/* </div>
              {/* End Sidebar Navigation */}
  
              {/* Project Progress */}
              <div class="card border-0 rounded-0 g-mb-50">
                {/* Panel Header */}
                {/* <div class="card-header d-flex align-items-center justify-content-between g-bg-gray-light-v5 border-0 g-mb-15">
                  <h3 class="h6 mb-0">
                      <i class="icon-layers g-pos-rel g-top-1 g-mr-5"></i> Project Progress
                    </h3>
                  <div class="dropdown g-mb-10 g-mb-0--md">
                    <span class="d-block g-color-primary--hover g-cursor-pointer g-mr-minus-5 g-pa-5" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="icon-options-vertical g-pos-rel g-top-1"></i>
                      </span>
                    <div class="dropdown-menu dropdown-menu-right rounded-0 g-mt-10">
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-layers g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> Projects
                      </a>
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-wallet g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> Wallets
                      </a>
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-fire g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> Reports
                      </a>
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-settings g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> Users Setting
                      </a>
  
                      <div class="dropdown-divider"></div>
  
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-plus g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> View More
                      </a>
                    </div>
                  </div>
                </div>
                {/* End Panel Header */}
  
                {/* Panel Body */}
                <div class="js-scrollbar card-block u-info-v1-1 g-bg-white-gradient-v1--after g-height-300 g-pa-0">
                  {/* Web Design */}
                 {/*  <div class="g-mb-20">
                    <h6 class="g-mb-10">Web Design <span class="float-right g-ml-10">68%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-cyan u-progress-bar--xs" role="progressbar" style={{width: "68%", ariaValuenow:"68", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">11% more than last week</small>
                  </div>
                  {/* End Web Design */}
  
                  {/* Unify Project */}
                 {/*  <div class="g-mb-20">
                    <h6 class="g-mb-10">Dribbble Shots <span class="float-right g-ml-10">62%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-pink u-progress-bar--xs" role="progressbar" style={{width: "62%", ariaValuenow:"62", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">20% less than last week</small>
                  </div>
                  {/* End Unify Project */}
  
                  {/* Unify Project */}
                 {/*  <div class="g-mb-20">
                    <h6 class="g-mb-10">Unify Project <span class="float-right g-ml-10">93%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-primary u-progress-bar--xs" role="progressbar" style={{width: "93%", ariaValuenow:"93", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">17% more than last week</small>
                  </div>
                  {/* End Unify Project */}
  
                  {/* WordPress Coding */}
                {/*   <div class="g-mb-20">
                    <h6 class="g-mb-10">WordPress Coding <span class="float-right g-ml-10">74%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-black u-progress-bar--xs" role="progressbar" style={{width: "74%", ariaValuenow:"74", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">35% more than last week</small>
                  </div>
                  {/* End WordPress Coding */}
  
                  {/* Pixeel Ltd */}
                 {/*  <div class="g-mb-20">
                    <h6 class="g-mb-10">Pixeel Ltd <span class="float-right g-ml-10">86%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-darkpurple u-progress-bar--xs" role="progressbar" style={{width: "86%", ariaValuenow:"86", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">42% more than last week</small>
                  </div>
                  {/* End Pixeel Ltd */}
                </div>
                {/* End Panel Body */}
              </div>
              {/* End Project Progress */}
            </div>
            {/* End Profile Sidebar */}
  
            {/* Profle Content */}
            <div class="col-lg-9">
            {/* Nav tabs */}
            <ul class="nav nav-justified u-nav-v1-1 u-nav-red g-brd-bottom--md g-brd-bottom-2 g-brd-darkred g-mb-20" role="tablist" data-target="nav-2-1-default-hor-left-underline" data-tabs-mobile-type="slide-up-down" data-btn-classes="btn btn-md btn-block rounded-0 u-btn-outline-primary g-mb-20">
              <li class="nav-item">
                <a class="nav-link g-py-10 active" data-toggle="tab" href="#nav-2-1-default-hor-left-underline--1" role="tab">Presentación</a>
              </li>
              <li class="nav-item">
                <a class="nav-link g-py-10" data-toggle="tab" href="#nav-2-1-default-hor-left-underline--2" role="tab">Metas</a>
              </li>
              <li class="nav-item">
                <a class="nav-link g-py-10" data-toggle="tab" href="#nav-2-1-default-hor-left-underline--3" role="tab"> Estadísticas</a>
              </li>
              <li class="nav-item">
                <a class="nav-link g-py-10" data-toggle="tab" href="#nav-2-1-default-hor-left-underline--4" role="tab">Informes</a>
              </li>
            </ul>
            {/* End Nav tabs */}

            {/* Tab panes */}
            <div id="nav-2-1-default-hor-left-underline" class="tab-content">
              {/* Edit Profile */}
              <div class="tab-pane fade show active" id="nav-2-1-default-hor-left-underline--1" role="tabpanel" data-parent="#nav-2-1-default-hor-left-underline">
                <h2 class="h4 g-font-weight-300">{ods} | {titulo}</h2>
                <p>Si bien se ha conseguido progresar de manera sustancial a la hora de ampliar el acceso a agua potable y saneamiento, existen miles de millones de personas (principalmente en áreas rurales) que aún carecen de estos servicios básicos. En todo el mundo, una de cada tres personas no tiene acceso a agua potable salubre, dos de cada cinco personas no disponen de una instalación básica destinada a lavarse las manos con agua y jabón, y más de 673 millones de personas aún defecan al aire libre.</p>

                <p>La pandemia de la COVID-19 ha puesto de manifiesto la importancia vital del saneamiento, la higiene y un acceso adecuado a agua limpia para prevenir y contener las enfermedades. La higiene de manos salva vidas. De acuerdo con la Organización Mundial de la Salud, el lavado de manos es una de las acciones más efectivas que se pueden llevar a cabo para reducir la propagación de patógenos y prevenir infecciones, incluido el virus de la COVID-19. Aun así, hay miles de millones de personas que carecen de acceso a agua salubre y saneamiento, y los fondos son insuficientes.</p>



                
              </div>
              {/* End Edit Profile */}

              {/* Security Settings */}
              <div class="tab-pane fade" id="nav-2-1-default-hor-left-underline--2" role="tabpanel" data-parent="#nav-2-1-default-hor-left-underline">
                <h2 class="h4 g-font-weight-300">{ods} | Retos a alcanzar</h2>
                <p class="g-mb-25">
                    <strong>6.1.</strong> De aquí a 2030, lograr el acceso universal y equitativo al agua potable a un precio asequible para todos.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>6.2.</strong> De aquí a 2030, lograr el acceso a servicios de saneamiento e higiene adecuados y equitativos para todos y poner fin a la defecación al aire libre, prestando especial atención a las necesidades de las mujeres y las niñas y las personas en situaciones de vulnerabilidad.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>6.3.</strong> De aquí a 2030, mejorar la calidad del agua reduciendo la contaminación, eliminando el vertimiento y minimizando la emisión de productos químicos y materiales peligrosos, reduciendo a la mitad el porcentaje de aguas residuales sin tratar y aumentando considerablemente el reciclado y la reutilización sin riesgos a nivel mundial.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>6.4.</strong> De aquí a 2030, aumentar considerablemente el uso eficiente de los recursos hídricos en todos los sectores y asegurar la sostenibilidad de la extracción y el abastecimiento de agua dulce para hacer frente a la escasez de agua y reducir considerablemente el número de personas que sufren falta de agua.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>6.5.</strong> De aquí a 2030, implementar la gestión integrada de los recursos hídricos a todos los niveles, incluso mediante la cooperación transfronteriza, según proceda.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>6.6.</strong> De aquí a 2020, proteger y restablecer los ecosistemas relacionados con el agua, incluidos los bosques, las montañas, los humedales, los ríos, los acuíferos y los lagos.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>6.a.</strong> De aquí a 2030, ampliar la cooperación internacional y el apoyo prestado a los países en desarrollo para la creación de capacidad en actividades y programas relativos al agua y el saneamiento, como los de captación de agua, desalinización, uso eficiente de los recursos hídricos, tratamiento de aguas residuales, reciclado y tecnologías de reutilización.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>6.b.</strong> Apoyar y fortalecer la participación de las comunidades locales en la mejora de la gestión del agua y el saneamiento.


                
                </p>

               
              </div>
              {/* End Security Settings */}

              {/* Payment Options */}
              <div class="tab-pane fade" id="nav-2-1-default-hor-left-underline--3" role="tabpanel" data-parent="#nav-2-1-default-hor-left-underline">
                <h2 class="h4 g-font-weight-300">{ods} | Grado de consecución del objetivo</h2>
                <p class="g-mb-25">Fuente: <a href="https://unstats.un.org/sdgs/report/2020/">https://unstats.un.org/sdgs/report/2020/</a>
</p>

                <Barras
                color="#27BFE5"
                data = {[88.07,87.61,72.30,72.85,50.75,55.72,67.20,60.55,67.64]}
                />
              </div>
              {/* End Payment Options */}

              {/* Notification Settings */}
              <div class="tab-pane fade" id="nav-2-1-default-hor-left-underline--4" role="tabpanel" data-parent="#nav-2-1-default-hor-left-underline">
                <h2 class="h4 g-font-weight-300">{ods} | Documentación</h2>
                <p class="g-mb-25">Below are the notifications you may manage.</p>

                  {/* Email Notification */}
                  <div class="form-group">
                    <label class="d-flex align-items-center justify-content-between">
                      <span>AGUA LIMPIA Y SANEAMIENTO: POR QUÉ ES IMPORTANTE</span>
                      <div class="u-check">
                      <a href="https://www.un.org/sustainabledevelopment/es/wp-content/uploads/sites/3/2016/10/6_Spanish_Why_it_Matters.pdf">Descargar documento</a> 
                      </div>
                    </label>
                  </div>
                  <div class="form-group">
                    <label class="d-flex align-items-center justify-content-between">
                      <span>El derecho humano al agua y el saneamiento</span>
                      <div class="u-check">
                      <a href="https://odssocialnetwork.org/documentos/N0947938 Derecho al agua 6.pdf">Descargar documento</a> 

                      </div>
                    </label>
                  </div>
                  {/* End Email Notification */}

                  <hr class="g-brd-gray-light-v4 g-my-20"/>

              </div>
              {/* End Notification Settings */}
            </div>
            {/* End Tab panes */}
          </div>
            {/* End Profle Content */}
          </div>
        </div>
      </section>
    )
}