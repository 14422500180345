import { Breadcrumb } from "../layout/Breadcrumb"
import { Terminos } from "../layout/Terminos"
import { Proceso2 } from "./Proceso2"

export const PLE = () => {
    return (
        <>
        <Breadcrumb
            info1 = "Personal "
            info2 = "Learning "
            info3 = "Environment"
            camino = "PLE"
        />
         <Terminos
          titulo="Entorno Personal de Aprendizaje"
          texto="Desde aquí puedes diseñar tu propio itinerario formativo sobre ODS, acorde a tus propios objetivos, contenidos y medios,
          así como la temporalidad con la que quieres abordarlos."
          />
        <Proceso2/>
        </>
    )
}