import Carousel from 'react-elastic-carousel'
import { Item2 } from './Item2';
import { Item1 } from './Item1';
import { Item3 } from './Item3';

export const Promo = () => {


    const imagen = {
        backgroundImage: "url(../../assets/img-temp/1920x1080/img13.jpg)"
      };

    return (
        <Carousel 
          itemsToShow={1}
          showArrows={false}
          pagination={true}
          easing="cubic-bezier(1,.15,.55,1.54)"
          tiltEasing="cubic-bezier(0.110, 1, 1.000, 0.210)"
          transitionMs={700}
        >
          <Item1/>
          <Item2/>
          <Item3/>
        </Carousel>
    )
}