import React,{ useState, useEffect} from 'react';
import { json } from "d3-fetch";
import { Categoria } from '../Noticias/Categoria';
import LoadingOverlay from 'react-loading-overlay';
import { UltimaNoticia } from '../Home/UltimaNoticia';


export const UltimasNoticias = ({categories}) => {

    console.log(categories);

    const url = "https://backend.odssocialnetwork.org//wp-json/wp/v2/posts?_embed&per_page=3&tags=105&categories=" + categories[0];

    const [ posts, setPosts] = useState([]);
    const [ categoriaswp, setCategoriaswp] = useState([]);
    const [ loading, setLoading] = useState(true);

    const terminos = ["poor","world","action"];


    useEffect(() => {
        json(`/datos/ods/categoriaswp.json`).then((data) => {
            setCategoriaswp(data);
          });
        fetch(url)
            .then(response => response.json())
            .then(posts => {
                setPosts(posts);
                setLoading(false);

    })},[]);

    return (

        <LoadingOverlay
        active={loading}
        spinner
        text='Cargando contenido...'
        >
          { loading ? "Cargando..." :
        <>
        {/* Blog Classic Blocks */}
        <section className="container g-pt-50 g-pb-10">
        <h2 className="h3 text-uppercase mb-3">Últimas Noticias</h2>

      <div className="masonry-grid row g-mb-10">

        {
            posts.map((post,index) => (
                <UltimaNoticia
                    id = {post.id}
                    title = {post.title.rendered}
                    source_url = {post._embedded["wp:featuredmedia"] ? post._embedded["wp:featuredmedia"][0].source_url : "https://loremflickr.com/745/450/" + terminos[index] }
                    date = {posts.date}
                />
            ))
        }


        </div>
        </section>
          </>
          }
          </LoadingOverlay>

        
    )
}