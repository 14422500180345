import React, { useState, useEffect} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { Breadcrumb } from '../layout/Breadcrumb'
import { Terminos } from '../layout/Terminos'
import Modal from 'react-modal';


export const Agenda = () => {

  const url = "https://backend.odssocialnetwork.org/wp-json/tribe/events/v1/events?start_date=2021-01-01&end_date=2022-12-31&per_page=50";

  const [ events, setEvents] = useState([]);
  const [ loading, setLoading] = useState(true);
  const [windowOpen, setWindowOpen] = useState(false);  
  const [eventoSeleccionado, setEventoSeleccionado] = useState({
      title: "",
      description: "",
      imagen: "",
      fecha: ""

  }); 

    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      }
    };
    
    const openModal = () => {
      setWindowOpen(true);
    }

    const closeModal = () => {
      setWindowOpen(false);
    }

  useEffect(() => {
    fetch(url)
        .then(response => response.json())
        .then(events => {
            let eventos = events.events;
            if (events.total_pages > 1) {
                for (let i = 2; i <= events.total_pages; i++) {
                    fetch(`${url}&page=${i}`)
                        .then(response => response.json())
                        .then(events => {
                            eventos = [...eventos, ...events.events];
                            setEvents(eventos);
                        });
                }
                console.log(eventos);

                setLoading(false);
            }

    })},[]);

  const handleDateClick = (e) => {
    setEventoSeleccionado({
        title: e.event.title,
        description: e.event._def.extendedProps.description,
        direccionEvento: e.event._def.extendedProps.direccionEvento,
        imagen: e.event._def.extendedProps.imagen,
        fecha: e.date
    });
    console.log(e);

    openModal();
    //alert(e.event._def.extendedProps.description);
    //console.log(e.event._def.extendedProps.description);
    return 0;
  }

  const generarEventos = () => {


    if(events != undefined ) {
    let eventos_generados = [];

    events.map(e => {
        eventos_generados.push({title: e.title, date: e.start_date, fullDay: e.all_day, color: "red", description: e.description, direccionEvento: e.website, imagen: e.image.url})        
    })

      return eventos_generados;
    }
  }

    return (
        <>
        <Breadcrumb
            info1 = "Agenda"
            info2 = ""
            camino = "agenda"
        />
        <Terminos
          titulo="Agenda"
          texto="Agenda, eventos y novedades"
        />
        <section className="g-py-50">
            
        <div className="container" style={{display: windowOpen ? 'none' : 'block'}}>

          <FullCalendar
          plugins={[ dayGridPlugin ]}
          initialView="dayGridMonth"
          weekends={false}
          locale="es"
          eventClick={e => handleDateClick(e)}
          eventTimeFormat= {{ hour: '2-digit', minute: '2-digit'}}
          buttonText={{today: "Hoy"}}
          weekends= {true}
          firstDay={1}
          events={generarEventos()}
           
          />
          </div>
        </section>

        <Modal
          isOpen={windowOpen}
          style={customStyles}
          contentLabel="Agenda ODSSocialNetwork"
        >

          <div id="modal-type-hashlink" class="js-autonomous-popup text-left g-max-width-600 g-bg-white g-overflow-y-auto g-pa-20" data-modal-type="hashlink" data-effect="fadein">
            <button type="button" class="close" onClick={closeModal}>
              <i class="hs-icon hs-icon-close"></i>
            </button>
            <h4 class="g-mb-20">{eventoSeleccionado.title}</h4>
            <h5>{eventoSeleccionado.fecha}</h5>
            <hr/>
            <img src={eventoSeleccionado.imagen} width="150px" />
            <div dangerouslySetInnerHTML={{__html: eventoSeleccionado.description}}></div>
          </div>
          <a href={eventoSeleccionado.direccionEvento} class="btn btn-md u-btn-primary g-mr-10 g-mb-15">Ir a la web</a>


        </Modal>
              
                
      </>
    )
}