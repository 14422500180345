import { Atlas } from './Atlas';
import { Barras } from './Barras';
import { Botones } from './Botones';

export const Profile2 = ({cambiarOds, ods, titulo}) => {
    return (
        <section class="g-mb-100">
        <div class="container">
          <div class="row">
            {/* Profile Sidebar */}
            <div class="col-lg-3 g-mb-50 g-mb-0--lg">
              {/* User Image */}
              <div class="u-block-hover g-pos-rel">
                <figure>
                  <img class="img-fluid w-100 u-block-hover__main--zoom-v1" src="/assets/datos/ods/ods2.jpg" alt="Image Description"/>
                </figure>
                <Atlas
                  titulo = {"SDG 2: Zero hunger"}
                  enlace = {"https://datatopics.worldbank.org/sdgatlas/goal-2-zero-hunger"}
                  subtitulo = {"Beyond hunger: ensuring food security for all"}
                  imagen = {"/assets/datos/atlas/goal-2.072d77c3.png"}
                />
                {/*<Botones cambiarOds={cambiarOds} />*/}

                {/* Figure Caption */}
                 {/*<figcaption class="u-block-hover__additional--fade g-bg-black-opacity-0_5 g-pa-30">
                  <div class="u-block-hover__additional--fade u-block-hover__additional--fade-up g-flex-middle">
                    {/* Figure Social Icons */}
                     {/*<ul class="list-inline text-center g-flex-middle-item--bottom g-mb-20">
                      <li class="list-inline-item align-middle g-mx-7">
                        <a class="u-icon-v1 u-icon-size--md g-color-white" href="#">
                          <i class="icon-note u-line-icon-pro"></i>
                        </a>
                      </li>
                      <li class="list-inline-item align-middle g-mx-7">
                        <a class="u-icon-v1 u-icon-size--md g-color-white" href="#">
                          <i class="icon-notebook u-line-icon-pro"></i>
                        </a>
                      </li>
                      <li class="list-inline-item align-middle g-mx-7">
                        <a class="u-icon-v1 u-icon-size--md g-color-white" href="#">
                          <i class="icon-settings u-line-icon-pro"></i>
                        </a>
                      </li>
                    </ul>
                    {/* End Figure Social Icons */}
                   {/*</div>
                </figcaption>
                {/* End Figure Caption */}
  
                {/* User Info */}
                {/*
                 <span class="g-pos-abs g-top-20 g-left-0">
                    <a class="btn btn-sm u-btn-primary rounded-0" href="#">Johne Doe</a>
                    <small class="d-block g-bg-black g-color-white g-pa-5">Project Manager</small>
                  </span>
                */}
                {/* End User Info */}
              </div>
              {/* User Image */}
  
              {/* Sidebar Navigation */}
              {/* <div class="list-group list-group-border-0 g-mb-40">
                {/* Overall */}
                {/* <a href="page-profile-main-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-home g-pos-rel g-top-1 g-mr-8"></i> Overall</span>
                  <span class="u-label g-font-size-11 g-bg-gray-dark-v2 g-rounded-20 g-px-10">2</span>
                </a>
                {/* End Overall */}
  
                {/* Profile */}
              {/*   <a href="page-profile-profile-1.html" class="list-group-item justify-content-between active">
                  <span><i class="icon-cursor g-pos-rel g-top-1 g-mr-8"></i> Profile</span>
                </a>
                {/* End Profile */}
  
                {/* Users Contacts */}
               {/*  <a href="page-profile-users-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-notebook g-pos-rel g-top-1 g-mr-8"></i> Users Contacts</span>
                </a>
                {/* End Users Contacts */}
  
                {/* My Projects */}
               {/*  <a href="page-profile-projects-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-layers g-pos-rel g-top-1 g-mr-8"></i> My Projects</span>
                  <span class="u-label g-font-size-11 g-bg-primary g-rounded-20 g-px-10">9</span>
                </a>
                {/* End My Projects */}
  
                {/* Comments */}
                {/* <a href="page-profile-comments-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-bubbles g-pos-rel g-top-1 g-mr-8"></i> Comments</span>
                  <span class="u-label g-font-size-11 g-bg-pink g-rounded-20 g-px-8">24</span>
                </a>
                {/* End Comments */}
  
                {/* Reviews */}
                {/* <a href="page-profile-reviews-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-heart g-pos-rel g-top-1 g-mr-8"></i> Reviews</span>
                </a>
                {/* End Reviews */}
  
                {/* History */}
                {/* <a href="page-profile-history-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-fire g-pos-rel g-top-1 g-mr-8"></i> History</span>
                </a>
                {/* End History */}
  
                {/* Settings */}
                {/* <a href="page-profile-settings-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-settings g-pos-rel g-top-1 g-mr-8"></i> Settings</span>
                  <span class="u-label g-font-size-11 g-bg-cyan g-rounded-20 g-px-8">3</span>
                </a>
                {/* End Settings */}
              {/* </div>
              {/* End Sidebar Navigation */}
  
              {/* Project Progress */}
              <div class="card border-0 rounded-0 g-mb-50">
                {/* Panel Header */}
                {/* <div class="card-header d-flex align-items-center justify-content-between g-bg-gray-light-v5 border-0 g-mb-15">
                  <h3 class="h6 mb-0">
                      <i class="icon-layers g-pos-rel g-top-1 g-mr-5"></i> Project Progress
                    </h3>
                  <div class="dropdown g-mb-10 g-mb-0--md">
                    <span class="d-block g-color-primary--hover g-cursor-pointer g-mr-minus-5 g-pa-5" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="icon-options-vertical g-pos-rel g-top-1"></i>
                      </span>
                    <div class="dropdown-menu dropdown-menu-right rounded-0 g-mt-10">
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-layers g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> Projects
                      </a>
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-wallet g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> Wallets
                      </a>
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-fire g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> Reports
                      </a>
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-settings g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> Users Setting
                      </a>
  
                      <div class="dropdown-divider"></div>
  
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-plus g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> View More
                      </a>
                    </div>
                  </div>
                </div>
                {/* End Panel Header */}
  
                {/* Panel Body */}
                <div class="js-scrollbar card-block u-info-v1-1 g-bg-white-gradient-v1--after g-height-300 g-pa-0">
                  {/* Web Design */}
                 {/*  <div class="g-mb-20">
                    <h6 class="g-mb-10">Web Design <span class="float-right g-ml-10">68%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-cyan u-progress-bar--xs" role="progressbar" style={{width: "68%", ariaValuenow:"68", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">11% more than last week</small>
                  </div>
                  {/* End Web Design */}
  
                  {/* Unify Project */}
                 {/*  <div class="g-mb-20">
                    <h6 class="g-mb-10">Dribbble Shots <span class="float-right g-ml-10">62%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-pink u-progress-bar--xs" role="progressbar" style={{width: "62%", ariaValuenow:"62", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">20% less than last week</small>
                  </div>
                  {/* End Unify Project */}
  
                  {/* Unify Project */}
                 {/*  <div class="g-mb-20">
                    <h6 class="g-mb-10">Unify Project <span class="float-right g-ml-10">93%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-primary u-progress-bar--xs" role="progressbar" style={{width: "93%", ariaValuenow:"93", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">17% more than last week</small>
                  </div>
                  {/* End Unify Project */}
  
                  {/* WordPress Coding */}
                {/*   <div class="g-mb-20">
                    <h6 class="g-mb-10">WordPress Coding <span class="float-right g-ml-10">74%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-black u-progress-bar--xs" role="progressbar" style={{width: "74%", ariaValuenow:"74", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">35% more than last week</small>
                  </div>
                  {/* End WordPress Coding */}
  
                  {/* Pixeel Ltd */}
                 {/*  <div class="g-mb-20">
                    <h6 class="g-mb-10">Pixeel Ltd <span class="float-right g-ml-10">86%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-darkpurple u-progress-bar--xs" role="progressbar" style={{width: "86%", ariaValuenow:"86", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">42% more than last week</small>
                  </div>
                  {/* End Pixeel Ltd */}
                </div>
                {/* End Panel Body */}
              </div>
              {/* End Project Progress */}
            </div>
            {/* End Profile Sidebar */}
  
            {/* Profle Content */}
            <div class="col-lg-9">
            {/* Nav tabs */}
            <ul class="nav nav-justified u-nav-v1-1 u-nav-red g-brd-bottom--md g-brd-bottom-2 g-brd-darkred g-mb-20" role="tablist" data-target="nav-2-1-default-hor-left-underline" data-tabs-mobile-type="slide-up-down" data-btn-classes="btn btn-md btn-block rounded-0 u-btn-outline-primary g-mb-20">
              <li class="nav-item">
                <a class="nav-link g-py-10 active" data-toggle="tab" href="#nav-2-1-default-hor-left-underline--1" role="tab">Presentación</a>
              </li>
              <li class="nav-item">
                <a class="nav-link g-py-10" data-toggle="tab" href="#nav-2-1-default-hor-left-underline--2" role="tab">Metas</a>
              </li>
              <li class="nav-item">
                <a class="nav-link g-py-10" data-toggle="tab" href="#nav-2-1-default-hor-left-underline--3" role="tab"> Estadísticas</a>
              </li>
              <li class="nav-item">
                <a class="nav-link g-py-10" data-toggle="tab" href="#nav-2-1-default-hor-left-underline--4" role="tab">Informes</a>
              </li>
            </ul>
            {/* End Nav tabs */}

            {/* Tab panes */}
            <div id="nav-2-1-default-hor-left-underline" class="tab-content">
              {/* Edit Profile */}
              <div class="tab-pane fade show active" id="nav-2-1-default-hor-left-underline--1" role="tabpanel" data-parent="#nav-2-1-default-hor-left-underline">
                <h2 class="h4 g-font-weight-300">{ods} | {titulo}</h2>
                <p>Poner fin al hambre.</p>

                <p>Tras décadas de una disminución constante, el número de personas que padecen hambre (medido por la prevalencia de desnutrición) comenzó a aumentar lentamente de nuevo en 2015. Las estimaciones actuales indican que cerca de 690 millones de personas en el mundo padecen hambre, es decir, el 8,9 por ciento de la población mundial, lo que supone un aumento de unos 10 millones de personas en un año y de unos 60 millones en cinco años.</p>

                <p>El mundo no está bien encaminado para alcanzar el objetivo de hambre cero para 2030. Si continúan las tendencias recientes, el número de personas afectadas por el hambre superará los 840 millones de personas para 2030.</p>

                <p>Según el Programa Mundial de Alimentos, alrededor de 135 millones de personas padecen hambre severa Disponible en inglés, debido principalmente a los conflictos causados por los seres humanos, el cambio climático y las recesiones económicas. La pandemia de COVID-19 podría duplicar ahora esa cifra y sumar unos 130 millones de personas más que estarían en riesgo de padecer hambre severa a finales de 2020.</p>

                
              </div>
              {/* End Edit Profile */}

              {/* Security Settings */}
              <div class="tab-pane fade" id="nav-2-1-default-hor-left-underline--2" role="tabpanel" data-parent="#nav-2-1-default-hor-left-underline">
                <h2 class="h4 g-font-weight-300">{ods} | Retos a alcanzar</h2>
                <p class="g-mb-25">
                    <strong>2.1.</strong> Para 2030, poner fin al hambre y asegurar el acceso de todas las personas, en particular los pobres y las personas en situaciones vulnerables, incluidos los lactantes, a una alimentación sana, nutritiva y suficiente durante todo el año.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>2.2.</strong> Para 2030, poner fin a todas las formas de malnutrición, incluso logrando, a más tardar en 2025, las metas convenidas internacionalmente sobre el retraso del crecimiento y la emaciación de los niños menores de 5 años, y abordar las necesidades de nutrición de las adolescentes, las mujeres embarazadas y lactantes y las personas de edad.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>2.3.</strong> Para 2030, duplicar la productividad agrícola y los ingresos de los productores de alimentos en pequeña escala, en particular las mujeres, los pueblos indígenas, los agricultores familiares, los pastores y los pescadores, entre otras cosas mediante un acceso seguro y equitativo a las tierras, a otros recursos de producción e insumos, conocimientos, servicios financieros, mercados y oportunidades para la generación de valor añadido y empleos no agrícolas.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>2.4.</strong> Para 2030, asegurar la sostenibilidad de los sistemas de producción de alimentos y aplicar prácticas agrícolas resilientes que aumenten la productividad y la producción, contribuyan al mantenimiento de los ecosistemas, fortalezcan la capacidad de adaptación al cambio climático, los fenómenos meteorológicos extremos, las sequías, las inundaciones y otros desastres, y mejoren progresivamente la calidad del suelo y la tierra.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>2.5.</strong> Para 2020, mantener la diversidad genética de las semillas, las plantas cultivadas y los animales de granja y domesticados y sus especies silvestres conexas, entre otras cosas mediante una buena gestión y diversificación de los bancos de semillas y plantas a nivel nacional, regional e internacional, y promover el acceso a los beneficios que se deriven de la utilización de los recursos genéticos y los conocimientos tradicionales y su distribución justa y equitativa, como se ha convenido internacionalmente.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>2.a.</strong>   Aumentar las inversiones, incluso mediante una mayor cooperación internacional, en la infraestructura rural, la investigación agrícola y los servicios de extensión, el desarrollo tecnológico y los bancos de genes de plantas y ganado a fin de mejorar la capacidad de producción agrícola en los países en desarrollo, en particular en los países menos adelantados.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>2.b.</strong> Corregir y prevenir las restricciones y distorsiones comerciales en los mercados agropecuarios mundiales, entre otras cosas mediante la eliminación paralela de todas las formas de subvenciones a las exportaciones agrícolas y todas las medidas de exportación con efectos equivalentes, de conformidad con el mandato de la Ronda de Doha para el Desarrollo.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>2.c.</strong> Adoptar medidas para asegurar el buen funcionamiento de los mercados de productos básicos alimentarios y sus derivados y facilitar el acceso oportuno a información sobre los mercados, en particular sobre las reservas de alimentos, a fin de ayudar a limitar la extrema volatilidad de los precios de los alimentos.


                
                </p>

               
              </div>
              {/* End Security Settings */}

              {/* Payment Options */}
              <div class="tab-pane fade" id="nav-2-1-default-hor-left-underline--3" role="tabpanel" data-parent="#nav-2-1-default-hor-left-underline">
                <h2 class="h4 g-font-weight-300">{ods} | Grado de consecución del objetivo</h2>
                <p class="g-mb-25">Fuente: <a href="https://unstats.un.org/sdgs/report/2020/">https://unstats.un.org/sdgs/report/2020/</a>
</p>

               
                <Barras
                color="#DEA739"
                data = {[56.17,63.05,55.39,53.30,46.64,50.25,53.50,44.58,53.56]}
                />
              </div>
              {/* End Payment Options */}

              {/* Notification Settings */}
              <div class="tab-pane fade" id="nav-2-1-default-hor-left-underline--4" role="tabpanel" data-parent="#nav-2-1-default-hor-left-underline">
                <h2 class="h4 g-font-weight-300">{ods} | Documentación</h2>
                <p class="g-mb-25">Below are the notifications you may manage.</p>

                  {/* Email Notification */}
                  <div class="form-group">
                    <label class="d-flex align-items-center justify-content-between">
                      <span>HAMBRE CERO: POR QUÉ ES IMPORTANTE</span>
                      <div class="u-check">
                      <a href="https://www.un.org/sustainabledevelopment/es/wp-content/uploads/sites/3/2016/10/2_Spanish_Why_it_Matters.pdf">Descargar documento</a> 
                      </div>
                    </label>
                  </div>
                  <div class="form-group">
                    <label class="d-flex align-items-center justify-content-between">
                      <span>RES AGNU 2016 Declaración universal sobre la erradicación del hambre y la malnutrición</span>
                      <div class="u-check">
                      <a href="https://odssocialnetwork.org/documentos/RES AGNU 2016 Declaración universal sobre la erradicación del hambre y la malnutrición 2.pdf">Descargar documento</a> 

                      </div>
                    </label>
                  </div>
                  {/* End Email Notification */}

                  <hr class="g-brd-gray-light-v4 g-my-20"/>

              </div>
              {/* End Notification Settings */}
            </div>
            {/* End Tab panes */}
          </div>
            {/* End Profle Content */}
          </div>
        </div>
      </section>
    )
}