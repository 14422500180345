import React, {useState, useEffect} from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Item } from './Item';


export const Portfolio = () => {

    const url = "https://backend.odssocialnetwork.org//wp-json/wp/v2/posts?_embed&tags=145&per_page=99";

    const [ recursos, setRecursos] = useState([]);
    const [ recursosSeleccionados, setRecursosSeleccionados ] = useState([]);
    const [ categorias, setCategorias ] = useState([]);
    const [ loading, setLoading] = useState(true);


    useEffect(() => {

        const cargarDatos = async () => {
            let cat = ["Todos"];
            await fetch(url)
            .then(response => response.json())
            .then(async recursos => {
                setRecursos(recursos);
                setRecursosSeleccionados(recursos);
                await recursos.map( recurso => {
                    cat.push(recurso._embedded["wp:term"][0][0].name);
                    console.log(cat)
                });
                let dup = [...new Set(cat)];
                setCategorias(dup);
                setLoading(false);
        })

        }

        cargarDatos();

        },[]);

        const handleClick = (e) => {
            const res = e.target.innerHTML;
            if (res == "Todos") {
                setRecursosSeleccionados(recursos);
            } else {

            const seleccion = recursos.filter( recurso => {
                if(recurso._embedded["wp:term"][0][0].name == res ) {
                    return recurso;
                }
            });
            setRecursosSeleccionados(seleccion);
        }
        }

    return (

        <LoadingOverlay
        active={loading}
        spinner
        text='Cargando contenido...'
        >

        <ul id="filterControls" class="d-block list-inline text-center g-mb-50">
        {/* cbp-filter-item-active */}
          {
              categorias.map(categoria => (
                <li class="list-inline-item cbp-filter-item g-brd-around g-brd-gray-light-v4 g-brd-primary--active g-color-gray-dark-v4 g-color-primary--hover g-color-primary--active g-font-size-12 rounded g-transition-0_3 g-px-20 g-py-7 mb-2" 
                name={categoria}
                role="button"
                onClick={handleClick}
                >
                {categoria}
              </li> 
              ))
          }
        </ul>

        <section className="g-pl-100">


        {
            recursosSeleccionados.map( recurso => (
                <Item recurso={recurso}/>
            ))
        }

        </section>

</LoadingOverlay>
    
    )
}
