import { Atlas } from './Atlas';
import { Barras } from './Barras';
import { Botones } from './Botones';

export const Profile11 = ({cambiarOds, ods, titulo}) => {
    return (
        <section class="g-mb-100">
        <div class="container">
          <div class="row">
            {/* Profile Sidebar */}
            <div class="col-lg-3 g-mb-50 g-mb-0--lg">
              {/* User Image */}
              <div class="u-block-hover g-pos-rel">
                <figure>
                  <img class="img-fluid w-100 u-block-hover__main--zoom-v1" src="/assets/datos/ods/ods11.jpg" alt="Image Description"/>
                </figure>
                {/*<Botones cambiarOds={cambiarOds} />*/}
                <Atlas
                  titulo = {"SDG 11: Sustainable cities and communities"}
                  enlace = {"https://datatopics.worldbank.org/sdgatlas/goal-11-sustainable-cities-and-communities"}
                  subtitulo = {"Polluted air plagues cities worldwide"}
                  imagen = {"/assets/datos/atlas/goal-11.5d3d902b.png"}
                />
                {/* Figure Caption */}
                 {/*<figcaption class="u-block-hover__additional--fade g-bg-black-opacity-0_5 g-pa-30">
                  <div class="u-block-hover__additional--fade u-block-hover__additional--fade-up g-flex-middle">
                    {/* Figure Social Icons */}
                     {/*<ul class="list-inline text-center g-flex-middle-item--bottom g-mb-20">
                      <li class="list-inline-item align-middle g-mx-7">
                        <a class="u-icon-v1 u-icon-size--md g-color-white" href="#">
                          <i class="icon-note u-line-icon-pro"></i>
                        </a>
                      </li>
                      <li class="list-inline-item align-middle g-mx-7">
                        <a class="u-icon-v1 u-icon-size--md g-color-white" href="#">
                          <i class="icon-notebook u-line-icon-pro"></i>
                        </a>
                      </li>
                      <li class="list-inline-item align-middle g-mx-7">
                        <a class="u-icon-v1 u-icon-size--md g-color-white" href="#">
                          <i class="icon-settings u-line-icon-pro"></i>
                        </a>
                      </li>
                    </ul>
                    {/* End Figure Social Icons */}
                   {/*</div>
                </figcaption>
                {/* End Figure Caption */}
  
                {/* User Info */}
                {/*
                 <span class="g-pos-abs g-top-20 g-left-0">
                    <a class="btn btn-sm u-btn-primary rounded-0" href="#">Johne Doe</a>
                    <small class="d-block g-bg-black g-color-white g-pa-5">Project Manager</small>
                  </span>
                */}
                {/* End User Info */}
              </div>
              {/* User Image */}
  
              {/* Sidebar Navigation */}
              {/* <div class="list-group list-group-border-0 g-mb-40">
                {/* Overall */}
                {/* <a href="page-profile-main-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-home g-pos-rel g-top-1 g-mr-8"></i> Overall</span>
                  <span class="u-label g-font-size-11 g-bg-gray-dark-v2 g-rounded-20 g-px-10">2</span>
                </a>
                {/* End Overall */}
  
                {/* Profile */}
              {/*   <a href="page-profile-profile-1.html" class="list-group-item justify-content-between active">
                  <span><i class="icon-cursor g-pos-rel g-top-1 g-mr-8"></i> Profile</span>
                </a>
                {/* End Profile */}
  
                {/* Users Contacts */}
               {/*  <a href="page-profile-users-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-notebook g-pos-rel g-top-1 g-mr-8"></i> Users Contacts</span>
                </a>
                {/* End Users Contacts */}
  
                {/* My Projects */}
               {/*  <a href="page-profile-projects-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-layers g-pos-rel g-top-1 g-mr-8"></i> My Projects</span>
                  <span class="u-label g-font-size-11 g-bg-primary g-rounded-20 g-px-10">9</span>
                </a>
                {/* End My Projects */}
  
                {/* Comments */}
                {/* <a href="page-profile-comments-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-bubbles g-pos-rel g-top-1 g-mr-8"></i> Comments</span>
                  <span class="u-label g-font-size-11 g-bg-pink g-rounded-20 g-px-8">24</span>
                </a>
                {/* End Comments */}
  
                {/* Reviews */}
                {/* <a href="page-profile-reviews-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-heart g-pos-rel g-top-1 g-mr-8"></i> Reviews</span>
                </a>
                {/* End Reviews */}
  
                {/* History */}
                {/* <a href="page-profile-history-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-fire g-pos-rel g-top-1 g-mr-8"></i> History</span>
                </a>
                {/* End History */}
  
                {/* Settings */}
                {/* <a href="page-profile-settings-1.html" class="list-group-item list-group-item-action justify-content-between">
                  <span><i class="icon-settings g-pos-rel g-top-1 g-mr-8"></i> Settings</span>
                  <span class="u-label g-font-size-11 g-bg-cyan g-rounded-20 g-px-8">3</span>
                </a>
                {/* End Settings */}
              {/* </div>
              {/* End Sidebar Navigation */}
  
              {/* Project Progress */}
              <div class="card border-0 rounded-0 g-mb-50">
                {/* Panel Header */}
                {/* <div class="card-header d-flex align-items-center justify-content-between g-bg-gray-light-v5 border-0 g-mb-15">
                  <h3 class="h6 mb-0">
                      <i class="icon-layers g-pos-rel g-top-1 g-mr-5"></i> Project Progress
                    </h3>
                  <div class="dropdown g-mb-10 g-mb-0--md">
                    <span class="d-block g-color-primary--hover g-cursor-pointer g-mr-minus-5 g-pa-5" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="icon-options-vertical g-pos-rel g-top-1"></i>
                      </span>
                    <div class="dropdown-menu dropdown-menu-right rounded-0 g-mt-10">
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-layers g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> Projects
                      </a>
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-wallet g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> Wallets
                      </a>
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-fire g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> Reports
                      </a>
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-settings g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> Users Setting
                      </a>
  
                      <div class="dropdown-divider"></div>
  
                      <a class="dropdown-item g-px-10" href="#">
                        <i class="icon-plus g-font-size-12 g-color-gray-dark-v5 g-mr-5"></i> View More
                      </a>
                    </div>
                  </div>
                </div>
                {/* End Panel Header */}
  
                {/* Panel Body */}
                <div class="js-scrollbar card-block u-info-v1-1 g-bg-white-gradient-v1--after g-height-300 g-pa-0">
                  {/* Web Design */}
                 {/*  <div class="g-mb-20">
                    <h6 class="g-mb-10">Web Design <span class="float-right g-ml-10">68%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-cyan u-progress-bar--xs" role="progressbar" style={{width: "68%", ariaValuenow:"68", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">11% more than last week</small>
                  </div>
                  {/* End Web Design */}
  
                  {/* Unify Project */}
                 {/*  <div class="g-mb-20">
                    <h6 class="g-mb-10">Dribbble Shots <span class="float-right g-ml-10">62%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-pink u-progress-bar--xs" role="progressbar" style={{width: "62%", ariaValuenow:"62", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">20% less than last week</small>
                  </div>
                  {/* End Unify Project */}
  
                  {/* Unify Project */}
                 {/*  <div class="g-mb-20">
                    <h6 class="g-mb-10">Unify Project <span class="float-right g-ml-10">93%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-primary u-progress-bar--xs" role="progressbar" style={{width: "93%", ariaValuenow:"93", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">17% more than last week</small>
                  </div>
                  {/* End Unify Project */}
  
                  {/* WordPress Coding */}
                {/*   <div class="g-mb-20">
                    <h6 class="g-mb-10">WordPress Coding <span class="float-right g-ml-10">74%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-black u-progress-bar--xs" role="progressbar" style={{width: "74%", ariaValuenow:"74", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">35% more than last week</small>
                  </div>
                  {/* End WordPress Coding */}
  
                  {/* Pixeel Ltd */}
                 {/*  <div class="g-mb-20">
                    <h6 class="g-mb-10">Pixeel Ltd <span class="float-right g-ml-10">86%</span></h6>
                    <div class="js-hr-progress-bar progress g-bg-black-opacity-0_1 rounded-0 g-mb-5">
                      <div class="js-hr-progress-bar-indicator progress-bar g-bg-darkpurple u-progress-bar--xs" role="progressbar" style={{width: "86%", ariaValuenow:"86", ariaValuemin:"0", ariaValuemax:"100"}}></div>
                    </div>
                    <small class="g-font-size-12">42% more than last week</small>
                  </div>
                  {/* End Pixeel Ltd */}
                </div>
                {/* End Panel Body */}
              </div>
              {/* End Project Progress */}
            </div>
            {/* End Profile Sidebar */}
  
            {/* Profle Content */}
            <div class="col-lg-9">
            {/* Nav tabs */}
            <ul class="nav nav-justified u-nav-v1-1 u-nav-red g-brd-bottom--md g-brd-bottom-2 g-brd-darkred g-mb-20" role="tablist" data-target="nav-2-1-default-hor-left-underline" data-tabs-mobile-type="slide-up-down" data-btn-classes="btn btn-md btn-block rounded-0 u-btn-outline-primary g-mb-20">
              <li class="nav-item">
                <a class="nav-link g-py-10 active" data-toggle="tab" href="#nav-2-1-default-hor-left-underline--1" role="tab">Presentación</a>
              </li>
              <li class="nav-item">
                <a class="nav-link g-py-10" data-toggle="tab" href="#nav-2-1-default-hor-left-underline--2" role="tab">Metas</a>
              </li>
              <li class="nav-item">
                <a class="nav-link g-py-10" data-toggle="tab" href="#nav-2-1-default-hor-left-underline--3" role="tab"> Estadísticas</a>
              </li>
              <li class="nav-item">
                <a class="nav-link g-py-10" data-toggle="tab" href="#nav-2-1-default-hor-left-underline--4" role="tab">Informes</a>
              </li>
            </ul>
            {/* End Nav tabs */}

            {/* Tab panes */}
            <div id="nav-2-1-default-hor-left-underline" class="tab-content">
              {/* Edit Profile */}
              <div class="tab-pane fade show active" id="nav-2-1-default-hor-left-underline--1" role="tabpanel" data-parent="#nav-2-1-default-hor-left-underline">
                <h2 class="h4 g-font-weight-300">{ods} | {titulo}</h2>
                <p>El mundo cada vez está más urbanizado. Desde 2007, más de la mitad de la población mundial ha estado viviendo en ciudades, y se espera que dicha cantidad aumente hasta el 60 % para 2030.</p>

                <p>Las ciudades y las áreas metropolitanas son centros neurálgicos del crecimiento económico, ya que contribuyen al 60 % aproximadamente del PIB mundial. Sin embargo, también representan alrededor del 70 % de las emisiones de carbono mundiales y más del 60 % del uso de recursos.</p>

                <p>La rápida urbanización está dando como resultado un número creciente de habitantes en barrios pobres, infraestructuras y servicios inadecuados y sobrecargados (como la recogida de residuos y los sistemas de agua y saneamiento, carreteras y transporte), lo cual está empeorando la contaminación del aire y el crecimiento urbano incontrolado.</p>

                <p>El impacto de la COVID-19 será más devastador en las zonas urbanas pobres y densamente pobladas, especialmente para el mil millón de personas que vive en asentamientos informales y en barrios marginales en todo el mundo, donde el hacinamiento también dificulta cumplir con las medidas recomendadas, como el distanciamiento social y el autoaislamiento.</p>

                <p>El organismo de las Naciones Unidas para los alimentos, la FAO, advirtió de que el hambre y las muertes podrían aumentar de manera significativa en las zonas urbanas que no cuentan con medidas para garantizar que los residentes pobres y vulnerables tengan acceso a alimentos.</p>

                
              </div>
              {/* End Edit Profile */}

              {/* Security Settings */}
              <div class="tab-pane fade" id="nav-2-1-default-hor-left-underline--2" role="tabpanel" data-parent="#nav-2-1-default-hor-left-underline">
                <h2 class="h4 g-font-weight-300">{ods} | Retos a alcanzar</h2>
                <p class="g-mb-25">
                    <strong>11.1.</strong> De aquí a 2030, asegurar el acceso de todas las personas a viviendas y servicios básicos adecuados, seguros y asequibles y mejorar los barrios marginales.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>11.2.</strong> De aquí a 2030, proporcionar acceso a sistemas de transporte seguros, asequibles, accesibles y sostenibles para todos y mejorar la seguridad vial, en particular mediante la ampliación del transporte público, prestando especial atención a las necesidades de las personas en situación de vulnerabilidad, las mujeres, los niños, las personas con discapacidad y las personas de edad.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>11.3.</strong> De aquí a 2030, aumentar la urbanización inclusiva y sostenible y la capacidad para la planificación y la gestión participativas, integradas y sostenibles de los asentamientos humanos en todos los países.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>11.4.</strong> Redoblar los esfuerzos para proteger y salvaguardar el patrimonio cultural y natural del mundo.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>11.5.</strong> De aquí a 2030, reducir significativamente el número de muertes causadas por los desastres, incluidos los relacionados con el agua, y de personas afectadas por ellos, y reducir considerablemente las pérdidas económicas directas provocadas por los desastres en comparación con el producto interno bruto mundial, haciendo especial hincapié en la protección de los pobres y las personas en situaciones de vulnerabilidad.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>11.6.</strong> De aquí a 2030, reducir el impacto ambiental negativo per capita de las ciudades, incluso prestando especial atención a la calidad del aire y la gestión de los desechos municipales y de otro tipo.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>11.7.</strong> De aquí a 2030, proporcionar acceso universal a zonas verdes y espacios públicos seguros, inclusivos y accesibles, en particular para las mujeres y los niños, las personas de edad y las personas con discapacidad.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>11.a.</strong> Apoyar los vínculos económicos, sociales y ambientales positivos entre las zonas urbanas, periurbanas y rurales fortaleciendo la planificación del desarrollo nacional y regional.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>11.b.</strong> De aquí a 2020, aumentar considerablemente el número de ciudades y asentamientos humanos que adoptan e implementan políticas y planes integrados para promover la inclusión, el uso eficiente de los recursos, la mitigación del cambio climático y la adaptación a él y la resiliencia ante los desastres, y desarrollar y poner en práctica, en consonancia con el Marco de Sendai para la Reducción del Riesgo de Desastres 2015-2030, la gestión integral de los riesgos de desastre a todos los niveles.
                    <hr class="g-brd-gray-light-v4 g-my-25"/>
                    <strong>11.c.</strong> Proporcionar apoyo a los países menos adelantados, incluso mediante asistencia financiera y técnica, para que puedan construir edificios sostenibles y resilientes utilizando materiales locales.


                
                </p>

               
              </div>
              {/* End Security Settings */}

              {/* Payment Options */}
              <div class="tab-pane fade" id="nav-2-1-default-hor-left-underline--3" role="tabpanel" data-parent="#nav-2-1-default-hor-left-underline">
                <h2 class="h4 g-font-weight-300">{ods} | Grado de consecución del objetivo</h2>
                <p class="g-mb-25">Fuente: <a href="https://unstats.un.org/sdgs/report/2020/">https://unstats.un.org/sdgs/report/2020/</a>
</p>

                <Barras
                color="#F89D29"
                data = {[89.05,84.88,76.62,77.98,58.92,60.02,71.03,83.24,71.81]}
                />
              </div>
              {/* End Payment Options */}

              {/* Notification Settings */}
              <div class="tab-pane fade" id="nav-2-1-default-hor-left-underline--4" role="tabpanel" data-parent="#nav-2-1-default-hor-left-underline">
                <h2 class="h4 g-font-weight-300">{ods} | Documentación</h2>
                <p class="g-mb-25">Below are the notifications you may manage.</p>

                  {/* Email Notification */}
                  <div class="form-group">
                    <label class="d-flex align-items-center justify-content-between">
                      <span>CIUDADES Y COMUNIDADES SOSTENIBLES: POR QUÉ ES IMPORTANTE</span>
                      <div class="u-check">
                      <a href="https://www.un.org/sustainabledevelopment/es/wp-content/uploads/sites/3/2016/10/11_Spanish_Why_it_Matters.pdf">Descargar documento</a> 
                    </div>
                    </label>
                  </div>
                  <div class="form-group">
                    <label class="d-flex align-items-center justify-content-between">
                      <span>Informe de la Conferencia de las Naciones Unidas sobre la Vivienda y el Desarrollo Urbano Sostenible (Hábitat III)</span>
                      <div class="u-check">
                      <a href="https://odssocialnetwork.org/documentos/A_CONF.226_12_S Habitat III 11.pdf">Descargar documento</a> 

                      </div>
                    </label>
                  </div>
                  {/* End Email Notification */}

                  <hr class="g-brd-gray-light-v4 g-my-20"/>

              </div>
              {/* End Notification Settings */}
            </div>
            {/* End Tab panes */}
          </div>
            {/* End Profle Content */}
          </div>
        </div>
      </section>
    )
}