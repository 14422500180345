import React,{useState,useEffect} from 'react';
import Moment from 'react-moment';
import { Categoria } from './Categoria';
import {Helmet} from "react-helmet";



export const NoticiaVista = ( {post, categoriaswp} ) => {

    return (
    <>
      <Helmet>
        <title>{post[0].title.rendered}</title>
        <meta property="og:title" content={post[0].title.rendered}></meta>
        <meta property="og:image" content={post[0]._embedded["wp:featuredmedia"] ? post[0]._embedded["wp:featuredmedia"][0].source_url : "https://loremflickr.com/745/450/poor" }></meta>
      </Helmet>
    {/* News Content */}
      <section className="g-pt-50 g-pb-100">
      <div className="container">
        <div className="row">
          {/* Articles Content */}
          <div className="col-lg-9 g-mb-50 g-mb-0--lg">
            <article className="g-mb-60">
              <header className="g-mb-30">
                <h2 className="h1 g-mb-15">
                <div dangerouslySetInnerHTML={{__html: post[0].title.rendered}}></div></h2>

                <ul className="list-inline d-sm-flex g-color-gray-dark-v4 mb-0">
                  {/* <li className="list-inline-item">
                    <a className="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" href="#">Mike Coolman</a>
    </li> 
                  <li className="list-inline-item g-mx-10">/</li>*/}
                  <li className="list-inline-item">
                  <Moment locale="es">{post[0].date}</Moment>
                  </li>
                  {/* <li className="list-inline-item g-mx-10">/</li>
                  <li className="list-inline-item g-mr-10">
                    <a className="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" href="#">
                      <i className="icon-finance-206 u-line-icon-pro align-middle g-pos-rel g-top-1 mr-1"></i> 24
                    </a>
                  </li>
                  <li className="list-inline-item ml-auto">
                    <i className="icon-eye u-line-icon-pro align-middle mr-1"></i> Views 3821
                  </li> */}
                </ul>

                <hr className="g-brd-gray-light-v4 g-my-15"/>

                <ul className="list-inline text-uppercase mb-0">
                  <li className="list-inline-item g-mr-10">
                    <strong className="align-middle g-font-size-24">{ post[0].id }</strong>
                  </li>
                  <li className="list-inline-item g-mr-10">
                    <span className="g-color-gray-dark-v5">|</span>
                  </li>
                  <li className="list-inline-item g-mr-10">
                  <a className="btn u-btn-facebook g-font-size-12 rounded g-px-20--sm g-py-10" href={"https://www.facebook.com/sharer/sharer.php?u=https://odssocialnetwork.org/noticias_redes_sociales.php%3Fid=" + post[0].id + "%26title=" + post[0].title.rendered + "%26image=" + (post[0]._embedded["wp:featuredmedia"] ? post[0]._embedded["wp:featuredmedia"][0].source_url : "https://loremflickr.com/745/450/poor")}>
                      <i className="fa fa-facebook g-mr-5--sm"></i> <span className="g-hidden-xs-down">Facebook</span>
                    </a>
                  </li>
                  <li className="list-inline-item g-mr-10">
                  <a className="btn u-btn-twitter g-font-size-12 rounded g-px-20--sm g-py-10" href={"https://twitter.com/intent/tweet?text=" + post[0].title.rendered + "&url=https://odssocialnetwork.org/noticia/" + post[0].id}>
                      <i className="fa fa-twitter g-mr-5--sm"></i> <span className="g-hidden-xs-down">Twitter</span>
                    </a>
                  </li>
                  {/* <li className="list-inline-item">
                    <a className="btn u-btn-lightred g-font-size-12 rounded g-py-10" href="#">
                <i className="fa fa-pinterest"></i> 
                    </a>
                </li> */}
                </ul>
              </header>

              <div className="g-font-size-16 g-line-height-1_8 g-mb-30">
                <figure className="u-shadow-v25 g-mb-30">
                  <img className="img-fluid w-100" src={post[0]._embedded["wp:featuredmedia"] ? post[0]._embedded["wp:featuredmedia"][0].source_url : "https://loremflickr.com/745/450/poor" } alt="Image Description"/>
                 

                </figure>

                
                <div dangerouslySetInnerHTML={ {__html: post[0].content.rendered} } />
              </div>

              {/* Sources & Tags */}
              {/* <div className="g-mb-30">
                <h6 className="g-color-gray-dark-v1 g-mb-15">
                  <strong className="g-mr-5">Source:</strong> <a className="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" href="#">The Next Web (TNW)</a>
                </h6>
                <h6 className="g-color-gray-dark-v1">
                  <strong className="g-mr-5">Tags:</strong>
                  <a className="u-tags-v1 g-font-size-12 g-brd-around g-brd-gray-light-v4 g-bg-primary--hover g-brd-primary--hover g-color-black-opacity-0_8 g-color-white--hover rounded g-py-6 g-px-15 g-mr-5" href="#">Business</a>
                  <a className="u-tags-v1 g-font-size-12 g-brd-around g-brd-gray-light-v4 g-bg-primary--hover g-brd-primary--hover g-color-black-opacity-0_8 g-color-white--hover rounded g-py-6 g-px-15 g-mr-5" href="#">SaaS</a>
                  <a className="u-tags-v1 g-font-size-12 g-brd-around g-brd-gray-light-v4 g-bg-primary--hover g-brd-primary--hover g-color-black-opacity-0_8 g-color-white--hover rounded g-py-6 g-px-15 g-mr-5" href="#">Web Design</a>
                  <a className="u-tags-v1 g-font-size-12 g-brd-around g-brd-gray-light-v4 g-bg-primary--hover g-brd-primary--hover g-color-black-opacity-0_8 g-color-white--hover rounded g-py-6 g-px-15 g-mr-5" href="#">IT</a>
                </h6>
              </div> */}
              {/* End Sources & Tags */}

              <hr className="g-brd-gray-light-v4"/>

              {/* Social Shares */}
              <div className="g-mb-30">
                <ul className="list-inline text-uppercase mb-0">
                  <li className="list-inline-item g-mr-10">
                    <strong className="align-middle g-font-size-24">{ post[0].id }</strong>
                  </li>
                  <li className="list-inline-item g-mr-10">
                    <span className="g-color-gray-dark-v5">|</span>
                  </li>
                  <li className="list-inline-item g-mr-10">
                  <a className="btn u-btn-facebook g-font-size-12 rounded g-px-20--sm g-py-10" href={"https://www.facebook.com/sharer/sharer.php?u=https://odssocialnetwork.org/noticias_redes_sociales.php%3Fid=" + post[0].id + "%26title=" + post[0].title.rendered + "%26image=" + (post[0]._embedded["wp:featuredmedia"] ? post[0]._embedded["wp:featuredmedia"][0].source_url : "https://loremflickr.com/745/450/poor")}>
                      <i className="fa fa-facebook g-mr-5--sm"></i> <span className="g-hidden-xs-down">Facebook</span>
                    </a>
                  </li>
                  <li className="list-inline-item g-mr-10">
                    <a className="btn u-btn-twitter g-font-size-12 rounded g-px-20--sm g-py-10" href={"https://twitter.com/intent/tweet?text=" + post[0].title.rendered + "&url=https://odssocialnetwork.org/noticia/" + post[0].id}>
                      <i className="fa fa-twitter g-mr-5--sm"></i> <span className="g-hidden-xs-down">Twitter</span>
                    </a>
                  </li>
                  {/* <li className="list-inline-item">
                    <a className="btn u-btn-lightred g-font-size-12 rounded g-py-10" href="#">
                      <i className="fa fa-pinterest"></i>
                    </a>
            </li> */}
                </ul>
              </div>
              {/* End Social Shares */}

            </article>

            <div id="stickyblock-end"></div>
          </div>
          {/* End Articles Content */}

          {/* Sidebar */}
          <div className="col-lg-3">

            {/* Subscribe */}
            <div className="u-shadow-v25 u-bg-overlay g-bg-img-hero g-bg-white-gradient-opacity-v2--after g-py-40 g-px-20 g-mb-50" >
              <div className="u-bg-overlay__inner text-center">
                <div className="g-mb-40">
                  <h2 className="g-color-white">Vancouver, BC</h2>
                  <p className="g-color-white-opacity-0_8">Unit 25 Suite 3, 925 Prospect PI,<br/>Beach Resort, 23001</p>
                </div>

                <div className="g-mb-30">
                  <h3 className="d-inline-block g-bg-primary-opacity-0_6 g-color-white g-font-weight-600 g-font-size-12 text-uppercase g-py-5 g-px-10">Phone number</h3>
                  <span className="d-block g-color-white g-font-size-18">+01 (0) 333 444 55</span>
                </div>

                <div className="input-group rounded">
                  <input className="form-control g-brd-none g-px-13" type="email" placeholder="Your Email"/>
                  <div className="input-group-append">
                    <button className="btn u-btn-primary text-uppercase g-px-20 g-py-14" type="submit">
                      <i className="icon-envelope g-pos-rel g-top-1"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* End Subscribe */}
            {/* Social Links */}
            <div className="g-mb-50">
              <div className="u-heading-v3-1 g-mb-30">
                <h2 className="h5 u-heading-v3__title g-color-gray-dark-v1 text-uppercase g-brd-primary">ODS asociados</h2>
              </div>

              <ul className="list-unstyled mb-0">
                    {
                        post[0].categories.map( cat => (
                            <Categoria
                            categoriaswp = {categoriaswp}
                            cat = {cat}
                            imagen = {true}
                            />
                        ))
                    }
                
              </ul>
            </div>
            {/* End Social Links */}


            <div id="stickyblock-start" className="js-sticky-block g-sticky-block--lg g-pt-20" data-start-point="#stickyblock-start" data-end-point="#stickyblock-end">

            </div>
          </div>
          {/* End Sidebar */}
        </div>
      </div>
    </section>
    {/* End News Content */}
    </>
    )
}